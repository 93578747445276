import request from '../utils/request';
import url from '../utils/url';

//内容分类点击排行
export function finance(params) {
    return request(url.anClick, {
        method: 'POST',
        params
    })
}

export function spmhClick(params) {
    return request(url.anSpMh, {
        method: 'POST',
        params
    })
}
