/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form} from 'antd';
import {ProFormText} from '@ant-design/pro-form';
import {removeEmptyField} from '../../compoent/emptyFild'

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
        };
    }


    onFinish = (values) => {
        let params = removeEmptyField(values);
        if (params) {
            this.props.addSetting(params).then(res => {
                this.props.spreadSetting()
            })
        }
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };


    render() {
        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      initialValues={{remember: true}}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      autoComplete="off"
                >
                    <ProFormText rules={[
                        {
                            required: true,
                            message: '请输入邀请人数!',
                        },
                    ]} width="md" name="conditions" label="邀请人数" placeholder="邀请人数 "/>


                    <ProFormText tooltip="盈利百分比" width="md" name="scale" label="盈利比例"
                                 placeholder="盈利比例 1 ~ 20"
                                 rules={[
                                     {
                                         required: true,
                                         message: '盈利比例!',
                                     }
                                 ]}
                    />

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="success" htmlType="reset">
                            重置
                        </Button>
                        <Button  type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        )


    }


}

const mapState = state => ({
    ...state.spread

});

const mapDispatch = dispatch => ({
    ...dispatch.spread
})

export default connect(mapState, mapDispatch)(Add);