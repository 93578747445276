/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Col, message, Row, Space } from 'antd';
import { ProFormSelect, ProFormText, ProForm, ProFormTextArea, ProFormUploadButton } from '@ant-design/pro-form';


class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            type: '',
            titleImg: null,
            loading: false,
            tagList: [],
            yyList: [],
            cjList: [],
            status: [
                {label:'正常',value:1},
                { label: '下架', value: 0 }
            ],
            feesType: [
                { label: 'vip', value: 1 },
                { label: '金币', value: 2 },
                { label: '免费', value: 0 },
            ],
            ids: [],
            fileList: [],
            filesList: []
        };
    }

    componentDidMount() {
        const { row } = this.props
        let arr = [];
        this.props.childs.map(item => {
            let obj = {}
            obj.label = item.name
            obj.value = item.id
            if (item.cateType === 'cj')
                obj.desc = '厂家'

            else if (item.cateType == 'yy')
                obj.desc = '演员'
            else if (item.cateType == 'tag') {
                obj.desc = '标签'

            }
            else if (item.cateType == 'nav') {
                obj.desc = '导航'
            }

            arr.push(obj)
        })

        const fileArr = []
        const filesArr = []
        if (row != null && row.titleImg) {
            fileArr.push(
                {
                    uid: row.id,
                    name: row.titleImg.substring(row.titleImg.lastIndexOf("/") + 1),
                    status: 'done',
                    url: row.titleImg,
                }
            )
        }
        if (row && row != null && row.details) {
            row.details.map(item => {
                filesArr.push(
                    {
                        uid: Math.random().toString(20).substring(2, 20 + 2),
                        name: item.substring(item.lastIndexOf("/") + 1),
                        status: 'done',
                        url: item,
                    }
                )

            })

        }
        this.setState({ tagList: arr, fileList: fileArr, filesList: filesArr })

    }

    onFinish = (e) => {
       
        if (!e||this.state.loading) return
        const { tagList, titleImg, filesList } = this.state
        const { row, ids, type } = this.props
        if (e.tagId) {
            let obj = tagList.filter((item) => { return e.tagId.some(curVal => (curVal === item.value)) })
            e.tag = obj.map(i => { return i.label })
        }
        if (titleImg && e.titleImg && e.titleImg.length > 0)
            e.titleImg = titleImg

        if (filesList && filesList.length > 0)
            e.details = filesList.map(item => { return item.url })

        if (type === '1' || type == '0') {
            //批量操作
            e.ids = ids
            if (type == '0')
                e.ids = row.id
            this.props.update(e)
                .then(res => {
                    if (res.code === 0) {
                        message.info('提交成功');

                    } else {
                        message.error('提交失败');
                    }
                    this.props.handleCancel()
                    // this.props.moments()
                })

        } else {
            this.props.add(e)
                .then(res => {
                    if (res.code === 0) {
                        message.info('提交成功');

                    } else {
                        message.error('提交失败');
                    }
                    this.props.handleCancel()
                    //  this.props.moments()
                }
                )

        }



    };
    handleChange = ({ fileList: newFileList }) => {

        this.setState({ fileList: newFileList })
    }
    handleChanges = (e) => {
        console.log('a', e)

        this.setState({ filesList: e.fileList })
    }
    removeFile = (e) => {
        console.log('a', e)
        //arrList = arrList.filter((item) => item !== "周三");
        let src = this.setState.filesList
        const newFile = src.filter(f => f.uuid !== e.uuid);
        this.setState({ filesList: newFile })
        return true;
    }

    render() {
        const { tagList, status, feesType, fileList, filesList, loading, cjList } = this.state;
        const { img, type, row } = this.props

        const required = type === '2'
        return (
            <ProForm layout='inline' grid={true} rowProps={{ gutter: [12, 16] }} preserve={false}
                initialValues={row ? {
                    collect: row ? row.collect : 0,
                    title: row ? row.title : '',
                    params: row ? row.template : '',
                    viewer: row ? row.viewer : 0,
                    hots: row ? row.hots : 0,
                    feesType: row ? row.feesType : '',
                    status: row ? row.status : '',
                    seeTime: row ? row.seeTime : '',
                    feesPrice: row ? row.feesPrice : '',
                    tagId: row ? row.tagId : null,

                    videoPath: row ? row.videoPath : '',
                    descs: row ? row.descs : '',

                } : {}}
                submitter={{
                    render: (props, doms) => {
                        return (
                            <Row style={{ paddingTop: 10 }}>
                                <Col span={12} offset={24}>
                                    <Space>{doms}</Space>
                                </Col>
                            </Row>
                        );
                    },
                    submitButtonProps: {
                        loading: loading
                    }

                }}
                onFinish={(v) => this.onFinish(v)}
            >
                <ProFormText type='number' colProps={{ span: 12 }} name="collect" label="收藏" placeholder="收藏" />
                <ProFormText type='number' colProps={{ span: 12 }} name="viewer" label="观看" placeholder="观看" />
                <ProFormText type='number' colProps={{ span: 12 }} name="hots" label="热度" placeholder="热度" />
                <ProFormText colProps={{ span: 12 }} name="params" label="模板" placeholder="参数" />
                <ProFormText colProps={{ span: 12 }} name="seeTime" label="时长" placeholder="时长" />
                <ProFormText type='number' colProps={{ span: 12 }} name="feesPrice" label="价格" placeholder="金币价格" />
                <ProFormSelect colProps={{ span: 12 }} name="status" label="状态" placeholder="状态" options={status} />
                <ProFormSelect colProps={{ span: 12 }} name="feesType" label="类型" placeholder="收费类型" options={feesType} />
                <ProFormSelect name="tagId" label="标签" rules={[{ required: true, message: '请选择标签!' }]} placeholder="请选择视频标签" mode="multiple" options={tagList}
                    fieldProps={{
                        optionItemRender(item) {
                            return item.label + ' - ' + item.desc;
                        },
                    }} />

                <ProFormText rules={[{ required: required }]} name="title" label="标题" placeholder="视频标题 " />
                <ProFormUploadButton fileList={fileList} onChange={this.handleChange}
                    disabled={type === '1'} name="titleImg" max={1} tooltip="视频封面图片" label="封面" placeholder="显示图标"
                    fieldProps={{
                        name: 'file',
                        beforeUpload: async (file) => {

                            this.setState({ loading: true })
                            setTimeout(() => {
                                this.setState({loading: false})
                            }, 3000);
                            img({ file: file, type: 'video' }).then(r => {
                                //https://prod.smallybook.xyz/video/20231017/1697555468938.jpg

                                if (r.code === 0)
                                    this.setState({ loading: false, titleImg: r.data })
                                return true
                            })


                            return false
                        }
                    }}
                />
                <ProFormUploadButton fileList={filesList}
                    name="details" max={30} tooltip="视频详情图片" label="详情" placeholder="显示图详情"
                    fieldProps={{
                        name: 'file',
                        beforeUpload: async (file) => {
                            this.setState({loading: true})
                            setTimeout(() => {
                                this.setState({loading: false})
                            }, 3000);
                            img({ file: file, type: 'video' }).then(r => {
                               
                                if (r.code === 0) {
                                    let f = this.state.filesList
                                    f.push({
                                        uid: file.uid,
                                        name: file.name,
                                        status: 'done',
                                        url: r.data,
                                    })
                                    this.setState({  filesList: f })
                                }
                                this.setState({loading: false})
                                return true
                            })


                            return false
                        },
                        onRemove: (file) => {
                            const newFile = filesList.filter(f => f.uid !== file.uid);
                            this.setState({ filesList: newFile })
                            //  console.log('re', this.state.filesList)
                            return true;
                        }
                    }}
                />

                <ProFormText disabled={true} rules={[{ required: required }]} tooltip="填写能访问的视频完整链接 如视频是本地资源 建议忽略此字段" name="videoPath" label="视频" placeholder="视频外链 " />
                <ProFormTextArea name="descs" label="描述" placeholder="简介" />



            </ProForm>

        )


    }


}

const mapState = state => ({
    ...state.moments

});

const mapDispatch = dispatch => ({
    ...dispatch.moments
})

export default connect(mapState, mapDispatch)(Add);