import React, {Component} from 'react'
import {connect} from "react-redux";
import {Button, Input,Form} from 'antd';
import {message} from "antd/lib/index";
const { TextArea } = Input;

class FilterKw extends Component {

    componentDidMount() {
        this.props.filterDataList()

    }

    onFinish = (values) => {
        if (values) {
            this.props
                .updateFilter(values)
                .then(res=>{
                    this.props.filterDataList();
                    message.success('修改成功')

                })

        }

    };
    onFinishFailed = (errorInfo) => {

    };


    render() {
        const {filterData} =this.props

        return (
            <div>
                <div  >
                    <Form
                        style={{display:'flex',flexDirection: 'column',alignContent: 'flex-start',marginTop: 20, marginLeft: 20}}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"
                        initialValues={{
                            str: filterData||''
                        }}

                    >

                        <Form.Item
                            style={{width: 1500}}
                            name="str"

                            rules={[{required: true, message: '请输入内容 .分隔'}]}
                        >
                            <TextArea     rows={30} placeholder='请输入内容'/>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 8,}} style={{marginTop:20 ,marginLeft:100}}>

                            <Button type="success" htmlType="reset" >
                                重置
                            </Button>
                            <Button  style={{ marginLeft: 30 }} type="primary" htmlType="submit">
                                确认
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

            </div>
        )
    }
}

const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(FilterKw);