/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import LevelList from './LevelList'
import style from './Level.module.scss'
// import moment, { duration } from 'moment';
import { connect } from "react-redux";
// import CreateUser from './component/CreateUser'

import ChangeLevel from './components/ChangeLevel';


import {
    Form,
    Button,
    Modal
} from 'antd';

class Level extends Component {

    state = {
        data: [],
        min: '',
        max: '',
        visible: false,
        date: [],
        id: '',
        type: ''
        // centered:true

    }
    createLevel = () => {
        this.setState({
            visible: true,
            type: '0'
        })
    }
    handleCancel = () => {
        this.props.clear()
        this.setState({
            visible: false,
        })
    }
    render() {
        const { searchData, visible, type } = this.state
        // const { data } = this.props

        return (
            <div >
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        labelCol={{ span: 6 }}
                        // wrapperCol={{ span: 6 }}
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"

                    >
                        <Form.Item style={{ marginLeft: 20 }}>
                            <Button onClick={this.createLevel} type="primary" style={{ marginLeft: 1500 }}>
                                新增
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <LevelList searchData={searchData} />
                </div>
                <Modal destroyOnClose visible={visible} title='新增' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <ChangeLevel handleCancel={this.handleCancel} type={type} ></ChangeLevel>
                </Modal>

            </div >
        )
    }
}
const mapState = state => ({
    ...state.level

});

const mapDispatch = dispatch => ({
    ...dispatch.level
})

export default connect(mapState, mapDispatch)(Level);