import request from '../utils/request';
import url from '../utils/url';


export function list(params) {
    return request(url.circleContentList, {
        method: 'POST',
        params
    });
}


export function del(params) {
    return request(url.delCircleContent, {
        method: 'POST',
        params
    });
}

export function allType() {
    return request(url.circleTypes, {
        method: 'POST',
    });
}
export function allNames() {
    return request(url.circleNames, {
        method: 'POST',
    });
}

