import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, message } from 'antd';


// import './index.scss';

function UpdatePsw(props) {

    const [state, setState] = useState();
    const [form] = Form.useForm();


    useEffect(() => {


    }, [])

    const onFinish = (values) => {
        // if (values.conPwd !== values.newPwd) {
        //     form.setFields([
        //         {
        //             errors: ['两次密码输入不一致'],
        //             // name: 'conPwd',
        //             // value: values.conPwd
        //         }
        //     ]);wei
        //     return;
        // }
        values.id = props.record
    
        props.resetPassword({id:values.id,val:values.newPwd}).then(res => {

            if (res && res.code === 0) {
                message.success('修改成功')
            }
            props.onCancel()
        })
    };


    return (
        <div>
            <Form
                name="UpdatePsw"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 13 }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="newPwd"
                    label="新密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入新密码',
                        },
                    ]}
                    hasFeedback
                >
                    <Input type="password" minLength={6} placeholder='请输入新密码' />
                </Form.Item>
                <Form.Item
                    name="conPwd"
                    dependencies={['newPwd']}
                    label="确认密码"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请输入确认密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPwd') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不一致！'));
                            },
                        }),
                    ]}

                >
                    <Input type="password" placeholder='请输入确认密码' />
                </Form.Item>
                <Form.Item style={{ marginLeft: 200 }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

const mapState = state => ({
    ...state.users
});
const mapDispatch = dispatch => ({
    ...dispatch.users
});
export default connect(mapState, mapDispatch)(UpdatePsw);