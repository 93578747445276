import React, {Component} from 'react';
import {connect} from "react-redux";
import ProTable from '@ant-design/pro-table';
import {Image, Button, Popconfirm,Modal} from 'antd';
import Add from './Add'


class MonitorListList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open:false,
            row:null,
            type:''
          
        }
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                hideInSearch: true,
              


            },
            {
                title: '参数描述',
                align: 'center',
                ellipsis: true,
                dataIndex: 'remark',
                key: 'remark',
            },
            {
                title: '参数类型',
                align: 'center',
                ellipsis: true,
                dataIndex: 'type',
                key: 'type',
            },

            {
                title: '图片参数',
                dataIndex: 'url',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div >{record.url?<Image style={{ width: 50, height: 50 }} src={_} />:'无图片参数'} </div> 
                      );
                }
               
            },

            {
                title: '文本参数',
                align: 'center',
                ellipsis: true,
                copyable: true,
                hideInSearch: true,
                dataIndex: 'params',
                key: 'params'
             },

            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.upd(record)} style={{marginLeft: 2}}>编辑</a>
                            <Popconfirm
                                title="确定操作？"
                                onConfirm={() => this.del(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a style={{marginLeft: 10, color: 'red'}}
                            >删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];

        
    }

    componentDidMount() {
        
    }
    

    handleCancel = () => {
       
        this.setState({
            open: false,
            row:null
        })
    };
    add = (row) => {
        this.setState({
            open: true,
            type: '0',
            row: row
        })
    }
    upd = (record) => {
        this.setState({open: true, row: record, type: '1'})
    }
    del = (record) => {
        this.props.del({id: record.id}).then(res => {
            this.props.list();

        })
    };
 


    render() {
        const {data, img,list} = this.props;
        const { row,type} = this.state;

        return (
            <div>
            <ProTable
                columns={this.columns}
                request={(params, s, f) => {
                   
                    this.props.list(params)
                }}
                dataSource={data}
                columnsState={{
                    persistenceKey: 'pro-table-singe-demos',
                    persistenceType: 'localStorage',
                }}
                rowKey="id"
                search={false}

                pagination={{
                    pageSize: 50
                }}
                revalidateOnFocus={true}
                dateFormatter="string"
                headerTitle="系统参数"
                toolBarRender={() => [
                    <Button key="create" type="primary" onClick={() => this.add()}>
                        添加
                    </Button>,

                ]}
            />
                 <Modal destroyOnClose open={this.state.open} title={type === '0' ? '新增' : '编辑'}
                           footer={null}
                           onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} row={row} type={type}></Add>
                    </Modal>

        </div>

        );
    }
}


const mapState = state => ({
    ...state.apiMonitor
});

const mapDispatch = dispatch => ({
    ...dispatch.apiMonitor

})

export default connect(mapState, mapDispatch)(MonitorListList);