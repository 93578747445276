/* eslint-disable import/no-anonymous-default-export */
import {list,create,sync,paths,update,status,zjs,zjSort,zjFeesTypeStatus,syncZj,mhAddZj} from '../services/circleCommentApi';
import {child} from '../services/momentsCommentApi';
import {img } from '../services/cateApi'
import {
    fileDel,
    sourceDel,
    sourceClean
} from '../services/MonitorApi';
export default {
    state: {
        data: [],
        childs:[],
        pathData:[],
        zjs:[]
    },
    effects: {
        async list(params) {
            const response = await list(params)

            if (response.code === 0) {
                this.saveData({
                    data: response.data
                })
            }
        },
        async paths() {
            const response =   await paths()   
            if (response.code === 0) {
                this.savePath({
                    pathData: response.data
                })
            }
        },
        async fileDel(params) {
            return   await fileDel(params)
          },
          async sourceDel(params) {
            return   await sourceDel(params)
          },
          async sourceClean(params) {
            return   await sourceClean(params)
          },
          async syncZj(params) {
            return   await syncZj(params)
          },
          async mhAddZj(params) {
            return   await mhAddZj(params)
          },




        async child(params) {

            const response = await child(params)

            if (response.code === 0) {
                this.saveChild({
                    childs: response.data,

                })
            }
        },
        async zjFeesType(params) {

            return await zjFeesTypeStatus(params)
  
          },

        async zjList(params) {

          return await zjs(params)

        },
        async zjSort(params) {

            return await zjSort(params)
  
          },
        async create(params) {
            return  await create(params)   
        },
        async sync() {
            return  await sync()   
        },
        async update(params) {
            return  await update(params)   
        },
        async status(params) {
            return  await status(params)   
        },
        async img(params) {
            return await img(params)  
         },

    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        savePath(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveChild(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveZj(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }

    }
}