/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Form,
    Input,
    Button,
    Select,
    Radio,

} from 'antd';
import SendOrderList from './SendOrderList'
import { removeEmptyField } from '../../compoent/emptyFild'
import style from './SendOrder.module.scss'
const { Option } = Select;

export const SendOrder = (props) => {

    const [status] = useState([{ id: '0', name: '关闭' }, { id: '1', name: '正常' }])
    const [newValues,setNewValues ] = useState({})
    const [audit,setAudit]= useState('0')



    useEffect(() => {
        props.list()
    }, []);

    const onChange=(e)=>{
        const audits = e.target.value
        newValues.audit=audits
        setNewValues(newValues)
        setAudit(audits)
        props.sendOrder({ pageNo: 1, pageSize: 10 ,audit:audits})

    }

    const onFinish = (values) => {

        if (values) {
            //
            values.pageNo = 1
            values.pageSize = 10
            values.audit=audit
            let newValues = removeEmptyField(values)

            setNewValues(newValues)
            props.sendOrder(newValues)
        }

    };

    return (
        <div >
            <div className={style.top}>
                <Form
                    style={{ marginTop: 20, marginLeft: 20 }}
                    name="basic"
                    layout='inline'
                    // wrapperCol={{ span: 6 }}
                    onFinish={onFinish}
                    autoComplete="off"

                >
                    <Form.Item
                        style={{ width: 300 }}
                        label="发单id"
                        name="id"
                    >
                        <Input placeholder='发单id' />
                    </Form.Item>


                    <Form.Item
                        style={{ width: 300 }}
                        label="发单人"
                        name="name"
                    >
                        <Input placeholder='请输入发单人查询' />
                    </Form.Item>

                    <Form.Item
                        style={{ width: 300 }}
                        label="内容"
                        name="content"
                    >
                        <Input placeholder='请输入发单内容' />
                    </Form.Item>


                    <Form.Item
                        style={{ width: 250, marginLeft: 20 }}
                        label="类型"
                        name="cid"
                    >
                        <Select
                            showSearch
                            placeholder='请选择订单类型'

                        >
                            {props.listData.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.caption}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ width: 250, marginLeft: 20 }}
                        label="类型"
                        name="status"
                    >
                        <Select
                            showSearch
                            placeholder='请选择订单类型'

                        >
                            {status.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item style={{ marginLeft: 500, marginTop: 0 }}>
                        <Button type="primary" htmlType="submit">
                            查询
                        </Button>
                        <Button type="primary" htmlType='reset' style={{ marginLeft: 42 }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>

            </div>

            <div style={{marginLeft:20}}>
                <Radio.Group onChange={onChange} defaultValue="0"  style={{ marginBottom: 16 }}>
                    <Radio.Button  style={{marginRight:18}} value="0">未审核</Radio.Button>
                    <Radio.Button value="1">已审核</Radio.Button>
                </Radio.Group>

            </div>

            <div className={style.middle}>
                <SendOrderList newValues={newValues} audit={audit}></SendOrderList>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.order

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.order

})

export default connect(mapStateToProps, mapDispatchToProps)(SendOrder)
