/* eslint-disable import/no-anonymous-default-export */
import { addCateTag, parentTag, updCateTag, list, img, delCateTag } from '../services/cateApi'

export default {
    state: {
        CateTagData: [],
        parentTagData: [],
        updCateTagData: [],
        updateListData: [],
        imgData: '',
        imgUrl: '',
        delCateTagData: []
    },
    effects: {
        async addCateTag(params) {

            const response = await addCateTag(params)

            if (response.code === 0) {
                this.saveCateTag({
                    CateTagData: response.data
                })

            }
        },
        async parentTag(params) {

            const response = await parentTag(params)

            if (response.code === 0) {
                this.saveParentTag({
                    parentTagData: response.data
                })

            }
        },
        
        async updCateTag(params) {

            const response = await updCateTag(params)

            if (response.code === 0) {
                this.saveupdCateTag({
                    updCateTagData: response.data
                })

            }
        },
        async list(params) {

            const response = await list(params)

            if (response.code === 0) {
                this.saveList({
                    updateListData: response.data,
                    imgData: '',
                    bg:''
                })

            }
        },


        async icon(params) {
            const response = await img(params)
            if (response.code === 0) {

                this.saveUrl({
                    imgUrl: response.data
                })

            }
            return response
        },
        async delCateTag(params) {
            const response = await delCateTag(params)
            if (response.code === 0) {
                this.saveDelCateTag({
                    delCateTagData: response.data
                })

            }
        },
    },
    reducers: {
        saveCateTag(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveParentTag(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveupdCateTag(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveList(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveImg(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveUrl(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveDelCateTag(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        clear(state, payload) {
            return {
                ...state,
                imgUrl: ''
            }
        },

    }
}