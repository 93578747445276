import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

export const AddSign = (props) => {
    const [fileList, setFileList] = useState([])
/*    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState()*/


    const propsFile = {
        listType: "picture",
        accept: ".png, .jpg, .jpeg",
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            // this.setState({ fileList: newFileList })
            setFileList(newFileList)
        },
        beforeUpload: file => {
            const r = new FileReader();
            r.readAsDataURL(file);
            r.onload = e => {
                file.thumbUrl = e.target.result;
                setFileList([...fileList, file])
                props.imgs({ file: file, type: 'image' })


                //
                // this.setState({ picData: picData.data })
            };
            return false;
        },
        fileList
    };


    const onFinish = (values) => {
        // const { type } = this.props
        //
        //
        if (props.type === '0') {
            if (values) {
                values.imgUrl = props.imgData

                // const newValues = removeEmptyField(values)
                props.addAactivity(values).then(res => {
                    props.activityListPage({ page: 1, size: 10 })
                })
            }
            props.clear()
            props.handleCancel()
        }
        // else if (props.type === '1') {
        //     //编辑接口
        //
        //     if (values) {
        //         values.icon = this.props.imgData
        //         values.id = this.props.record.id
        //
        //         // const newValues = removeEmptyField(values)
        //         props.updCateTag(values).then(res => {
        //             props.activityListPage({ page: 1, size: 10 })
        //         })
        //     }
        //     this.props.handleCancel()
        // }

    };


    return (
        <div>
            <Form
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            // initialValues={{
            //     // caption: record ? record.caption : '',
            // }}
            >
                <Form.Item
                    label="活动名称"
                    name="title"
                    rules={[{ required: true, message: '请输入标签名称!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="imgUrl" label="图标">
                    <Upload {...propsFile} maxCount={1}>
                        <Button icon={<UploadOutlined />}>上传图标</Button>
                    </Upload>
                </Form.Item>
                <Form.Item
                    label="活动详情"
                    name="content"
                    rules={[{ required: true, message: '请输入活动详情!' }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        确认
                    </Button>
                    <Button type="primary" htmlType="reset" style={{ marginLeft: 30 }}>
                        重置
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.activity

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.activity

})

export default connect(mapStateToProps, mapDispatchToProps)(AddSign)
