import React, { Component } from 'react'

import { connect } from "react-redux";
import { ProFormText, ProForm,  } from '@ant-design/pro-form';
import { Col, Space, Row } from 'antd';

class Account extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mId: '',
            data: {},
        }
    }


    componentDidMount() {
        // this.setState({ data: this.props.data })
        //
    }




    render() {
        const { row } = this.props
        const { data } = this.state
        return (
           <div>
             <div style={{fontSize:20}}>账号：{row.loginName}</div>
            <div style={{fontSize:20}}>密码：{row.remarks}</div>
           </div>
        )
    }
}

const mapState = state => ({
    ...state.pay

});

const mapDispatch = dispatch => ({
    ...dispatch.pay
})

export default connect(mapState, mapDispatch)(Account);