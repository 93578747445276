import request from '../utils/request';
import url from '../utils/url';

//会员列表
export function userList(params) {
    return request(url.userList, {
        method: 'GET',
        params
    })

}
export function details(params) {
    return request(url.details, {
        method: 'GET',
        params
    })
}
export function accBalanceLog(params) {
    return request(url.accBalanceLog, {
        method: 'GET',
        params
    })
}
export function add(params) {
    return request(url.add, {
        method: 'POST',
        params
    })
}
export function homeData(params) {
    return request(url.homeData, {
        method: 'GET',
        params
    })
}
export function changeStatus(params) {
    return request(url.changeStatus, {
        method: 'POST',
        params
    })
}
export function resetPassword(params) {
    return request(url.resetPassword, {
        method: 'POST',
        params
    })
}
//用户推荐列表
export function recommendList(params) {
    return request(url.memberRecommendList, {
        method: 'POST',
        params
    })
}
//删除推荐用户信息
export function delRecommend(params) {
    return request(url.delMemberRecommend, {
        method: 'POST',
        params
    })
}
//删除推荐用户信息
export function addRecommend(params) {
    return request(url.addMemberRecommend, {
        method: 'POST',
        params
    })
}
//个人消息推送
export function pushMsg(params) {
    return request(url.personalMsg, {
        method: 'POST',
        params
    })
}
export function updVip(params) {
    return request(url.updVip, {
        method: 'POST',
        params
    })
}

//个人消息推送
export function pushAllMsg(params) {
    return request(url.pushAllMsg, {
        method: 'POST',
        params
    })
}
//vipoi
export function vipoi(params) {
    return request(url.vipoi, {
        method: 'POST',
        params
    })
}