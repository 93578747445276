import React, {Component} from 'react'

import {connect} from "react-redux";
import {Badge, Button, Modal,message } from 'antd';
import ProTable from '@ant-design/pro-table';
import Add from './Add'

class Comment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            row: null,
            selectedRowKeys:[],
            loading:false,
            type:'mh',
            title:"生成漫画"
        
        };

        this.columns = [
            {
                title: '漫画资源',
                dataIndex: 'name',
                width:300,
              
                align: 'center',
                hideInSearch: true,
                copyable: true,
            },
            {
                title: '章节数量',
                align: 'center',
                dataIndex: 'value',
                hideInSearch: true,//processing' : 'default'
                render: (_, record) => (
                    
                    <a>
                        {JSON.parse(record.value).status=="1"?'同步中':JSON.parse(record.value).status=='0'?'队列中':
                        JSON.parse(record.value).status=='3'?'异常：'+JSON.parse(record.value).error:
                        record.value&&JSON.parse(record.value).dir?JSON.parse(record.value).dir.length|'':''}
                    </a>

                 )
            },

            {
                title: '操作',
                align: 'center',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                    <div>
                        <Button  type="primary" disabled={this.state.selectedRowKeys&&this.state.selectedRowKeys.length>0&&(record.value&&(JSON.parse(record.value).status == "1"||JSON.parse(record.value).status == "0"||JSON.parse(record.value).status == "3"))}  onClick={() => this.action(record)} >生成</Button>
                    <Button style={{marginLeft:10}} disabled={record.value&&JSON.parse(record.value).status == "1"} type="primary" danger onClick={() => this.del(record)} >删除</Button>
                    </div>
                    )
                }
            }

        ];


    }

    componentDidMount() {
        this.props.child({type:this.state.type});
    }


    handleCancel = () => {
        this.setState({
            visible: false,
            row:null,
            selectedRowKeys:[],
            title:"生成漫画"
        })
    };


    sync = (row) => {
        this.setState({loading:true})
        this.props.sync().then(res => {
            if (res.code===0) {
                message.info('同步成功');
            }
            this.setState({loading:false});
            this.props.paths();

        })
    }

    cleaner = () => {
        this.setState({ loading: true })
        this.props.sourceClean({type:'mh'}).then(res => {
            if (res.code === 0) {
                message.info('缓存已清空 请重新同步');
            }

            this.setState({ loading: false });
            this.props.paths();

        })
    }
    action = (record) => {
        this.setState({visible: true, row: record})
    }
    parse = (record) => {
        this.setState({
            visible: true,
            type: '0',
            row: record
        
        })
    }
    del = (record) => {
        if(record.value=="1")
            return 
       this.props.sourceDel({path:record.name})
       .then(res=>{
         if(res.code==0){
            this.props.paths();

         }
       }
       )
    }

    start = () => {
        this.setState({loading:true,visible: true, type: '0',title: '生成漫画(已选'+this.state.selectedRowKeys.length+")"})
        setTimeout(() => {
            this.setState({loading:false});
            
        }, 1000);
    };
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({selectedRowKeys: newSelectedRowKeys})
    };

    onSelectChange = (newSelectedRowKeys,row) => {
       
        let arr=[]
         row.map(item=>{
            if(item.value!=="1")
              arr.push(item.name)
        })
      
        this.setState({selectedRowKeys: arr})
    };


    render() {
        const {row, type,selectedRowKeys,loading,title} = this.state;
        const { childs,pathData} = this.props;
        const rowSelection = {selectedRowKeys, onChange: this.onSelectChange,}
        const hasSelected = selectedRowKeys.length > 0;
     
        return (
            <div>
                <div
                    style={{
                        marginBottom: 1,
                    }}
                >
                    <Button type="primary" onClick={() => this.start()} disabled={!hasSelected} loading={loading}>
                        批量生成
                    </Button>

                </div>
                <ProTable
                columns={this.columns}
                request={(params, s, f) => {
                    this.props.paths()
                }}
                rowSelection={rowSelection}
                dataSource={pathData}
                columnsState={{
                    persistenceKey: 'pro-table-singe-demos',
                    persistenceType: 'localStorage',
                }}
                rowKey="name"

                pagination={{
                    pageSize: 50,
                }}
                search={false}
                revalidateOnFocus={true}
                dateFormatter="string"
                headerTitle="漫画资源路径"
                toolBarRender={() => [
                    <Button style={{marginRight:10}} key="creat" type="primary" danger onClick={() => this.cleaner()} loading={loading}>
                       清空
                    </Button>,
                    <Button key="creat" type="primary" danger onClick={() => this.sync()} loading={loading}>
                       同步漫画目录
                    </Button>

                ]}
            />
                <Modal destroyOnClose open={this.state.visible} title={title}
                       footer={null}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} childs={childs} row={row} mp={selectedRowKeys}/>
                </Modal>
            </div>
        );

    }
}

const mapState = state => ({
    ...state.circleComment

});

const mapDispatch = dispatch => ({
    ...dispatch.circleComment
})

export default connect(mapState, mapDispatch)(Comment);