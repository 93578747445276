/* eslint-disable import/no-anonymous-default-export */
import {commentList, del} from '../services/orderCommentApi';

export default {
    state: {
        data: []
    },
    effects: {
        async commentList(params) {
            const response = await commentList(params)
            if (response.code === 200) {
                this.saveCommentList({
                    data: response.data,

                })
            }
        },

        async del(params) {
            await del(params)
        },
    },
    reducers: {
        saveCommentList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },

    }
}