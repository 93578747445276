import request from '../utils/request';
export default function download(url,method,params) {
    request(url, {method, params},'blob')
        .then(resp => {
        if (!resp)
            return;
        let downloadUrl = window.URL.createObjectURL(new Blob([resp]));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = downloadUrl;
        link.setAttribute('download', 'excel.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    })
}