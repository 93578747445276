/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Form,
    Input,
    Button,
    Select,
    Radio,

} from 'antd';
import DispatchOrderList from './DispatchOrderList'
import { removeEmptyField } from '../../compoent/emptyFild'
import style from './DispatchOrder.module.scss'
const { Option } = Select;

export const DispatchOrder = (props) => {

    const [status] = useState([{ id: 0, name: '待处理' }, { id: 1, name: '进行中' },{ id: 2, name: '已完成' },{ id: 4, name: '取消' }])
    const [newValues,setNewValues ] = useState({})
    const [audit,setAudit]= useState(0)



    useEffect(() => {
        props.list({size:50})
    }, []);

    const onChange=(e)=>{
        const audits = e.target.value
        setNewValues({ page: 1, size: 10 ,status:0,audit:audits})
        setAudit(audits)

        props.dispatchList({ page: 1, size: 10 ,status:0,audit:audits})

    }

    const onFinish = (values) => {

        if (values) {
            //
            values.page = 1
            values.size = 10
            values.audit=audit;
            let newValues = removeEmptyField(values)

            setNewValues(newValues)
            props.dispatchList(newValues)
        }

    };

    return (
        <div >
            {audit===0?'':  <div className={style.top}>
                <Form
                    style={{ marginTop: 20, marginLeft: 20 }}
                    name="basic"
                    layout='inline'
                    // wrapperCol={{ span: 6 }}
                    onFinish={onFinish}
                    autoComplete="off"

                >
                    <Form.Item
                        style={{ width: 250 }}
                        label="订单编号"
                        name="orderId"
                    >
                        <Input placeholder='请输入订单编号查询' />
                    </Form.Item>
                    <Form.Item
                        style={{ width: 200 }}
                        label="派单人"
                        name="dname"
                    >
                        <Input placeholder='请输入派单人查询' />
                    </Form.Item>

                    <Form.Item
                        style={{ width: 200 , marginLeft: 5}}
                        label="接单人"
                        name="sname"
                    >
                        <Input placeholder='请输入接单人查询' />
                    </Form.Item>

                    <Form.Item
                        style={{ width: 200, marginLeft: 5 }}
                        label="派单内容"
                        name="content"
                    >
                        <Input placeholder='请输入派人内容' />
                    </Form.Item>


                    <Form.Item
                        style={{ width: 160, marginLeft: 5 }}
                        label="分类"
                        name="cate"
                    >
                        <Select
                            showSearch
                            placeholder='请选择订单类型'

                        >
                            {props.listData.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.caption}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ width: 160, marginLeft: 10 }}
                        label="状态"
                        name="status"
                    >
                        <Select
                            showSearch
                            placeholder='请选择订单类型'

                        >
                            {status.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item style={{ marginLeft: 20,marginTop:2 }}>

                        <Button type="primary" htmlType='reset'>
                            重置
                        </Button>
                        <Button type="primary" htmlType="submit"  style={{ marginLeft: 15 }}>
                            查询
                        </Button>
                    </Form.Item>
                </Form>

            </div>}

            <div style={{marginLeft:20}}>
                <Radio.Group onChange={onChange} defaultValue={0}  style={{ marginBottom: 16 }}>
                    <Radio.Button  style={{marginRight:18}} value={0}>未审核</Radio.Button>
                    <Radio.Button value={1}>已审核</Radio.Button>
                </Radio.Group>

            </div>

            <div className={style.middle}>
                <DispatchOrderList newValues={newValues} audit={audit} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.order

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.order

})

export default connect(mapStateToProps, mapDispatchToProps)(DispatchOrder)
