import axios from "axios";
import {message, Modal} from "antd";
import {env} from '../utils/url'
import load, {closeLoading, openLoading} from './loading/loading'



axios.defaults.withCredentials = false;
const instance = axios.create({
    timeout: 30000,
    baseURL: "/",

    // withCredentials: true,
    // Content-Type: multipart/form-data;boundary="boundary"
    // headers: { "token": token?.token ? token.token : '' }

});

//常见的http状态码信息d
let httpCode = {
    400: "请求参数错误",
    401: "权限不足, 请重新登录",
    403: "服务器拒绝本次访问",
    404: "请求资源未找到",
    500: "内部服务器错误",
    501: "服务器不支持该请求中使用的方法",
    502: "网关错误",
    504: "网关超时",
    600: '账号已在其他地方登录'
};

instance.interceptors.request.use(
    config => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        config.headers['Authorization'] = userInfo && userInfo.token ? 'Bearer ' + userInfo.token : '';
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//添加响应拦截器
instance.interceptors.response.use(
    response => {
        if (env === 'dev')
            console.log('response', response)
        load.dispatch(closeLoading());

        if (response.data.code === 200 || response.data.code === 0|| response.data.type ==='application/octet-stream') {
           // message.success('成功');
            if (response && response.data && response.data.data && response.data.data.current) {
                response.data.data.current = Number(response.data.data.current)
            }
            return Promise.resolve(response.data);
        }
        else if (response.data.code === 401||response.data.code ===499) {
            localStorage.clear()
            if (response.data.code === 401) {
                Modal.error({
                    title:'认证失败',
                    content:'当前登录用户没有进行认证,请联系管理员',
                    onOk:()=>{window.location.href = "/#/login"}
                });
            }else {
                window.location.href = "/#/login"
                window.location.reload(true)
            }
            return Promise.reject(response.data.msg);
        }
        else if ([405, 204, 218, 600].includes(response.data.code)) {
            //根据状态码返回给提示
            if (response.data.code === 218)
                message.error("网络不给力,请稍后再试");

            else if (response.data.code === 600) {
                localStorage.clear()
              const modal =   Modal.error({
                    title:'登录信息异常',
                    content:'你的账号在异地登录，请重新尝试或修改密码',
                    onOk:()=>{window.location.href = "/#/login" }
                });

                setTimeout(() => {
                    modal.destroy();
                },  3000);
            }
            else {
                message.error(response.data.data);
            }

            return Promise.resolve({});
        }
        else {
            let tips = response.data.msg ? response.data.msg : response.data.code ? response.data.code : '业务异常';
            message.error({
                content: tips,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                }
            });
            return Promise.resolve({});
        }

    },
    error => {
        if (error.response) {
            // 根据请求失败的http状态码去给用户相应的提示

            let tips =
                error.response.code in httpCode
                    ? httpCode[error.response.code]
                    : error.response.data.msg;
            message.error(tips);
            if (error.response.code === 401||error.response.data.code === 600||error.response.code === 499) {
                //针对框架跳转到登陆页面
                localStorage.clear();
                
                window.location.href = "/#/login";window.location.reload(true)
            }
            // else if (error.response.code === 600) {
            //   window.location.href = "/#/login";
            //   localStorage.clear()

            // }
            return Promise.reject(error);
        } else {
           // window.location.href = "/quwan/#/login";
            return Promise.resolve("请求超时,请刷新重试");

        }
    }
);

export default function request(url, config, respType) {
   
    load.dispatch(openLoading());
    setTimeout(() => {
        load.dispatch(closeLoading());
    }, 5000)
    const {method, params} = config;
    const data = method === "POST" ? "data" : "params";
    const paramsData = new FormData();
    if (env === 'dev') {
        //message.warn('当前环境调试模式!!')
        console.log("requestParams", config)
    }

    if (params && Object.keys(params).length) {
        //判断是否有图片(图片键名files)
        for (let key in params) {
            if (key === "files" && Array.isArray(params[key])) {
                params[key].map(item => paramsData.append("files", item))
            }

            else {
                paramsData.append(key, params[key]);
            }
        }
    }
    return new Promise((resolve, reject) => {
        instance({
            url,
            method: method,
            responseType: respType,
            [data]: method === "GET" ? params : paramsData,
            headers: {
                // 'Authorization': token && token.token ? 'Bearer ' + token.token : ''
            }
        })
            .then(response => {
                resolve(response);
                load.dispatch(closeLoading());
            })
            .catch(error => {
                reject(error);
                load.dispatch(closeLoading());
            });
    });
}