/* eslint-disable import/no-anonymous-default-export */
import { adminListPage, addAdmin, roleListPage, updateAdmin, delAdmin, updateStatus } from '../services/admin'

export default {
    state: {
        data: [],
        addAdminData: [],
        roleListData: [],
        updateAdminData: [],
        delAdminData: [],
        updateStatusData: []
    },
    effects: {
        async adminListPage(params) {
            const response = await adminListPage(params)
            if (response.code === 0) {

                this.saveAdminListPage({
                    data: response.data
                })
            }
        },
        async addAdmin(params) {
            const response = await addAdmin(params)
            if (response.code === 0) {
                this.saveAddAdminData({
                    addAdminData: response.data
                })
            }
        },
        async roleListPage(params) {
            const response = await roleListPage(params)

            return response

        },
        async updateAdmin(params) {
            const response = await updateAdmin(params)
            if (response.code === 0) {
                this.saveupdateAdminData({
                    updateAdminData: response.data
                })
            }
        },
        async delAdmin(params) {
            const response = await delAdmin(params)

            if (response.code === 0) {
                this.saveDelAdmin({
                    delAdminData: response.data
                })
            }
        },
        async updateStatus(params) {
            const response = await updateStatus(params)

            if (response.code === 0) {
                this.saveUpdateStatus({
                    updateStatusData: response.data
                })
            }
        },
    },
    reducers: {
        saveAdminListPage(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveAddAdminData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveRoleListData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveupdateAdminData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveDelAdmin(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveUpdateStatus(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
    }

}