import React from "react";
//import { routes } from "../../router/menu";
import { Redirect, Route, withRouter } from "react-router-dom";
import UserInfo from "../userInfo/userInfo";
import  { PageContainer } from '@ant-design/pro-layout';
import permitData from '../../router/permit'


function MainContent(props) {
    const permiss = JSON.parse(localStorage.getItem("permit")) ? JSON.parse(localStorage.getItem("permit")) : []
    const  permit = permitData(permiss)
  const {
    location: { pathname }
  } = props;

  const len = permit.routes.length - 1;
  let itemData = {};
  for (let i = 0; i < len; i++) {
    if (permit.routes[i].path === pathname || permit.routes[i].permission) {
      itemData = permit.routes[i];
      break;
    }
  }
  let path = "";
  for (let i = 0; i < len; i++) {
    if (permit.routes[i].auth) {
      path = permit.routes[i].path;
      break;
    }
  }
 // const isAuth = permissions.includes(itemData.auth) || permit.routes.permission;
  return (
    <PageContainer fixedHeader>

        {permit.routes.map(item => {
          localStorage.setItem("permissionData", JSON.stringify(item.permission))

          return  (
            <Route
              render={() => <item.component />}
              key={item.path}
              path={item.path}
            />
          ) ;
        })}
        <Route path="/userInfo" key="/userInfo" render={() => <UserInfo />} />
        <Redirect from="/" exact to={path ? path : "/login"} />


    </PageContainer>
  );
}
export default withRouter(MainContent);
