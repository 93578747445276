import request from '../utils/request';
import url from '../utils/url';


//视频列表
export function moments(params) {
    return request(url.videoList, {
        method: 'POST',
        params
    });
}

export function delDynamic(params) {
    return request(url.delDynamic, {
        method: 'POST',
        params
    });
}
export function cdnRefresh(params) {
    return request(url.videoCdnRefresh, {
        method: 'POST',
        params
    });
}
export function cdnCache(params) {
    return request(url.videoCdnCache, {
        method: 'POST',
        params
    });
}

//修改
export function batchUpdate(params) {
    return request(url.videoUpdate, {
        method: 'POST',
        params
    });
}
//修改
export function add(params) {
    return request(url.videoAdd, {
        method: 'POST',
        params
    });
}

//修改
export function status(params) {
    return request(url.videoStatus, {
        method: 'POST',
        params
    });
}