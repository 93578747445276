/* eslint-disable import/no-anonymous-default-export */
import {list,upd} from '../services/vcsApi';

export default {
    state: {
        data: []
    },
    effects: {
        async list(params) {
            const response = await list()
            if (response.code === 0) {
                this.saveData({
                    data: response.data,

                })
            }
        },
     
        async upd(params) {
           return await upd(params)
        },
    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },

    }
}