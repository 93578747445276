import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { Modal, Table, Badge } from 'antd';
import ShowUserInfo from './component/ShowUserInfo'
import ShowUserAcc from './component/ShowUserAcc'
import UpdatePsw from './component/updatePsw';



class MembersList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 20,
            visible: false,
            record: {},
            balanceRecord: {},
            // title: [],
            current: 1,
            status: false,
            idRecord: {},
            recommend: false,
            msgList: false,
            memberInfo: {}

        }

        this.columns = [

            {
                title: '用户昵称',
                dataIndex: 'nickname',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {_}({record.id})
                        </div>
                    );
                },
            },
            {
                title: '会员',
                align: 'center',
                dataIndex: 'vip',
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_ === 1 ? 'success' : _ === 0 ? 'default' : 'error'}
                            text={_ === 1 || _ === 2 ? record.vipTime : '-'} />
                    </a>

                )
            },
            {
                title: '金币',
                dataIndex: 'vb',
                align: 'center',
            },

            {
                title: '总消费',
                dataIndex: 'consumeMoney',
                align: 'center',
            },
            {
                title: '账号',
                dataIndex: 'em',
                align: 'center',
            },
            {
                title: '推荐人',
                dataIndex: 'parentId',
                align: 'center',
            },
            {
                title: '渠道',
                dataIndex: 'channel',
                align: 'center',
            },
            {
                title: '域名',
                dataIndex: 'payPwd',
                align: 'center',
            },
            {
                title: '系统',
                dataIndex: 'os',
                align: 'center',
            },
            {
                title: '注册IP',
                dataIndex: 'remarks',
                align: 'center',
            },

            // {
            //     title: '系统',
            //     align: 'center',
            //     render: (_, record) => {
            //         return (
            //             <div>
            //                 {record.source === 0 ? <a style={{ color: "blue" }}>web</a> : <div>其他</div>}
            //             </div>
            //         );
            //     }
            // },



            {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {record.status === 1 ? <a onClick={() => this.changeOff(record)}>正常</a> :
                                <a onClick={() => this.changeOn(record)} style={{ color: "grey" }}>禁用</a>}
                        </div>
                    );
                }
            },

            {
                title: '最后登录时间',
                dataIndex: 'lastVisitTime',
                align: 'center',
                sorter: (a, b) => new Date(a.lastVisitTime).getTime() - new Date(b.lastVisitTime).getTime()

            },
            {
                title: '注册时间',
                dataIndex: 'addTime',
                align: 'center',
                sorter: (a, b) => new Date(a.addTime).getTime() - new Date(b.addTime).getTime()
            },
            {
                title: "操作",
                fixed: 'right',
                width: 260,
                // dataIndex: "action",
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.userInfo(record)}>vip</a>
                            <a onClick={() => this.changeAcc(record)} style={{ marginLeft: 20 }}>订单记录</a>
                            <a onClick={() => this.updatePsw(record)} style={{ marginLeft: 20 }}>修改密码</a>

                        </div>
                    );
                }
            }

        ];
    }

    componentDidMount() {

        this.init();
    }

    init = () => {
        const { userList } = this.props
        userList({ page: 1, size: 10 })

    }

    handleChangeMembersList = (current, pageSize) => {
        const { newValues } = this.props
        //
        newValues.page = current
        newValues.size = 10
        this.setState({ current })
        this.props.userList({ ...newValues })
    }

    userInfo = (record) => {
        this.setState({
            visible: true,
            title: 1,
            record
        })
       

    }
    changeAcc = (record) => {
        this.setState({
            visible: true,
            title: 2,
            balanceRecord: record
        })
        // const { accBalanceLog } = this.props
        // accBalanceLog().then(res => {
        //     accBalanceLog({ mId: this.state.balanceRecord.id })
        // })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
        })

    }
    cancel = () => {
        this.setState({
            status: false,
            recommend: false,
            msgList: false
        })
    }

    updatePsw = (record) => {

        this.setState({
            status: true,
            idRecord: record.id
        })
    }
    changeOff = (record) => {
        //
        const { changeStatus } = this.props
        const { current } = this.state
        // const id = record.id + ''
        //
        changeStatus({ mId: record.id, status: 1 }).then(res => {
            this.props.userList({ page: current, size: 10 })
        })
    }
    changeOn = (record) => {
        //
        const { changeStatus, data } = this.props
        const { current } = this.state
        changeStatus({ mId: record.id, status: 0 }).then(res => {
            this.props.userList({ page: current, size: 10 })
        }
        )
    }

    handleCancelVip = (refresh) => {
        if (refresh) {
            const { current } = this.state
            this.props.userList({ page: current, size: 10 })
        }
        this.setState({
            visible: false,

        })
    }

    recommend = (record) => {
        this.setState({
            recommend: true,
            memberInfo: record
        })

    }
    msgList = (record) => {
        this.setState({
            msgList: true,
            memberInfo: record
        })

    }
    showTotal = (total, rang) => {
        return `共:` + total
    }

    render() {
        const { data, userLoading } = this.props;
        const { status, current, visible, title, record, balanceRecord, idRecord } = this.state;

        const pagination = {
            current: current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeMembersList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false,//大于50时显示pageSize切换器
            showTotal: this.showTotal
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records}
                    rowKey={record => record.id} pagination={pagination}
                    scroll={{ x: 'max-content' }}

                />

                <Modal destroyOnClose open={visible} title={title === 1 ? '用户详情' : '帐变记录'}
                    width={title === 1 ? 500 : 1500} footer={null} onCancel={this.handleCancel}>
                    {title === 1 ? <ShowUserInfo row={record} handleCancelVip={this.handleCancelVip}></ShowUserInfo> :
                        <ShowUserAcc record={balanceRecord}></ShowUserAcc>}
                </Modal>

                <Modal destroyOnClose open={status} title='修改密码' footer={null} onCancel={this.cancel}>
                    <UpdatePsw record={idRecord} onCancel={this.cancel}></UpdatePsw>
                </Modal>



            </div>
        );
    }
}


const mapState = state => ({
    ...state.users

});

const mapDispatch = dispatch => ({
    ...dispatch.users
})

export default connect(mapState, mapDispatch)(MembersList);