import React, { Component } from 'react';
import { connect } from "react-redux";
import { Image, Col, Row, Modal, Popconfirm, Table, Tag, Badge, Button, message } from 'antd';
import Add from './Add'
import MhInfo from './MhInfo';
import { ModalForm,ProFormSelect  } from '@ant-design/pro-components';


class ContentList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            play: false,
            visible: false,
            zjOpen: false,
            row: {},
            selectedRowKeys: [],
            loading: false,
            title: "",
            newValues: {},
            type: '',
            zjData: [],
            sortData: [],
            modalVisit: false,
            zjSelect:[],
            zjId:null,
            uf:null
            
        }


        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center'

            },

            {
                title: '漫画标题',
                dataIndex: 'title',
                align: 'center',
                width: 200,


            },
            {
                title: '封面',
                dataIndex: 'titleImg',
                align: 'center',
                render: (_, record) => _ ? <div ><Image style={{ width: 50, height: 50 }} src={_} /> </div> : null
            },
            {
                title: '标签',
                dataIndex: 'tagList',
                align: 'center',
                width: 200,
                render: (_, record) => (<div>
                    {_ ? _.map((i, k) => (
                        <Tag key={k} color="processing" >
                            {i}
                        </Tag>)) : ''}
                </div>)
            },

            {
                title: '类型',
                dataIndex: 'feesType',
                align: 'center',
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_=== 1 ? 'success':_=== 0? 'default':_=== 2? 'processing': 'error'}
                            text={_=== 1 ? 'vip':_=== 0? '免费':_=== 2? '金币': '暂无设置'}/>
                    </a>

                 )

            },
            {
                title: '价格',
                dataIndex: 'feesPrice',
                align: 'center'

            },
            {
                title: '章节状态',
                dataIndex: 'finish',
                align: 'center',
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_ === 1 ? 'success' : 'processing'}
                            text={_ === 1 ? '完结' : '连载'} />

                    </a>

                )

            },
            {
                title: '观看',
                dataIndex: 'viewer',
                align: 'center'

            },
            {
                title: '收藏',
                dataIndex: 'collect',
                align: 'center'

            },
            {
                title: '热度',
                dataIndex: 'hots',
                align: 'center'

            },
            {
                title: '作者',
                dataIndex: 'auth',
                align: 'center'

            },

            {
                title: '描述',
                dataIndex: 'descs',
                align: 'center',
                width: 200,

            },
            {
                title: '漫画状态',
                align: 'center',
                tip: '11',
                dataIndex: 'status',
                fixed: 'right',
                render: (_, record) => (
                    <a>
                        <Popconfirm
                            title="变更状态"
                            onConfirm={() => this.status(record)}
                            okText="确定"
                            cancelText="取消"
                        >


                            <Badge
                                status={_ === 1 ? 'success' : _ === 0 ? 'default' : 'error'}
                                text={_ === 1 ? '正常' : _ === 0 ? '关闭' : '错误'} />
                        </Popconfirm>
                    </a>

                )
            },
            {
                title: '章节信息',
                dataIndex: 'itemCount',
                fixed: 'right',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            <Button style={{ marginRight: 10 }} onClick={() => this.mhDetail(record)} type='dashed' danger>目录({_})</Button>

                            
                        </div>

                    )
                }

            },

            {
                title: '操作',

                align: 'center',
                fixed: 'right',
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={()=>this.openModal(record)}  style={{color:'green',marginRight:10}} >新增</a>
                            <a disabled={this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0} onClick={() => this.update(record)}>编辑</a>
                            {record.status == 0 ?
                                <a style={{ marginLeft: 10, color: 'red' }}>
                                    <Popconfirm
                                        title="删除"
                                        onConfirm={() => this.del(record)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        删除

                                    </Popconfirm>
                                </a> : ''
                            }
                            
                        </div>
                    )
                }
            },



        ];
    }

    componentDidMount() {
        this.props.list({ ...this.props.newValues });
        this.setState({ newValues: this.props.newValues })
    }

    del = (record) => {
        this.props.fileDel({ id: record.id, type: 'mh' })
            .then(res => {
                if (res.code === 0) {
                    this.props.list({ ...this.state.newValues })
                }
            })
    }


    handleChangeList = (current, pageSize) => {
        const { newValues } = this.props
        newValues.page = current
        newValues.size = pageSize
        this.setState({ newValues })
        this.props.list(newValues)
    }
    update = (record) => {
        this.setState({ visible: true, type: "0", title: '编辑', row: record })
    }
    openModal = (record) => {
      
        this.props.syncZj()
        .then(res=>{
            if(res.code==0){
                this.setState({ zjSelect:res.data,modalVisit: true,zjId:record.id })

            }
        })
       
    }

    openModalChange = (r,s) => {
        
        this.setState({ modalVisit: r })
        
    }
    mhDetail = (row) => {
        this.props.zjList({ id: row.id })
            .then(res => {
                if (res.code == 0) {
                    console.log('res', res.data)
                    this.setState({ zjOpen: true, zjData: res.data, row: row })
                }
                else
                    message.error('请求失败')

            })
        //  
    }

    status = (row) => {
        this.props.status({ id: row.id, status: row.status == 1 ? 0 : 1 })
            .then(res => {
                if (res.code === 0) {
                    this.props.list(this.state.newValues)
                }
            })

    }
    start = () => {
        this.setState({ loading: true, visible: true, type: "1", title: '批量修改(' + this.state.selectedRowKeys.length + ")" })
        setTimeout(() => {
            this.setState({ loading: false });

        }, 1000);
    };
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({ selectedRowKeys: newSelectedRowKeys })
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            zjOpen: false,
            selectedRowKeys: [],
            row: null
        })
        this.props.list(this.state.newValues)
    };
    cancleZjModal = () => {

        this.setState({
            zjOpen: false,
            row: null,
            sortData: [],
            zjData: []
        })

    };
    sortZj = (data) => {
        this.setState({ sortData: data })

    }
    resetF = (prop) => {
        prop.reset()

    }
    onSo
    onSortConfirm = () => {
        const { sortData, zjData } = this.state

        if (sortData.length == 0)
            message.error("当前章节顺序没有变动")
        else {
            let arr = []
            sortData.map((item, idx) => {
                if (item.id !== zjData[idx].id) {
                    //id不一致 有变动
                    let obj = {}
                    obj.id = item.id
                    obj.num = idx + 1
                    arr.push(obj)
                }
            })
            if (arr.length == 0)
                message.error("当前章节顺序没有变动")
            else {
                this.props.zjSort({ json: JSON.stringify(arr) })
                    .then(res => {
                        if (res.code === 0) {
                            message.info('修改成功')
                            this.cancleZjModal();
                        }
                    })
            }

        }

    }


    render() {
        const { data, userLoading, tagList } = this.props;
        const { visible, title, row, selectedRowKeys, loading, type, zjOpen, zjData, modalVisit,zjSelect } = this.state
        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = { selectedRowKeys, onChange: this.onSelectChange, }
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div style={{ paddingTop: 10 }}>
                <Row >
                    <Col span={2} >
                        <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                            批量操作
                        </Button>
                    </Col>

                </Row>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records} scroll={{ x: 'max-content' }}
                    rowKey={record => record.id} pagination={pagination} rowSelection={rowSelection} />

                <Modal destroyOnClose open={visible} title={title} footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} childs={tagList} row={row} ids={selectedRowKeys} type={type} />
                </Modal>
                <Modal destroyOnClose={true} centered={true} maskClosable={false} open={zjOpen} width={1000}
                    onCancel={this.cancleZjModal} onOk={this.onSortConfirm}>
                    <MhInfo row={row} zjData={zjData} sort={this.sortZj} cancleZjModal={this.cancleZjModal} />
                </Modal>

                <ModalForm 
                     
                    title="添加章节,默认添加到最后一张"
                    open={modalVisit}
                    onFinish={async (e,r) => {
                      
                       
                        if(this.state.zjId==null){
                            message.error('请重新打开编辑框');
                            return;
                        }
                       
                        message.success('提交成功..请等待..');
                      return  this.props.mhAddZj({id:this.state.zjId,zj:e.zjs})
                        .then(res=>{
                            if( this.state.uf&&this.state.uf!=null){
                                this.state.uf.form?.resetFields()
                            }
                            if (res.code==0){
                               
                                this.props.list({ ...this.props.newValues });
                                return true;
                            }else{
                                message.error(res.message);
                            }
                               
                        })
                       
                       
                        
                    }}
                    onOpenChange={this.openModalChange}
                    submitter={{
                     
                    
                        // 完全自定义整个区域
                        render: (props, doms) => {
                        
                          return [
                          
                            <button
                              type="button"
                              key="submit"
                              onClick={() => {
                                props.form?.submit?.();
                                this.setState({uf:props})
                            }}
                            >
                              提交
                            </button>,
                          ];
                        },
                      }}
                >

                    <ProFormSelect
                   
                   mode="multiple"
                        options={zjSelect}
                        name="zjs"
                        rules={[{ required: true,message:'请选择对应文件目录' }]}
                        label="请选择对应文件目录"
                    />

                </ModalForm>

            </div>

        );
    }
}


const mapState = state => ({
    ...state.circleComment
});

const mapDispatch = dispatch => ({
    ...dispatch.circleComment

})

export default connect(mapState, mapDispatch)(ContentList);