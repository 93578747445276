import React, {Component} from "react";
import {connect} from 'react-redux';
import ProTable from '@ant-design/pro-table';
import Add from './Add'
import ProfitList from './ProfitList'
import {Badge, Button, Modal, Popconfirm} from 'antd';

class SpreadSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            showList:false

        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                hideInSearch: true,
                width: 80,


            },
            {
                title: '邀请人数',
                align: 'center',
                copyable: true,
                ellipsis: true,
                dataIndex: 'conditions',
                key: 'conditions',
                hideInSearch: true,
                width: 80,
            },

            {
                title: '盈利比例',
                align: 'center',
                dataIndex: 'scale',
                hideInSearch: true,
                width: 80,
                render: (_, record) => {
                    return (<div>{_}%</div>);
                }

            },


            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <Popconfirm
                                title="确定操作？"
                                onConfirm={() => this.del(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a style={{marginLeft: 10, color: 'red'}}
                            >删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];


    }


    handleCancel = () => {
        this.setState({
            visible: false,
            showList:false
        })
    };
    add = (row) => {
        this.setState({
            visible: true
        })
    }
    profitList = () => {
        this.setState({
            showList: true
        })
    }

    iosOff = () => {

            this.props.iosSwitch()
                .then(r=>{
                    Modal.info({
                        title: '当前状态',
                        content: (
                            <div>
                                <p/>

                                <h3 style={{color:'red',marginTop:30}}>ios推广配置当前状态--> {r.data?'开启':'关闭' }</h3>
                            </div>
                        ),

                        onOk() {},
                    });

                })
    }

    del = (record) => {
        this.props.delSetting({id: record.id}).then(res => {
            this.props.spreadSetting();

        })
    };


    render() {
        const {setting} = this.props;

        return (
            <div>
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                        this.setState({params})
                        this.props.spreadSetting()
                    }}
                    dataSource={setting}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"

                    revalidateOnFocus={true}
                    dateFormatter="string"
                    headerTitle="推广配置列表"
                    toolBarRender={() => [
                        <Button key="list" type="primary" onClick={() => this.profitList()}>
                            盈利榜单
                        </Button>,
                        <Button key="create" type="primary" onClick={() => this.add()}>
                            添加
                        </Button>,

                        <Button key="ios" type="primary" onClick={() => this.iosOff()}>
                             ios开关
                        </Button>

                    ]}
                />

                    <Modal destroyOnClose visible={this.state.visible}
                           footer={null}
                           onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} ></Add>
                    </Modal>

                <Modal destroyOnClose visible={this.state.showList}
                       footer={null}
                       width={ 1500}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <ProfitList></ProfitList>
                </Modal>

            </div>
        );

    }


}


const mapState = state => ({
    ...state.spread

});

const mapDispatch = dispatch => ({
    ...dispatch.spread
})

export default connect(mapState, mapDispatch)(SpreadSetting);