/* eslint-disable import/no-anonymous-default-export */
import { balanceLog,channelList,channelUpd,channelStatus,channelDetailStatus,channelDetailList,domainListAll,updAccessLink,domainListAcc ,channelAcc,channelXl} from "../services/payApi";

export default {
    state: {
        data: [],
        channelData:{},
        detailData:[],
        domainAll:[],
        omainAcc:[]
    },
    effects: {
        async balanceLog(params) {

            const response = await balanceLog(params)

            if (response.code === 0) {
                this.savePay({
                    data: response.data
                })
            }
        },
        async channelList(params) {

            const response = await channelList(params)

            if (response.code === 0) {
                this.saveChannelData({
                    channelData: response.data
                })
            }
        },
        async channelDetailList(params) {

            const response = await channelDetailList(params)
            
            if (response.code === 0) {
                this.saveDetaillData({
                    detailData: response.data
                })
            }
        },

        async domainListAll(params) {

            const response = await domainListAll(params)
            
            if (response.code === 0) {
                this.saveDomainListAll({
                    domainAll: response.data
                })
            }
        },
        async domainListAcc(params) {

            const response = await domainListAcc(params)
            
            if (response.code === 0) {
                this.saveDomainListAcc({
                    domainAcc: response.data
                })
            }
        },
        async channelUpd(params) {

             return  await channelUpd(params)
        },
        async channelXl(params) {

            return  await channelXl(params)
       },
        async channelAcc(params) {

            return  await channelAcc(params)
       },
        async updAccessLink(params) {

            return  await updAccessLink(params)
       },
        async channelStatus(params) {

            return  await channelStatus(params)
       },
       async channelDetailStatus(params) {

        return  await channelDetailStatus(params)
   }


    },
    reducers: {
        savePay(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveChannelData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveDetaillData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveDomainListAll(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveDomainListAcc(state, payload) {
            return {
                ...state,
                ...payload
            }
        }
    }
}