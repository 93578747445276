import request from '../utils/request';
import url from '../utils/url';

//配置信息列表
export function list(params) {
    return request(url.vipRuleList, {
        method: 'POST',
        params
    });
}

//添加
export function updateTVipRule(params) {
    return request(url.updateTVipRule, {
        method: 'POST',
        params
    });
}
//删除
export function delVip(params) {
    return request(url.delVipRule, {
        method: 'POST',
        params
    });
}

//权益列表
export function rightsList(params) {
    return request(url.vipRightsList, {
        method: 'POST',
        params
    });
}

//添加
export function addRights(params) {
    return request(url.addVipRights, {
        method: 'POST',
        params
    });
}
//删除
export function delRights(params) {
    return request(url.delVipRights, {
        method: 'POST',
        params
    });
}

//图片上传
export function upload(params) {
    return request(url.img, {
        method: 'POST',
        params
    })

}
//图片上传
export function vipLogs(params) {
    return request(url.vipLog, {
        method: 'POST',
        params
    })

}





