import React, { Component } from 'react'

import { connect } from "react-redux";
import { ProFormSelect, ProForm, } from '@ant-design/pro-form';
import { message } from 'antd';

class Xl extends Component {
    constructor(props) {
        super(props)

    }




    render() {
        const { row, domainAll,params } = this.props

        return (
            <ProForm
            onFinish={async (e) => {
                message.success('请求中.请等待..');
                e.channelId=row.id
                return this.props.channelXl(e)
                    .then(res => {
                        if (res.code == 0) {
                            message.success(res.message);
                            this.props.domainListAll()
                            this.props.channelList(params)
                          
                            return true;
                        } else {
                            message.error(res.message);
                        }

                    })



            }}
            >
                <ProFormSelect rules={[{ required: true, message: '至少选择一条路线!' }]}
                    name="domainId" label="线路链接" placeholder="至少选择一条路线" options={domainAll}
                    fieldProps={{
                        optionItemRender(item) {
                            return item.label + ' —— ' + item.desc;
                        },
                    }}
                />

            </ProForm>
        )
    }
}

const mapState = state => ({
    ...state.pay

});

const mapDispatch = dispatch => ({
    ...dispatch.pay
})

export default connect(mapState, mapDispatch)(Xl);