
export function removeEmptyField(obj) {
    var newObj = {};
    if (typeof obj == "string") {
        obj = JSON.parse(obj);
    }
    if (obj instanceof Array) {
        newObj = [];
    }
    if (obj instanceof Object) {
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr) && obj[attr] !== "" && obj[attr] !== null && obj[attr] !== undefined) {
                if (obj[attr] instanceof Object) {
                    newObj[attr] = this.removeEmptyField(obj[attr]);
                } else if (typeof obj[attr] == "string" && ((obj[attr].indexOf("{") > -1 && obj[attr].indexOf("}") > -1) || (obj[attr].indexOf("[") > -1 && obj[attr].indexOf("]") > -1))) {
                    try {
                        var attrObj = JSON.parse(obj[attr]);
                        if (attrObj instanceof Object) {
                            newObj[attr] = this.removeEmptyField(attrObj);
                        }
                    } catch (e) {
                        newObj[attr] = obj[attr];
                    }
                } else {
                    newObj[attr] = obj[attr];
                }
            }
        }
    }
    return newObj;
}