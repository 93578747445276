import request from '../utils/request';
import url from '../utils/url';


export function msgList(params) {
    return request(url.msgList, {
        method: 'POST',
        params
    })

}

export function createInfo(params) {
    return request(url.createInfo, {
        method: 'POST',
        params
    })

}
export function delMessage(params) {
    return request(url.delMessage, {
        method: 'POST',
        params
    })

}

export function chatRecordList(params) {
    return request(url.chatRecordList, {
        method: 'POST',
        params
    })

}
export function changeChatRecordStatus(params) {
    return request(url.changeChatRecordStatus, {
        method: 'POST',
        params
    })

}

export function domainList(params) {
    return request(url.domainList, {
        method: 'POST',
        params
    })

}

export function updDomain(params) {
    return request(url.domainUpd, {
        method: 'POST',
        params
    })

}

export function filterData() {
    return request(url.filterData, {
        method: 'POST'
    })

}
export function updateFilterData(params) {
    return request(url.updateFilterData, {
        method: 'POST',
        params
    })

}

export function spreadList() {
    return request(url.scaleList, {
        method: 'POST'
    })

}

export function spreadUpdate(params) {
    return request(url.scaleUpdate, {
        method: 'POST',
        params
    })

}
//syncDomain
export function syncDomain() {
    return request(url.domainSync, {
        method: 'POST'
        
    })

}

export function serviceList() {
    return request(url.serviceList, {
        method: 'POST'
        
    })

}
export function serviceAddOrUpd(params) {
    return request(url.serviceAddOrUpd, {
        method: 'POST',
        params
        
    })

}
export function serviceIpAdd(params) {
    return request(url.serviceIpAdd, {
        method: 'POST',
        params
        
    })

}
export function serviceIpUpd(params) {
    return request(url.serviceIpUpd, {
        method: 'POST',
        params
        
    })

}

export function dnsUpd(params) {
    return request(url.dnsUpd, {
        method: 'POST',
        params
        
    })

}
export function domainParse(params) {
    return request(url.domainParse, {
        method: 'POST',
        params
        
    })

}
export function dnsDel(params) {
    return request(url.dnsDel, {
        method: 'POST',
        params
        
    })

}
export function serviceImport(params) {
    return request(url.systemServiceImport, {
        method: 'POST',
        params
        
    })

}
