/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form} from 'antd';
import {ProFormText} from '@ant-design/pro-form';
import {removeEmptyField} from '../../compoent/emptyFild'

class AddProfitList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
        };
    }


    onFinish = (values) => {
        let params = removeEmptyField(values);
        if (params) {
            this.props.addProfitList(params).then(res => {
                this.props.profitList()
            })
        }
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };


    render() {
        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      initialValues={{remember: true}}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      autoComplete="off"
                >
                    <ProFormText rules={[
                        {
                            required: true,
                            message: '请输入用户名!',
                        },
                    ]} width="md" name="name" label="用户名" placeholder="张xx "/>


                    <ProFormText  width="md" name="money" label="金额"
                                 placeholder="金额1-999999"
                                 rules={[
                                     {
                                         required: true,
                                         message: '金额!',
                                     }
                                 ]}
                    />

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="success" htmlType="reset">
                            重置
                        </Button>
                        <Button  type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        )


    }


}

const mapState = state => ({
    ...state.spread

});

const mapDispatch = dispatch => ({
    ...dispatch.spread
})

export default connect(mapState, mapDispatch)(AddProfitList);