import request from '../utils/request';
import url from '../utils/url';

//首页数据汇总
export function homeData() {
    return request(url.homeData, {
        method: 'GET'
    });
}
//HOME折线图
export function indexDashboard(params) {
    return request(url.indexDashboard, {
        method: 'GET',
        params
    })
}
//返回权限菜单
export function getAdminMenuList(params) {
    return request(url.login, {
        method: 'POST',
        params
    });
}