import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table } from 'antd';

class PromoteList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
        }

        this.columns = [
            {
                title: '时间',
                dataIndex: 'day',
            },
            {
                title: '访问人数',
                dataIndex: 'visitor',
            },
            {
                title: '注册人数',
                dataIndex: 'register',
            },
            {
                title: '登陆人数',
                dataIndex: 'login',
            },
            {
                title: '活跃人数',
                dataIndex: 'active',
            },
            {
                title: '充值VIP人数',
                dataIndex: 'vip',
            },
            {
                title: '提现人数',
                dataIndex: 'tranfer',
            },
            {
                title: '发单人数',
                dataIndex: 'publishOrder',
            },
            {
                title: '接单人数',
                dataIndex: 'consumerOrder',
            },
            {
                title: '取消订单数',
                dataIndex: 'cancelOrder',
            },



        ];
    }
    componentDidMount() {
        this.init();
    }

    // handleEditUser = (record) => {
    //
    // }

    init = () => {
        const { spread } = this.props
        spread({ page: 1, size: 10 })
    }

    handleChangePromoteList = (current, pageSize) => {
        const { newValues } = this.props
        //
        newValues.page = current
        newValues.size = 10
        this.props.spread({ ...newValues })
    }
    render() {
        const { data, userTotal, userLoading } = this.props;
        const { pageNum, pageSize } = this.state;

        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangePromoteList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records} rowKey={record => record.id} pagination={pagination} />
            </div>
        );
    }
}


const mapState = state => ({
    ...state.spread

});

const mapDispatch = dispatch => ({
    ...dispatch.spread
})

export default connect(mapState, mapDispatch)(PromoteList);