/* eslint-disable import/no-anonymous-default-export */
import { finance,spmhClick } from '../services/financeApi'

export default {
    state: {
        data: []
    },
    effects: {
        async finance(params) {
            return await finance(params)
            
        },
        async spmh(params) {
            return await spmhClick(params)
            
        }
    },
    reducers: {
        saveFinance(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }
    }
}