import React, { Component } from 'react'
import { connect } from "react-redux";
import style from './Home.module.scss'
import { schart1 } from './ImgCharts'
import dayjs from 'dayjs';
import CountUp from 'react-countup';
import { StatisticCard } from '@ant-design/pro-components';
import { DatePicker } from 'antd';
import huan from "../../assets/huan.svg"
import huan1 from "../../assets/huanlv.svg"
import i from "../../assets/ip.png"
import r from "../../assets/reg.png"
import t from "../../assets/t.png"
import s from "../../assets/succ.png"
import a from "../../assets/am.png"
import l from "../../assets/l.png"
const imgStyle = {
  display: 'block',
  width: 42,
  height: 42,
};
const { Statistic, Divider } = StatisticCard;
const { RangePicker } = DatePicker;
const formatter = (value) => <CountUp end={value} separator="," />;
class Home extends Component {
  constructor(values) {
    super(values);

    this.state = {
      datas: {},
      timeSelect: [
        {
          label: '昨天',
          value: () => [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')]
        },
        {
          label: '今天',
          value: () => [dayjs(), dayjs().endOf('day')]
        },
        {
          label: '上周',
          value: () => [dayjs().add(-1, 'week').startOf('week'),
          dayjs().add(-1, 'week').endOf('week')]
        },
        {
          label: '本周',
          value: () => [dayjs().startOf('week'), dayjs().add(-1, 'd')]
        },
        {
          label: '本月',
          value: () => [dayjs().startOf('month'), dayjs().add(-1, 'd')]
        },
        {
          label: '上月',
          value: () => [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')]
        }

      ],
      responsive: false,
    }

  }


  componentDidMount() {

    this.props.homeData();

    this.props.indexDashboard().then(res => {


      this.setState({ datas: res.data })
      schart1(res.data.data)
    })



  }

  regis = (e) => {

    const { datas } = this.state
    schart1(datas.data, e);
  }

  onChange = (e) => {

    this.props.indexDashboard({ beginTime: e[0], endTime: e[1] }).then(res => {
      this.setState({ datas: res.data })
      schart1(res.data.data);
    })

  }

  render() {
    const { data } = this.props
    const { responsive, timeSelect, datas } = this.state

    return (
      <div className={style.pages}>

        <div className={style.topC}>
          <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
            <StatisticCard

              statistic={{
                title: 'IP',
                value: (data.totalCount) | '',
                formatter: formatter
              }}
            />
            <Divider type={responsive ? 'horizontal' : 'vertical'} />
            <StatisticCard

              statistic={{
                title: '注册',
                value: (data.registerCount) | '',
                formatter: formatter
              }}
            />
            <Divider type={responsive ? 'horizontal' : 'vertical'} />
            <StatisticCard
              statistic={{
                title: '今日活跃',
                value: data.keep,
                description: <Statistic title="占比" value={parseInt(data.keep / data.totalCount * 100) + "%" || 0} />,
              }}
              chart={
                <img
                  src={l}
                  alt="百分比"
                  width="100%"
                />
              }
              chartPlacement="left"
            />
            <Divider type={responsive ? 'horizontal' : 'vertical'} />
            <StatisticCard
              statistic={{
                title: '现有vip',
                value: data.vipCount | 0,
                description: <Statistic title="占比" value={parseInt(data.vipCount / data.totalCount * 100) + "%" || 0} />,
              }}
              chart={
                <img
                  src={huan1}
                  alt="百分比"
                  width="100%"
                />
              }
              chartPlacement="left"
            />
            <StatisticCard
              statistic={{
                title: 'vip累计',
                value: data.historyVip || 0,
                description: <Statistic title="占比" value={parseInt(data.historyVip / data.totalCount * 100) + "%" || 0} />,
              }}
              chart={
                <img
                  src={huan}
                  alt="百分比"
                  width="100%"
                />
              }
              chartPlacement="left"
            />
          </StatisticCard.Group>
        </div>
        {/* defaultValue={[dayjs().startOf('month'),dayjs().endOf('month')]} */}

        <div>
          <div className={style.selectC}>按时间查询:
            <RangePicker label="按时间查询"
              presets={timeSelect}
              onChange={(j, e) => this.onChange(e)}
              style={{ height: 40, width: 350, margin: 20 }} />

          </div>

          <div className={style.centC}>
            <StatisticCard.Group direction={responsive ? 'column' : 'row'}>
              <StatisticCard
                onClick={() => { this.regis('ip') }}
                statistic={{
                  title: 'IP',
                  value: datas ? datas.ip : 0,
                  icon: (
                    <img
                      style={imgStyle}
                      src={i}
                      alt="icon"
                    />
                  ),
                }}
              />

              <StatisticCard
                onClick={() => { this.regis('register') }}
                statistic={{
                  title: '用户',
                  value: datas ? datas.register : 0,
                  icon: (
                    <img
                      style={imgStyle}
                      src={r}
                      alt="icon"
                    />
                  ),
                }}
              />

              <StatisticCard
                onClick={() => { this.regis('order') }}
                statistic={{
                  title: '订单总量',
                  value: datas ? datas.order : 0,
                  icon: (
                    <img
                      style={imgStyle}
                      src={t}
                      alt="icon"
                    />
                  ),
                }}
              />

              <StatisticCard
                onClick={() => { this.regis('pay') }}
                statistic={{
                  title: '成功订单',
                  value: datas ? datas.pay : 0,
                  icon: (
                    <img
                      style={imgStyle}
                      src={s}
                      alt="icon"
                    />
                  ),
                }}
              />
              <StatisticCard
                onClick={() => { this.regis('amount') }}
                statistic={{
                  title: '收入金额',
                  value: datas ? datas.amount : 0,
                  icon: (
                    <img
                      style={imgStyle}
                      src={a}
                      alt="icon"
                    />
                  ),
                }}
              />

            </StatisticCard.Group>
          </div>

          <div className={style.centC}>
            <StatisticCard.Group>
              {datas && datas.acc ? datas.acc.map(item => (
                <StatisticCard key={item.name}
                  statistic={{
                    title: '('+item.id+')'+item.name,
                    value: ''+item.amount+' / '+item.real,
                  }}
                />
              )) : ""}


            </StatisticCard.Group>
          </div>



          <div className={style.bottom} id='main'>

          </div>
        </div>
      </div>
    );

  }
}

const mapState = state => ({
  ...state.homeData,
  // ...state.indexDashboard
});
const mapDispatch = dispatch => ({
  ...dispatch.homeData,
  // ...dispatch.indexDashboard

});


export default connect(mapState, mapDispatch)(Home);
