import React, { Component } from 'react'
import { ProCard, ProTable } from '@ant-design/pro-components';
import RcResizeObserver from 'rc-resize-observer';
import { connect } from 'react-redux';

import dayjs from 'dayjs';
import {
    DatePicker,
    Space,
    Tag
} from 'antd';

const { RangePicker } = DatePicker;


class Retained extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: false,
            data: [],
            time: null,
            channelTimeData: [],
            timeSelect: [
                {
                    label: '昨天',
                    value: () => [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')]
                },
                {
                    label: '今天',
                    value: () => [dayjs(), dayjs().endOf('day')]
                },
                {
                    label: '上周',
                    value: () => [dayjs().add(-1, 'week').startOf('week'),
                    dayjs().add(-1, 'week').endOf('week')]
                },
                {
                    label: '本周',
                    value: () => [dayjs().startOf('week'), dayjs().add(-1, 'd')]
                },
                {
                    label: '本月',
                    value: () => [dayjs().startOf('month'), dayjs().add(-1, 'd')]
                },
                {
                    label: '上月',
                    value: () => [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')]
                }

            ],

        }
        this.columns = [
            {
                title: '渠道',
                align: 'center',
                dataIndex: 'channel'
            },
            {
                title: '访问量',
                dataIndex: 'access',
                align: 'center',
                sorter: (a, b) => a.access - b.access,
            },
            {
                title: '用户',
                dataIndex: 'userOther',
                align: 'center',
                sorter: (a, b) => a.userOther - b.userOther,
            },
            {
                title: '注册',
                dataIndex: 'register',
                align: 'center',
                sorter: (a, b) => a.register - b.register,
            },

            {
                title: '订单总数',
                dataIndex: 'orderCount',
                align: 'center',
                sorter: (a, b) => a.orderCount - b.orderCount,
            },
            {
                title: '成功订单',
                dataIndex: 'payOrder',
                align: 'center',
                sorter: (a, b) => a.payOrder - b.payOrder,
            },
            {
                title: '首充订单',
                dataIndex: 'proOther',
                align: 'center',
                sorter: (a, b) => a.proOther - b.proOther,
            },
            {
                title: '复充订单',
                dataIndex: 'orderOther',
                align: 'center',
                sorter: (a, b) => a.orderOther - b.orderOther,
            },
            {
                title: '成功金额',
                dataIndex: 'income',
                align: 'center',
                sorter: (a, b) => a.income - b.income,
            },
            {
                title: '首充金额',
                dataIndex: 'active',
                align: 'center',
                sorter: (a, b) => a.active - b.active,
            },
            {
                title: '复充金额',
                align: 'center',
                render: (_, record) => record.income - record.active,
                sorter: (a, b) => (a.income - a.active) - (b.income - b.active),
            }

        ]
    }


    componentDidMount() {
        this.init();
    }

    init = () => {
        const { list } = this.props;
        list().then(res => {
            if (res.code == 0) {
                this.setState({ data: res.data })
            }
        })
    }

    onChange = (e) => {

        this.props.list({ begin: e[0], end: e[1] }).then(res => {
            if (res.code == 0) {
                this.setState({ data: res.data, time: null, channelTimeData: [] })
            }
        })

    }
    cClick = (e) => {

        this.setState({ channelTimeData: e.channelTimeData, time: e.balance })
    }
    render() {

        const { responsive, timeSelect, data, channelTimeData, time } = this.state;

        return (
            <div>
                <div>
                    <RangePicker label="按时间查询"
                        presets={timeSelect}
                        onChange={(j, e) => this.onChange(e)}
                        style={{ height: 40, width: 350, margin: 20 }} />
                </div>
                <RcResizeObserver
                    key="resize-observer"
                    onResize={(offset) => {

                        this.setState({ responsive: offset.width < 596 })
                    }}
                >
                    <ProCard
                        title="时间分布"
                        extra="流量分布"
                        bordered
                        headerBordered
                        split={responsive ? 'horizontal' : 'vertical'}
                    >
                        <ProCard split="horizontal">
                            {data.map(item => (
                                <ProCard title={'时间：' + item.balance + '~' + (item.balance + 1) + '\xa0\xa0 \xa0\xa0\xa0\xa0 访问量：' + (item.access) + '\xa0\xa0 \xa0\xa0\xa0\xa0 用户/注册：' + (item.userOther + '/' + item.register)}
                                    onClick={() => this.cClick(item)} key={item.balance} activeKey={0}
                                >
                           
                                    <Space size={[0, 8]} wrap>
                                        <Tag color="purple">收入：{item.income}</Tag>
                                        <Tag color="geekblue">首充：{item.active}</Tag>
                                        <Tag color="green">复充：{item.income - item.active}</Tag>
                                        <Tag color="magenta">拉单：{item.orderCount}</Tag>
                                        <Tag color="red">支付：{item.payOrder}</Tag>
                                        <Tag color="volcano">首充订单：{item.proOther}</Tag>
                                        <Tag color="cyan">复充订单：{item.orderOther}</Tag>


                                        
                                    </Space>
                                </ProCard>


                            ))}


                        </ProCard>
                        <ProCard title={'时间：' + (time ? time + '~' + (time + 1) : '')}>
                            <ProTable
                                dataSource={channelTimeData}
                                rowKey="channel"
                                pagination={false}
                                columns={this.columns}
                                search={false}
                                toolBarRender={false}
                                dateFormatter="string"

                            />
                        </ProCard>

                    </ProCard>
                </RcResizeObserver>
            </div>

        )
    }
}

const mapState = state => ({
    ...state.preserve
})
const mapDispatch = dispatch => ({
    ...dispatch.preserve

})

export default connect(mapState, mapDispatch)(Retained);
