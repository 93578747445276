import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message, Modal, Form, Input, Button, Popconfirm } from "antd";
import "./header.less";


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

function Header(props) {
  const { headImg } = props;
  const { logout, updatePassword } = props;
  const [form] = Form.useForm();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  //退出
  const handleLogout = () => {
    localStorage.clear()
    window.location.href = "/#/login"

  };

  const handleMenuClick = e => {
    setVisible(true);
  }

  //确定修改密码
  const onFinish = (values) => {
    if (values.conPwd !== values.newPwd) {
      form.setFields([
        {
          errors: ['两次密码输入不一致'],
          name: 'conPwd',
          value: values.conPwd
        }
      ]);
      return;
    }
    values.id = userInfo.userId

    updatePassword(values).then(res => {

      if (res && res.code === 0) {
        message.success(res.msg);
        setDisabled(true);
        setTimeout(() => {
          message.success('请重新登录');
          props.history.push("/login");
        }, 1000)
      }
    })
  }



  return (
    <div className="header-wrap">
      <div className="header-left">

      </div>
      <div className="header-right">

        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <div className='avatar-username'>
            {/* {
              headImg !== '' ?
                <img className='avatar-img' alt='' src={url.imgUrl + headImg} /> :
                userInfo.headImg && userInfo.headImg !== '' ?
                  <img className='avatar-img' alt='' src={url.imgUrl + userInfo.headImg} /> :
                  <Avatar size={24} icon={<UserOutlined />} />
            } */}
            <a href="https://files.smallybook.xyz" target="_blank" className="header-title">文件系统</a>
            <a style={{ marginLeft: 80 ,marginRight:50}} href="https://kf-welcomemessage.syb88.net/login" target="_blank" className="header-title">客服系统</a>
            <div className='userinfo' >欢迎您，{userInfo.loginName}</div>
            <a style={{ marginLeft: 20, color: 'blue' }} onClick={handleMenuClick} >修改密码</a>


            <Popconfirm
              title="确定退出？"
              onConfirm={handleLogout}
              okText="退出"
            >
              <a style={{ marginLeft: 30 }} >退出登录</a>
            </Popconfirm>

          </div>
        </div>



      </div>
      <Modal
        title='修改密码'
        open={visible}
        destroyOnClose
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form
          {...layout}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="oldPwd"
            label="旧密码"
            rules={[
              {
                required: true,
                message: '请输入旧密码',
              },
            ]}
          >
            <Input type="password" placeholder='请输入旧密码' />
          </Form.Item>
          <Form.Item
            name="newPwd"
            label="新密码"
            rules={[
              {
                required: true,
                message: '请输入新密码',
              },
            ]}
          >
            <Input type="password" placeholder='请输入新密码' />
          </Form.Item>
          <Form.Item
            name="conPwd"
            label="确认密码"
            rules={[
              {
                required: true,
                message: '请输入确认密码',
              },
            ]}
          >
            <Input type="password" placeholder='请输入确认密码' />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
            <Button disabled={disabled} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

const mapState = state => ({
  ...state.login
});
const mapDispatch = dispatch => ({
  ...dispatch.login
});
export default connect(mapState, mapDispatch)(withRouter(Header));
