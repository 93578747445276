import request from '../utils/request';
import url from '../utils/url';

//标签添加
export function addCateTag(params) {
    return request(url.addCateTag, {
        method: 'POST',
        params
    })

}
//分类标签列表
export function list(params) {
    return request(url.list, {
        method: 'POST',
        params
    })

}
//分类标签查询
export function parentTag(params) {
    return request(url.parentTag, {
        method: 'POST',
        params
    })

}
//标签修改
export function updCateTag(params) {
    return request(url.updCateTag, {
        method: 'POST',
        params
    })

}
//图片上传
export function img(params) {
    return request(url.img, {
        method: 'POST',
        params
    })

}
//标签删除
export function delCateTag(params) {
    return request(url.delCateTag, {
        method: 'POST',
        params
    })

}

