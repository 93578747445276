/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form} from 'antd';
import {ProFormText} from '@ant-design/pro-form';
import {removeEmptyField} from '../../compoent/emptyFild'

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            type: '',

        };
    }

    onFinish = (values) => {
        const {type} = this.props

        let params = removeEmptyField(values);
        if (type === '0') {

        }
        else if (type === '1') {
            //编辑接口

            if (params) {
                params.id = this.props.record.id
                this.props.spredadUpdate(params).then(res => {
                    this.props.spreadList()
                })
            }

        }
        this.props.handleCancel()

    };


    render() {
        const {record, type} = this.props
        const bol = !!record;
        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      initialValues={{remember: true}}
                      onFinish={this.onFinish}
                      autoComplete="off" initialValues={{
                    scale: bol ? record.scale : ''
                }}>

                    <ProFormText rules={[
                        {
                            required: true,
                            message: '比例!',
                        },
                    ]} width="md" name="scale" label="抽成比例" placeholder="抽成比例 "/>



                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="reset">
                            重置
                        </Button>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        )


    }


}

const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(Add);