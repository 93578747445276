import React, {Component} from 'react'
import style from './Log.module.scss'
import LogList from './LogList'
import {connect} from "react-redux";
import {removeEmptyField} from '../../compoent/emptyFild'

import {Button, Form, Input, Select} from 'antd';


class Log extends Component {

    onFinish = (values) => {
        values.page = 1
        values.size = 10
        let newValues = removeEmptyField(values)
        this.props.list(newValues)
    };
    onFinishFailed = (errorInfo) => {

    };

    render() {
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{marginTop: 20, marginLeft: 20}}
                        name="basic"
                        layout='inline'
                        onFinish={this.onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{width: 320}}
                            label="用户ID："
                            name="id"
                        >
                            <Input placeholder='输入Id'/>
                        </Form.Item>
                        <Form.Item
                            style={{width: 320}}
                            label="手机："
                            name="mobile"
                        >
                            <Input placeholder='输入手机'/>
                        </Form.Item>

                        <Form.Item
                            style={{width: 320}}
                            label="操作类型："
                            name="type"
                        >
                            <Select>
                                <Select.Option key={1} value={1}>发单</Select.Option>
                                <Select.Option key={2} value={2}>发动态</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{marginLeft: 0, marginTop: 0}}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{marginLeft: 30}}>
                                重置
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <LogList />
                </div>

            </div>
        )
    }
}

const mapState = state => ({
    ...state.memberLog

});

const mapDispatch = dispatch => ({
    ...dispatch.memberLog
})

export default connect(mapState, mapDispatch)(Log);