import React, { Component } from 'react'
import { Input, Form, Tree, Button, message } from 'antd'
import { connect } from "react-redux";
const Item = Form.Item
class RoleAuth extends Component {
    state = {
        checkedKeys: [],
        defaultCheckedKeys: [],
        getRoleMenuData: []
    }

    componentDidMount() {
        const { getRoleMenu, record } = this.props
        getRoleMenu({ roleId: record.id }).then(res => {
            if (res.code === 0) {
                this.setState({ getRoleMenuData: res.data })
                var checkedKeys = []
                if (res.data.length > 0) {
                    res.data.map((item, index) => {
                        if (item.checked === true) {
                            checkedKeys.push(item.key)
                        }
                        if (item.children.length > 0) {
                            item.children.map(ite => {
                                if (ite.checked === true) {
                                    checkedKeys.push(ite.key)
                                }
                            })
                        }
                    })
                    this.setState({ defaultCheckedKeys: checkedKeys })
                }

            }
        })

    }
    componentWillUnmount() {
        this.setState({ defaultCheckedKeys: [] })

    }

    onCheck = (checkedKeys, info) => {
        //consol
        console.log('c',checkedKeys.checked)
        this.setState({
            defaultCheckedKeys: checkedKeys.checked
        })
    };
    onFinish = () => {
        const menuIds = this.state.defaultCheckedKeys.map(String)
        // const id = this.props.record.id
        //
        this.props.configPower({ menuIds: menuIds, roleId: this.props.record.id }).then(res => {
            message.success('分配成功')
            this.props.roleListPage({ page: 1, size: 10 })
        }
        )
        this.setState({ defaultCheckedKeys: [] })
        this.props.handleCancel()


        // this.props.clear()
    };

    onFinishFailed = (errorInfo) => {

    };

    render() {
        const { record, } = this.props
        const { defaultCheckedKeys, getRoleMenuData } = this.state


        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    preserve={false}
                    autoComplete="off"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        label="角色名："
                        rules={[{ required: true, message: '请输入标签名称!' }]}
                    >
                        <Input value={record.roleName} disabled />
                    </Form.Item>
                    <Form.Item

                    >
                        {
                            getRoleMenuData && getRoleMenuData.length > 0 ? (
                                <Tree
                                    checkable
                                    // multiple={true}
                                    titleRender={(getRoleMenuData) => { return (<span >{getRoleMenuData.title}</span>) }}
                                    wrapperCol={{ offset: 8, span: 16 }}
                                    // defaultExpandAll={true}
                                    onCheck={this.onCheck}
                                    checkedKeys={defaultCheckedKeys}
                                    treeData={getRoleMenuData}
                                    checkStrictly={true}
                                >
                                </Tree>

                            ) : null
                        }


                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                        <Button type="primary" htmlType="reset" style={{ marginLeft: 30 }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form >



            </div >
        )
    }
}
const mapState = state => ({
    ...state.role

});

const mapDispatch = dispatch => ({
    ...dispatch.role
})

export default connect(mapState, mapDispatch)(RoleAuth);