/* eslint-disable import/no-anonymous-default-export */
import { spread,settingList,addSetting,delSetting ,profitList,delProfitList,addProfitList,iosSwitch} from "../services/spreadApi";

export default {
    state: {
        data: [],
        setting:[],
        profitListData:[]

    },
    effects: {
        async spread(params) {
            const response = await spread(params)

            if (response.code === 200) {
                this.saveSetting({
                    data: response.data
                })
            }
        },
        async spreadSetting() {
            const response = await settingList()
            if (response.code === 200) {
                this.saveSetting({
                    setting: response.data
                })
            }
        },
        async profitList() {
            const response = await profitList()
            if (response.code === 200) {
                this.saveProfitList({
                    profitListData: response.data
                })
            }
        },
        async addProfitList(params) {
            await  addProfitList(params)
        },
        async addSetting(params) {
           await  addSetting(params)
        },
        async delSetting(params) {
           await  delSetting(params)
        },
        async delProfitList(params) {
           await  delProfitList(params)
        }, async iosSwitch(params) {
          return await  iosSwitch(params)
        },
    },
    reducers: {
        saveSpread(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveSetting(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveProfitList(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
    }
}