/* eslint-disable import/no-anonymous-default-export */
import {message} from 'antd';
import {
    accBalanceLog,
    add,
    changeStatus,
    details,
    homeData,
    resetPassword,
    userList,
    recommendList,
    delRecommend,
    addRecommend,
    pushMsg,
    pushAllMsg,
    updVip,
    vipoi
} from '../services/usersApi'
import {msgList,delMessage} from '../services/systemInfoApi'

export default {
    state: {
        data: [],
        userData: [],
        details: [],
        balanceData: [],
        homeInfo: [],
        changeStatusData: [],
        resetPasswordData: [],
        recommendData: [],
        msgData:[],
        userLoading:true,
     

    },
    effects: {
        async userList(params) {
            const response = await userList(params)
            if (response.code === 0) {
                this.saveData({
                    data: response.data,
                    userLoading:false
                })

            }
        },
        async add(params) {
            const response = await add(params)

            if (response.code === 0) {
                this.saveAdd({
                    userData: response.data
                })
                message.success('添加成功')
            }
        },
        async pushAllMsg(params) {
            return await pushAllMsg(params)

        },
        async updVip(params) {
            return await updVip(params)

        },
        //vipoi
        async detail(params) {
            const response = await details(params)

            if (response.code === 0) {

                this.saveDetails({
                    details: response.data
                })
            }
        },
        async vipoi(params) {
            const response = await vipoi(params)

            if (response.code === 0) {

                this.saveoi({
                    oi: response.data
                })
            }
        },
        async accBalanceLog(params) {
            const response = await accBalanceLog(params)

            if (response.code === 0) {

                this.saveAccBalanceLog({
                    balanceData: response.data
                })
            }
        },
        async homeData(params) {
            const response = await homeData(params)

            if (response.code === 0) {

                this.saveHomeData({
                    homeInfo: response.data
                })
            }
        },
        async changeStatus(params) {
            const response = await changeStatus(params)
            if (response.code === 0) {
                this.saveChangeStatus({
                    changeStatusData: response.data
                })
            }
        },
        async resetPassword(params) {
            const response = await resetPassword(params)
            return response
        },

        async recommendList(params) {
            const response = await recommendList(params)
            if (response.code === 0) {
                this.saveRecommendData({
                    recommendData: response.data
                })

            }
        },
        async msgList(params) {
            const response = await msgList(params)
            if (response.code === 0) {
                this.saveMsgData({
                    msgData: response.data
                })

            }
        },
        async delMsg(params) {
            await delMessage(params)
        },
        async pushMsg(params) {
            await pushMsg(params)
        },
        async delRecommend(params) {
            await delRecommend(params)
        },
        async addRecommend(params) {
            await addRecommend(params)
        },


    },

    reducers: {
        saveRecommendData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveMsgData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },

        saveData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveAdd(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveDetails(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveAccBalanceLog(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveHomeData(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveChangeStatus(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveoi(state, payload) {
            return {
                ...state,
                ...payload
            }
        },


    }

}