import React, {Component} from 'react';
import {connect} from "react-redux";
import {Popconfirm, Table,Image} from 'antd';
import {sc} from "../../../constant/consts";

class FaceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            name: '',
            faceStatus: '',
            current: 1
        }
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'autoId',
                align: 'center',

            },
            {
                title: '用户名',
                dataIndex: 'name',
                align: 'center',

            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                align: 'center',

            },
            {
                title: '图片',
                align: 'center',

                render: (_, record) => record.faceUrl ?
                    <div><Image style={{width: 80, height: 80}} src={record.faceUrl}/></div> : null
            },

            {
                title: '审核',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            { record.faceStatus === 2 ? <div style={{color: "green",marginLeft:10}}>审核通过</div>
                            : record.faceStatus === -1 ? <div style={{color: "red",marginLeft:10}}>已拒绝</div>
                                    : record.faceStatus === 0 ? <div style={{color: "gray",marginLeft:10}}>未认证</div>
                                    : <span>
                                        <Popconfirm
                                            title="通过？"
                                            onConfirm={() => this.pass(record)}
                                            okText="确定"
                                            cancelText="取消"
                                        >
                                            <a>通过</a>
                                        </Popconfirm>
                                        <Popconfirm
                                            title="拒绝？"
                                            onConfirm={() => this.reject(record)}
                                            okText="拒绝"
                                            cancelText="取消"
                                        >
                                            <a style={{marginLeft:20}}>拒绝</a>
                                        </Popconfirm>
                                </span>}

                        </div>
                    )
                }
            },
        ];
    }

    componentDidMount() {
        this.init();
    }


    init = () => {
        const {faceList} = this.props
        faceList({page: 1, size: 10,faceStatus:1})
    }

    query = (current, pageSize) => {
        const {newValues} = this.props
        newValues.page = current
        this.setState({current})
        this.props.faceList({...newValues})
    }
    reject = (record) => {

        let {newValues,data} = this.props
        newValues.page=data.current;
        this.props.changeStatus({autoId: record.autoId,faceStatus:-1}).then(res => {
            this.props.faceList(newValues)
        })
    }
    pass = (record) => {
        let {newValues,data} = this.props
        newValues.page=data.current;
        this.props.changeStatus({autoId: record.autoId,faceStatus:2}).then(res => {
            this.props.faceList(newValues)
        })
    }

    render() {
        const {data, userLoading} = this.props;

       // const {current} = this.state;
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.query,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records}
                       rowKey={record => record.autoId} pagination={pagination}
                       scroll={sc}
                />
            </div>
        );
    }


}

const mapState = state => ({
    ...state.face
});

const mapDispatch = dispatch => ({
    ...dispatch.face

})

export default connect(mapState, mapDispatch)(FaceList);
