import React from 'react';
import {menus} from "./menu";

function parseData(data, permit) {
    let res = [];
    data.forEach(item => {

        permit.forEach(f => {
            if (item.auth === f.remarks) {
                item.path = '/'+f.menuUrl;
                item.title = f.menuName
                res.push(item);

            }
        })
    });

    return res;
}

function handleMenus(data, permit) {
    const menu = [];
    data.forEach(item => {
        permit.forEach(f => {
            if (item.auth === f.remarks) {
                item.path = '/'+f.menuUrl;
                item.title = f.menuName;
                if (item.children && item.children.length > 0) {
                    item.children = parseData(item.children, permit)
                }
                menu.push(item);

            }
        })

    })
    return menu;

}

function handleRouters(data) {
    const routes = [];
    data.forEach(item => {
        if (item.children) {
            item.children.forEach(c=>{
                if (c.component)
                    routes.push(c);
            })
            //handleRouters(item.children);
        } else {
            if (item.component) {

                routes.push(item);
            }
        }
    });
    return routes
}

export default function data(permit) {
    const menu = handleMenus(menus, permit)
    const routes = handleRouters(menu);
    return {menu: menu, routes: routes}
}
