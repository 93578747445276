import request from '../utils/request';
import url from '../utils/url';

//活动列表
export function activityListPage(params) {
    return request(url.activityListPage, {
        method: 'POST',
        params
    })

}
//添加活动
export function addAactivity(params) {
    return request(url.addAactivity, {
        method: 'POST',
        params
    })

}
export function imgs(params) {
    return request(url.imgs, {
        method: 'POST',
        params
    })

}
export function delAactivity(params) {
    return request(url.delAactivity, {
        method: 'POST',
        params
    })

}