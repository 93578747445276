/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { Col, Row, Space, message } from 'antd';
import { ProFormSelect, ProFormText, ProForm,ProFormSwitch } from '@ant-design/pro-form';
import { connect } from "react-redux";

class Parse extends Component {
    state = {
        status: [
            { label: '正常', value: 1 },
            { label: '失效', value: 0 }
        ],
        proxies: [
            { label: '开启', value: 1 },
            { label: '关闭', value: 0 }
        ],
        ctypes: [
            { label: 'A', value: "A" },
            { label: 'CNAME', value: "CNAME" },
            { label: 'TXT', value: "TXT" },
        ],

    };

    onFinish = (values) => {

        if (this.props.row && this.props.row.id)
            values.id = this.props.row.id
        
        if(this.props.parseType==0){
            this.props.domainParse(values)
            .then(res=>{
                if (res.code == 0) {
                    message.info('操作成功')
                    this.props.domainList()
                    this.props.handleCancel()
                }
                else
                    message.info(res.message)
            })
        }else{
            this.props.dnsUpd(values)
            .then(res=>{
                if (res.code == 0) {
                    message.info('操作成功')
                    this.props.domainList()
                    this.props.handleCancel()
                }
                else
                    message.info(res.message)
            })
        }

    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { row } = this.props
        console.log('rr',row)

        return (
            <ProForm
                initialValues={row ? {
                    doMain: row.doMain,
                    name:row.name,
                    ip: row.ip,
                    cType:row.ctype,
                    remark: row.remark,
                    status: row.status,
                    tips: row.tips,
                    proxied:row.proxied

                } : { status: 1,proxied:0 }}
                layout='vertical'
                submitter={{
                    render: (props, doms) => {
                        return (
                            <Row>
                                <Col offset={16}>
                                    <Space>{doms}</Space>
                                </Col>
                            </Row>
                        );
                    },
                }}
                onFinish={(v) => this.onFinish(v)}
            >
                <ProFormText  placeholder={row.doMain} disabled={true} label="解析域名" />
                <ProFormSelect rules={[{ required: true, message: '请选择解析类型' }]}
                    name="cType" label="类型" placeholder="*泛解析 @一级域名解析" options={this.state.ctypes} />

                <ProFormText rules={[{ required: true, message: '请输入解析记录' }]} name="name" label="记录" placeholder="@一级域名解析 *泛解析" />
                <ProFormText rules={[{ required: true, message: '请输入解析内容' }]} name="ip" label="内容" placeholder="ip或记录值" />
                <ProFormSelect rules={[{ required: true, message: '请选择解析类型' }]}
                    name="proxied" label="代理" placeholder="cf代理模式" options={this.state.proxies} />
                {/* <ProFormSwitch
                    initialValue={false}
                    label="是否解析"
                    name="parse"
                /> */}
                <ProFormText name="tips" label="描述" />
                <ProFormText name="remark" label="备注" />
            </ProForm>
        )
    }
}

const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(Parse);