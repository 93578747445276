/* eslint-disable import/no-anonymous-default-export */
import {
    homeData,
    indexDashboard
} from '../services/homeApi';

export default {
    state: {
        data: [],
        echartsData: [],
        getAdminMenuListData: []
    },
    effects: {
        /* 获取用户列表 */
        async homeData(params) {
            const response = await homeData(params);
            if (response.code === 0) {

                this.saveHomeinfo(response)
            }
        },
        async indexDashboard(params) {
            return  await indexDashboard(params)

           
        },

    },

    reducers: {
        saveIndexDashboard(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveHomeinfo(state, payload) {
            return {
                ...state,
                ...payload
            }
        },

    }
}