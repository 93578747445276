import request from '../utils/request';
import url from '../utils/url';

//视频列表
export function list() {
    return request(url.videoPaths, {
        method: 'POST'
    });
}
//同步资源库
export function syncVideo() {
    return request(url.videoSync, {
        method: 'POST'
    });
}
//标签数据
export function child(params) {
    return request(url.childTag, {
        method: 'POST',
        params
    });
}

//转码
export function parse(params) {
    return request(url.videoParse, {
        method: 'POST',
        params
    });
}
