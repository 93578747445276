import React, { Component } from 'react'
import RoleList from './RoleList'
import style from './Role.module.scss'
import { connect } from "react-redux";
import { removeEmptyField } from '../../compoent/emptyFild'
import moment from 'moment';
import AddRole from './component/AddRole';
import {
    Form,
    Input,
    Button,
    Modal,
    TimePicker,
    DatePicker,
    Select,

} from 'antd';
const { Option } = Select;

class Role extends Component {
    state = {
        visible: false
    }
    componentDidMount() {

    }
    onFinish = (values) => {

        if (values) {
            //
            values.page = 1
            values.size = 10
            let newValues = removeEmptyField(values)
            this.props.roleListPage(newValues)
        } else {
            this.props.roleListPage()
        }

    };
    onFinishFailed = (errorInfo) => {

    };

    addRole = () => {
        this.setState({
            visible: true
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    render() {
        const { RangePicker } = DatePicker;
        const { data, visible } = this.state

        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{ width: 320 }}
                            label="账户名"
                            name="roleName"
                        >
                            <Input placeholder='请输入发单人查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 320 }}
                            label="ID"
                            name="id"
                        >
                            <Input placeholder='请输入发单人查询' />
                        </Form.Item>


                        <Form.Item style={{ marginLeft: 100, marginTop: 0 }}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{ marginLeft: 42 }}>
                                重置
                            </Button>
                            <Button onClick={this.createUser} type="primary" onClick={this.addRole} style={{ marginLeft: 30 }}>
                                新增
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <RoleList />
                </div>
                <div>
                    <Modal destroyOnClose visible={visible} title='编辑' footer={null} onCancel={this.handleCancel} >
                        <AddRole handleCancel={this.handleCancel}></AddRole>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.role

});

const mapDispatch = dispatch => ({
    ...dispatch.role
})

export default connect(mapState, mapDispatch)(Role);