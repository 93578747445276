import React, {Component} from 'react'
import AgreementList from './AgreementList'
import style from './Agreement.module.scss'
import {connect} from "react-redux";
import {Button, Modal} from 'antd';
import Add from './component/Add'

class Agreement extends Component {
    state = {
        visible: false,
        type: ''
    };

    handleCancel = () => {
        this.setState({
            visible: false
        })
    };
    add = () => {
        this.setState({
            visible: true,
            type: '0'
        })
    }

    render() {
        const {  visible, type } = this.state;
        return (
            <div>
                <div className={style.top}>
                    <Button onClick={this.add} type="primary" style={{ marginLeft: 30 }}>
                        新增
                    </Button>
                </div>
                <div className={style.middle}>
                    <AgreementList />
                </div>
                <div>
                    <Modal destroyOnClose visible={visible} title='新增' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} type={type}></Add>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.agreement

});

const mapDispatch = dispatch => ({
    ...dispatch.agreement
})

export default connect(mapState, mapDispatch)(Agreement);