/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import PayList from './PayList'
import style from './Pay.module.scss'
import { connect } from 'react-redux';
// import moment from 'moment';
import { removeEmptyField } from '../compoent/emptyFild';
import {
    Form,
    Input,
    Button,
    DatePicker,
    Select,
} from 'antd';
import moment from 'moment';
import {tradeType_recharge} from "../../constant/consts";

const { Option } = Select;


class Pay extends Component {
    state = {
        payment: [
            {
                id: '1',
                name: '微信'
            },
            {
                id: '2',
                name: '支付宝'
            },
            {
                id: '3',
                name: '手工加款'
            },
        ],
        status: [
            {
                id: 0,
                name: '支付失败'
            },
            {
                id: 1,
                name: '支付成功'
            },
        ]
    }
    onFinish = (values) => {
        if (values) {
            values.page = 1
            values.size = 10
            values.tradeType = tradeType_recharge
            const newValues = removeEmptyField(values)
            this.props.balanceLog(newValues)
        } else {
            this.props.balanceLog()
        }

    };
    onFinishFailed = (errorInfo) => {

    };

    render() {
        const { RangePicker } = DatePicker;
        const { payment, status } = this.state
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{ width: 250 }}
                            label="用户名"
                            name="nickName"
                        >
                            <Input placeholder='请输入用户名查询' />
                        </Form.Item>

                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="真实姓名"
                            name="name"
                        >
                            <Input placeholder='请输入真实姓名查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="手机号"
                            name="mobile"
                        >
                            <Input placeholder='请输入手机号查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="ID"
                            name="id"
                        >
                            <Input placeholder='请输入ID查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 200, marginLeft: 20 }}
                            label="渠道"
                            name="method"
                        >
                            <Select
                                showSearch
                                placeholder='请选择充值渠道'

                            // style={{ width: 200 }}
                            // placeholder="Select a person"

                            >
                                {payment.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ width: 200, marginLeft: 20 }}
                            label="状态"
                            name="status"
                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            <Select
                                showSearch
                                placeholder='请选择充值状态'
                            // style={{ marginLeft:10}}
                            >
                                {status.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: 42 }}
                            label="发起时间"
                            name="addBeginTime"
                        // rules={[{ message: 'Please input your username!' }]}
                        > <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }} />

                        </Form.Item>

                        <Form.Item
                            style={{ marginLeft: 20, marginTop: 42 }}
                            label="结束时间"
                        // name="addEndTIme"
                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            {/* <DatePicker
                                showTime
                                style={{ marginRight: 20 }} /> */}
                            <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }} />
                        </Form.Item>


                        <Form.Item style={{ marginLeft: 600, marginTop: 42 }}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType="reset" style={{ marginLeft: 42 }}>
                                重置
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <PayList />
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.pay
})
const mapDispatch = dispatch => ({
    ...dispatch.pay
})

export default connect(mapState, mapDispatch)(Pay);
