//dev test prod
export const env = 'prod';

const dev = 'http://localhost:8010/js/';
//const test = 'https://dev.quqdan.com/qw/';
const test = 'http://localhost:8010/js/';
const prod = 'https://prod.smallybook.xyz/js/';
//const prod = 'https://prod.whaala.com/qw/';
const urlApi = env === 'dev' ? dev : env === 'test' ? test : env === 'prod' ? prod : dev;

export default {
    urlApi: urlApi,

    // =================================================
    login: urlApi + "admin/login", //用户登录
    resetPassword: urlApi + "admin/user/resetPassword", //用户登录
    updatePassword: urlApi + "admin/updatePassword", //修改密码
    homeData: urlApi + 'admin/index/user/total',//首页数据汇总
    userList: urlApi + 'admin/users',//会员列表
    details: urlApi + 'user/details',//会员详细信息
    updVip: urlApi+'admin/users/updVip',
    accBalanceLog: urlApi + 'admin/users/order',//会员账变
    changeStatus: urlApi + 'user/changeStatus',//会员开启/禁用
    balanceLog: urlApi + 'data/balanceLog',//充值-提现记录
    vipLog:urlApi+'admin/orders', //会员充值记录
    vipoi:urlApi+'admin/oi', //会员充值记录
    indexDashboard: urlApi + 'admin/indexDashboard',//home折线图
    imgs: urlApi + 'common/upload/imgs',//图片上传
    img: urlApi + 'common/upload/img',//图片上传

    //报表
    finance: urlApi + 'data/finance',//财务报表这
    spread: urlApi + 'data/spread',//推广报表
    preserve: urlApi + 'data/preserve',//推广报表
    exportSpread: urlApi + 'data/export/spread',//导出推广报表
    exportFinance: urlApi + 'data/export/finance',//导出财务报表cate
    exportPreserve: urlApi + 'data/export/preserve',//留存报表导出
    delCateTag: urlApi + 'admin/cate/delCateTag',//标签删除
    add: urlApi + 'user/add',//添加管理员
    //动态管理
    // delDynamic: urlApi + 'dynamic/delDynamic',//删除动态管理
    // managerDynamicListPage: urlApi + 'dynamic/managerDynamicListPage',//动态管理列表分页
    //后台管理员
    addAdmin: urlApi + 'admin/addAdmin',//添加管理员
    // adminList: urlApi + '/admin/adminList',//管理员列表
    adminListPage: urlApi + 'admin/adminListPage',//管理员列表分页
    delAdmin: urlApi + 'admin/delAdmin',//删除管理员
    detailAdmin: urlApi + 'admin/detailAdmin',//管理员详情
    getAdminMenuList: urlApi + 'admin/getAdminMenuList',//获取管理员菜单列表（登录成功
    updateAdmin: urlApi + 'admin/updateAdmin',//管理员修改
    //后台角色
    addRole: urlApi + 'admin/role/addRole',//添加角色
    configPower: urlApi + 'admin/role/configPower',//分配权限
    delRole: urlApi + 'admin/role/delRole',//删除角色
    detailRole: urlApi + 'admin/role/detailRole',//角色详情
    getRoleMenu: urlApi + 'admin/role/getRoleMenu',//角色关联菜单（树形结构
    roleList: urlApi + 'admin/role/roleList',//角色列表
    roleListPage: urlApi + 'admin/role/roleListPage',//角色列表分页
    updateRole: urlApi + 'admin/role/updateRole',//角色修改
    updateStatus: urlApi + 'admin/updateStatus',//角色状态修改
    pushAllMsg: urlApi + "msg/push/personal/where", //个人消息群发
  

    //服务分类
    addCateTag: urlApi + 'admin/cate/addCateTag',//标签添加
    list: urlApi + 'admin/cate/list',//分类标签列表
    parentTag: urlApi + 'admin/cate/parentTag',//分类标签查询
    childTag: urlApi + 'admin/cate/childTag',//分类标签查询
    updCateTag: urlApi + 'admin/cate/updCateTag',//标签修改
    //系统设置
    pay: urlApi + 'system/pay',//支付开关列表
    upd: urlApi + 'system/pay/upd',//开启关闭支付


    //活动管理
    activityListPage: urlApi + 'activity/activityListPage',//活动列表
    addAactivity: urlApi + 'activity/addAactivity',//添加活动
    delAactivity: urlApi + 'activity/delAactivity',//删除活动

  

    //app用户日志
    memberLogList: urlApi + 'security/securityLogListPage',//app用户日志列表

    //会员推荐信息
    memberRecommendList: urlApi + 'memberRecommend/recommendListPage',//会员推荐列表
    delMemberRecommend: urlApi + 'memberRecommend/delRecommend',//删除会员推荐
    addMemberRecommend: urlApi + 'memberRecommend/addRecommend',//添加会员推荐列表
   

    //菜单
    menuList: urlApi + 'admin/menu/list',//菜单列表
    parentMenuList: urlApi + 'admin/menu/parent',//菜单列表
    delMenu: urlApi + 'admin/menu/delMenu',//删
    addMenu: urlApi + 'admin/menu/addMenu',//添加
    updMenu: urlApi + 'admin/menu/updateMenu',//修改
    updMenuStatus: urlApi + 'admin/menu/status',//修改状态

    //banner
    bannerList: urlApi + 'admin/banner/bannerList',//banner列表
    delBanner: urlApi + 'admin/banner/delbanner',//删
    addBanner: urlApi + 'admin/banner/addBanner',//添加
    updBanner: urlApi + 'admin/banner/updateBanner',//修改
    updBannerStatus: urlApi + 'admin/banner/status',//修改状态

    // 支付控制
    payControlList: urlApi + 'admin/system/pay',//列表
    updPayStatus: urlApi + 'admin/system/pay/upd',//修改状态
    delPayType: urlApi + 'admin/system/pay/del',//添加
    editPayType: urlApi + 'admin/system/pay/edit',//编辑


    //推广配置
    spreadSettingList: urlApi + 'spreadSetting/list',//配置列表数据
    addSpreadSetting: urlApi + 'spreadSetting/add',//添加配置
    delSpreadSetting: urlApi + 'spreadSetting/del',//删除配置
    profitList: urlApi + 'spreadSetting/profitList',//盈利榜单数据列表
    addProfitList: urlApi + 'spreadSetting/addProfitList',//盈利榜单数据列表
    delProfitList: urlApi + 'spreadSetting/delProfitList',//盈利榜单数据列表
    iosSwitch: urlApi + 'spreadSetting/switch',//ios配置开关



    //vip
    vipRuleList: urlApi + 'admin/rule/vipRuleList',//vip配置信息列表
    updateTVipRule: urlApi + 'admin/rule/updateTVipRule',//新增
    delVipRule: urlApi + 'admin/rule/delTVipRule',

    //推广
    scaleList:urlApi+'system/spread/list',
    scaleUpdate:urlApi+'system/spread/update',

    //视频
    videoPaths:urlApi+'admin/video/resource/list',
    videoSync:urlApi+'admin/video/sync',
    videoParse:urlApi+'admin/video/parse', //视频转码
    videoList:urlApi+'admin/video/list',
    videoUpdate:urlApi+'admin/video/upd',
    videoAdd:urlApi+'admin/video/add',
    videoStatus:urlApi+'admin/video/status',
    videoCdnCache:urlApi+'admin/video/cdn.cache',
    videoCdnRefresh:urlApi+'admin/video/cdn.refresh',

    sortPaths:urlApi+'admin/sort/resource/list',
    sortSync:urlApi+'admin/sort/sync',
    sortParse:urlApi+'admin/sort/parse', //视频转码
    sortList:urlApi+'admin/sort/list',
    sortUpdate:urlApi+'admin/sort/upd',
    sortAdd:urlApi+'admin/sort/add',
    sortStatus:urlApi+'admin/sort/status',

    //漫画
    mhPage:urlApi+'admin/manhua/page',//分页列表
    mhSync:urlApi+'admin/mh/sync', //同步资源库
    mhSyncZj:urlApi+'admin/mh/sync/zj', //同步资章节
    mhPaths:urlApi+'admin/manhua/resource/list', //资源库列表
    mhCreate:urlApi+'admin/manhua/create', //生成漫画
    mhUpd:urlApi+'admin/manhua/upd', //修改i
    mhStatus:urlApi+'admin/manhua/status', //章节状态修改
    mhzjs:urlApi+'admin/manhua/zjlist', //章节列表 mhzjFeesType
    mhzjSort:urlApi+'admin/manhua/zjSort',
    mhzjFeesType:urlApi+'admin/manhua/zjType',
    mhAddZj:urlApi+'admin/mh/add/zj',

    //系统参数
    systemParams:urlApi+'admin/system/params/list',//分页列表
    systemParamsEdit:urlApi+'admin/system/params/edit',//edit
    systemParamsDel:urlApi+'admin/system/params/del',//del
    systemValueList:urlApi+'admin/system/value/list',//value list
    systemValueUpd:urlApi+'admin/system/value/upd',//value edit/system/file/del
    systemFileDel:urlApi+'admin/system/file/del', //文件删除
    systemSourceDel:urlApi+'admin/system/source/del', //资源删除
    systemSourceClean:urlApi+'admin/system/source/clean', //资源缓存删除
    systemServiceImport:urlApi+'admin/system/import', //资源缓存删除

    //domain
    domainList:urlApi+'admin/dm/list', //资源缓存删除
    domainUpd:urlApi+'admin/dm/upd', //资源缓存删除
    domainListAll:urlApi+'admin/dm/all', //资源缓存删除
    domainListAcc:urlApi+'admin/dm/acc', //资源缓存删除
    domainSync:urlApi+'admin/dm/sync', //资源缓存删除
    serviceList:urlApi+'admin/dm/services', //资源缓存删除
    serviceAddOrUpd:urlApi+'admin/dm/service/upd', //资源缓存删除
    serviceIpAdd:urlApi+'admin/dm/service/ip/add', //资源缓存删除
    serviceIpUpd:urlApi+'admin/dm/service/ip/upd', //资源缓存删除
    domainParse:urlApi+'admin/dm/parse', //资源缓存删除
    dnsUpd:urlApi+'admin/dm/dns/upd', //资源缓存删除
    dnsDel:urlApi+'admin/dm/dns/del', //资源缓存删除
    //serviceList
    //渠道
    channelPage:urlApi+'admin/channel/list',//分页列表
    channelUpd:urlApi+'admin/channel/upd',//渠道修改
    channelStatus:urlApi+'admin/channel/status',//渠道修改
    channelDetailList:urlApi+'admin/channel/detail/list',//渠道详情
    channelDetailStatus:urlApi+'admin/channel/detail/status',//渠道详情
    setLink:urlApi+'admin/channel/set/link',//渠道详情
    channelAcc:urlApi+'admin/channel/account',//渠道详情 channel/xl
    channelXl:urlApi+'admin/channel/xl',//渠道详情 channel/xl

    //数据分析
    anData:urlApi+'admin/an/data',// 小时数据分布排行
    anClick:urlApi+'admin/an/click',//分类 内容点击排行
    anSpMh:urlApi+'admin/an/spmh',//视频漫画 点击量数据分析


};
