import request from '../utils/request';
import url from '../utils/url';

//banner信息列表
export function list(params) {
    return request(url.bannerList, {
        method: 'POST',
        params
    });
}

//添加
export function add(params) {
    return request(url.addBanner, {
        method: 'POST',
        params
    });
}

//修改
export function upd(params) {
    return request(url.updBanner, {
        method: 'POST',
        params
    });
}

//shanchu
export function del(params) {
    return request(url.delBanner, {
        method: 'POST',
        params
    });
}


export function status(params) {
    return request(url.updBannerStatus, {
        method: 'POST',
        params
    });

}

//图片上传
export function upload(params) {
    return request(url.img, {
        method: 'POST',
        params
    })

}


