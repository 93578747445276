import {createStore} from 'redux'

export const open = "openLoading"
export const close = "closeLoading"
//开启loading
export const openLoading = () => ({
    type: open,
})

//关闭loading
export const closeLoading = () => ({
    type: close,
})


let defaultState = {
    loading: false,
};

function Reducer(state = defaultState, action) {
    let page = {...state};
    switch (action.type) {
        case open: {
            page.loading = true;
            return page;
        }
        case close: {
            page.loading = false;
            return page;
        }
        default:
            return state;
    }
}


export default createStore(Reducer);




