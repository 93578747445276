/* eslint-disable import/no-anonymous-default-export */
import {list} from '../services/memberLogApi';

export default {
    state: {
        data: []
    },
    effects: {
        async list(params) {
            const response = await list(params)
            if (response.code === 200) {
                this.saveData({
                    data: response.data,

                })
            }
        }
    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },

    }
}