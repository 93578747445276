/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Image, Col, Row, Popconfirm, Table, Modal, Tag, Badge, Button } from 'antd';
import ReactPlayer from 'react-player'
import Hls from 'hls.js';
import Add from './Add'
import Comment from './source/Comment'

class FeedbackList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            play: false,
            visible: false,
            row: {},
            selectedRowKeys: [],
            loading: false,
            showList: false,
            title: "",
            type: '',
            current: 1,
            newValues: {}
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',


            },
            {
                title: '标题',
                dataIndex: 'title',
                align: 'center',
                width: 180,
            },
            {
                title: '封面图片',
                dataIndex: 'titleImg',
                align: 'center',
                render: (_, record) => (

                    <Image style={{ width: 100, height: 80 }}
                        preview={{
                            imageRender: () => (
                                <ReactPlayer className='react-player'
                                    //这里是由上级页面传过来的视频地址
                                    url={record.videoPath.replace('v.ts','v.m3u8')}
                                    playing={this.state.play}
                                    width='80%'
                                    height="80%"
                                    controls

                                />
                            ),
                            maxScale: 40,
                            onVisibleChange: (flag) => {
                              
                                this.auto(!flag)
                              
                            },

                        }}
                        src={_} />

                )


            },
            {
                title: '付费类型',
                align: 'center',
                tip: '设置金币类型 vip也需要支付金币才能观看',
                dataIndex: 'feesType',
                hideInSearch: true,//processing' : 'default'
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_ === 1 ? 'success' : _ === 0 ? 'default' : _ === 2 ? 'processing' : 'error'}
                            text={_ === 1 ? 'vip' : _ === 0 ? '免费' : _ === 2 ? '金币' : '暂无设置'} />
                    </a>

                )
            },
            {
                title: '价格',
                align: 'center',
                tip: '只有金币付费该价格才有效',
                dataIndex: 'feesType',
                hideInSearch: true,//processing' : 'default'
                render: (_, record) => (
                    <a>
                        {
                            _ === 2 ? record.feesPrice : '-'
                        }
                    </a>

                )
            },
            {
                title: '标签',
                dataIndex: 'tagList',
                align: 'center',
                width: 180,
                render: (_, record) => (<div>
                    {_ ? _.map((i, k) => (
                        <Tag key={k} color="processing" >
                            {i}
                        </Tag>)) : ''}
                </div>)
            },


            {
                title: '时长',
                dataIndex: 'seeTime',
                align: 'center',
            },
            {
                title: '收藏',
                dataIndex: 'collect',
                align: 'center',
            },

            {
                title: '更新时间',
                dataIndex: 'updateTime',
                align: 'center',
            },
            {
                title: '添加时间',
                dataIndex: 'addTime',
                align: 'center',
            },
            {
                title: '状态',
                align: 'center',
                dataIndex: 'status',
                fixed: 'right',
                render: (_, record) => (
                    <a>
                        <Popconfirm
                            title="变更状态"
                            onConfirm={() => this.status(record)}
                            okText="确定"
                            cancelText="取消"
                        >


                            <Badge
                                status={_ === 1 ? 'success' : _ === 0 ? 'default' : 'error'}
                                text={_ === 1 ? '已上架' : _ === 0 ? '未上架' : '错误'} />
                        </Popconfirm>
                    </a>

                )
            },
            {
                title: '操作',
                align: 'center',
                fixed: 'right',
                render: (_, record) => {
                    return (
                        <div>
                            <a disabled={this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0} onClick={() => this.update(record)}>编辑</a>
                            {record.status == 0 ?
                                <a style={{ marginLeft: 10, color: 'red' }}>
                                    <Popconfirm
                                        title="删除"
                                        onConfirm={() => this.del(record)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        删除

                                    </Popconfirm>
                                </a> : ''
                            }
                        </div>
                    )
                }
            },


        ];
    }

    componentDidMount() {

        this.props.sortList({ ...this.props.newValues })
        this.setState({ newValues: this.props.newValues })
    }
    del = (record) => {
        this.props.fileDel({ id: record.id, type: 'dsp' })
            .then(res => {
                if (res.code === 0) {
                    this.props.sortList({ ...this.state.newValues })
                }
            })
    }

    handleChangeList = (current, pageSize) => {
        const { newValues } = this.props
        newValues.page = current
        newValues.size = pageSize
        this.setState({ newValues })
        this.props.sortList(newValues)
    }
    update = (record) => {
        this.setState({ visible: true, type: "0", title: '编辑', row: record })
    }
    openList = (record) => {
        this.setState({ showList: true })
    }
    status = (row) => {
        this.props.changeStatus({ id: row.id, status: row.status == 1 ? 0 : 1 })
            .then(res => {
                if (res.code === 0) {
                    this.props.sortList(this.state.newValues)
                }
            })

    }

    auto = (e) => {
        this.setState({
            play: e
        })

    }
    start = () => {
        this.setState({ loading: true, visible: true, type: "1", title: '批量修改(' + this.state.selectedRowKeys.length + ")" })
        setTimeout(() => {
            this.setState({ loading: false });

        }, 1000);
    };
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({ selectedRowKeys: newSelectedRowKeys })
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            showList: false,
            selectedRowKeys: [],
            row: null
        })
        this.props.sortList(this.state.newValues)
    };

    render() {
        const { data, userLoading, tagList } = this.props;
        const { visible, title, row, selectedRowKeys, loading, type, showList } = this.state
        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = { selectedRowKeys, onChange: this.onSelectChange, }
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: true//大于50时显示pageSize切换器
        };
        return (
            <div style={{ paddingTop: 10 }}>
                <Row >
                    <Col span={22} >
                        <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                            批量操作
                        </Button>
                    </Col>
                    <Col span={2} >
                        <Button type="primary" onClick={this.openList} >
                            资源列表
                        </Button>
                    </Col>
                </Row>



                <Table columns={this.columns} loading={userLoading} scroll={{ x: 'max-content' }}
                    rowSelection={rowSelection} dataSource={data.records} rowKey={record => record.id} pagination={pagination}
                />
                <Modal destroyOnClose open={visible} title={title} footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add current={this.state.current} handleCancel={this.handleCancel} childs={tagList} row={row} ids={selectedRowKeys} type={type} />
                </Modal>

                <Modal destroyOnClose open={showList} title={title} width={1200} footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Comment handleCancel={this.handleCancel} tags={tagList} />
                </Modal>

            </div>
        );
    }
}

const mapState = state => ({
    ...state.feedback
});

const mapDispatch = dispatch => ({
    ...dispatch.feedback

})

export default connect(mapState, mapDispatch)(FeedbackList);