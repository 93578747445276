import React, { Component } from 'react'
import { DatePicker, Popconfirm, Table } from 'antd';
import { connect } from "react-redux";
import dayjs from 'dayjs';
import ProTable from '@ant-design/pro-table';

const { RangePicker } = DatePicker;
class DetailList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            value: { begin: '', end: '' },
            timeSelect: [
                {
                    label: '昨天',
                    value: () => [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')]
                },
                {
                    label: '今天',
                    value: () => [dayjs(), dayjs().endOf('day')]
                },
                {
                    label: '上周',
                    value: () => [dayjs().add(-1, 'week').startOf('week'),
                    dayjs().add(-1, 'week').endOf('week')]
                },
                {
                    label: '本周',
                    value: () => [dayjs().startOf('week'), dayjs().add(-1, 'd')]
                },
                {
                    label: '本月',
                    value: () => [dayjs().startOf('month'), dayjs().add(-1, 'd')]
                },
                {
                    label: '上月',
                    value: () => [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')]
                }

            ],


        };
        this.columns = [
            {
                title: '日期',
                dataIndex: 'createDate',
                align: 'center',

            },
            {
                title: 'ip/用户/注册/首充',
                tip: ' 用户 进入网站会员充值页面或绑定账号 首充=进入网站充值的用户',
                align: 'center',
                render: (_, record) => <div >
                    {record.totalUser}<span style={{marginLeft:3,marginRight:3}}>/</span>{record.registerUser}<span style={{marginLeft:3,marginRight:3}}>/</span>{record.regist}<span style={{marginLeft:3,marginRight:3}}>/</span>{record.firstPayUserTotal}

                </div>

            },
            {
                title: '订单/成功',
                tip: '订单 =单天发起的订单总数量  成功= 已支付订单',
                align: 'center',
                render: (_, record) => <div >
                {record.orderCount}<span style={{marginLeft:3,marginRight:3}}>/</span>{record.payOrderCount}

            </div>

            },

            {
                title: '金额/成功',
                align: 'center',
                tip: '金额=当天发起订单的总金额 成功= 当天已支付成功的总金额',
                render: (_, record) => <div >
                {record.totalPrice}<span style={{marginLeft:3,marginRight:3}}>/</span>{record.totalPayPrice}

            </div>

            },
            {
                title: '首充/复充',
                tip: '首充=用户进入网站后24小时内充值 复充=支付总金额 - 首充金额',
                align: 'center',
                render: (_, record) => <div >
                {record.firstPayTotal}<span style={{marginLeft:3,marginRight:3}}>/</span>{record.totalPayPrice - record.firstPayTotal}

            </div>

            },

            {
                title: '充值系数/订单系数',
                tip: '充值基数 = 首充 / ip  订单系数=  金额 / 订单数 ',
                align: 'center',
                render: (_, record) => <div >
                {(Math.floor(record.firstPayUserTotal *100 )/ record.totalUser).toFixed(2)}<span style={{marginLeft:3,marginRight:3}}>/</span>{Math.floor(record.totalPayPrice / record.payOrderCount)}

            </div>

            },

            // {
            //     title: '操作',
            //     align: 'center',
            //     // dataIndex: 'addTime',
            //     render: (_, record) => {
            //         return (
            //             <div>
            //                 {/* <a onClick={() => this.updateInfo(record)}>编辑</a> */}
            //                 <Popconfirm
            //                     title="确定删除吗？"
            //                     onConfirm={() => this.del(record)}
            //                     okText="删除"
            //                     cancelText="取消"
            //                 >
            //                     <a >删除</a>
            //                 </Popconfirm>
            //             </div>
            //         )
            //     }
            // },


        ];

    }



    // componentDidMount() {
    //     const { value } = this.state
    //     value.id = this.props.row.id
    //     this.setState({ value })
    //     this.props.channelDetailList({ ...value })
    // }

    query = (current, pageSize) => {
        const { value } = this.state;
        value.page = current;
        this.setState({ current });
        this.props.channelDetailList({ ...value })
    };


    del = (record) => {
        const { value } = this.state

        this.props.channelDetailStatus({ id: record.id }).then(res => {
            this.props.channelDetailList({ ...value })
        })
    };

    onChange = (e) => {
        //{beginTime:e[0],endTime:e[1]}
        console.log('clean', e)

        const { value } = this.state
        value.begin = e[0]
        value.end = e[1]
        this.setState({ value })
        this.props.channelDetailList({ ...value })

    }

    render() {
        const { detailData } = this.props;
  
        // const pagination = detailData ? {
        //     current: detailData.current | 0,//当前页
        //     total: detailData.total | 0,//总条数
        //     pageSize: detailData.size | 0,//页大小
        //     onChange: this.query,
        //     showQuickJumper: true,//快速跳转
        //     showSizeChanger: false//大于50时显示pageSize切换器
        // } : {};
        return (
            <div>
                <ProTable
                columns={this.columns}
                search={false}
                request={(params, s, f) => {
                  
                    this.props.channelDetailList({id:this.props.row.id})
                }}
                pagination={{
                    pageSize: 50,
                }}
                dataSource={detailData}
              
                rowKey="createDate" 
                />

            </div>
        );
    }
}

const mapState = state => ({
    ...state.pay

});

const mapDispatch = dispatch => ({
    ...dispatch.pay
})

export default connect(mapState, mapDispatch)(DetailList);