import React, {Component} from "react";
import {connect} from 'react-redux';

import ProTable from '@ant-design/pro-table';
import Add from './component/Add'
import {Badge, Button, Modal,Popconfirm} from 'antd';

class Menus extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            type: '',
            row: {},
            parent: [],
            params: {}

        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
                hideInSearch: true,

                width: 80,

            },
            {
                title: '菜单名称',
                align: 'center',
                copyable: true,
                dataIndex: 'menuName',
                hideInSearch: false,
                width: 80,
                render: (_) => <a>{_}</a>,
            },
            {
                title: '菜单分类',
                align: 'center',
                copyable: true,
                ellipsis: true,
                hideInSearch: true,
                width: 80,
                render: (_, record) => (<div>
                    {record.pid === 0 ?
                        <div style={{color: 'blue'}}>{record.parentName}</div> : record.parentName}
                </div>)
            },
            {
                title: '路由路径',
                align: 'center',
                ellipsis: true,
                tip: 'url跳转路径,不可设置重复',
                dataIndex: 'menuUrl',
                hideInSearch: true,
                width: 80,
            },
            {
                title: '权限字符',
                align: 'center',

                ellipsis: true,
                tip: '菜单访问权限,非特殊情况不建议修改,不可设置重复',
                dataIndex: 'remarks',
                hideInSearch: true,
                width: 80,
            },
            {
                title: '状态',
                align: 'center',
                dataIndex: 'menuStatus',
                hideInSearch: true,
                width: 80,
                status: 2,
                render: (_, record) => {
                    return (
                        <Popconfirm
                            title="确定操作？"
                            onConfirm={() => this.status(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <a>
                                <Badge
                                    status={record.menuStatus == 1 ? 'success' : 'error'}
                                    text={record.menuStatus == 1 ? '正常' : '关闭'}/>
                            </a>
                        </Popconfirm>

                       );
                }
            },
            {
                title: '添加人',
                dataIndex: 'addBy',
                align: 'center',
                hideInSearch: true,
                width: 80,
            },
            {
                title: '添加时间',
                dataIndex: 'addTime',
                align: 'center',
                hideInSearch: true,
                width: 80,
            },
            {
                title: '操作',
                width: 80,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.upd(record)} style={{marginLeft: 2}}>编辑</a>
                            <Popconfirm
                                title="确定删除？"
                                onConfirm={() => this.del(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a  style={{marginLeft: 30, color: 'red'}}
                                >删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];


    }

    componentDidMount() {
        const {parent, list} = this.props
        parent();
        // list();

    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    };
    add = (row) => {
        this.setState({
            visible: true,
            type: '0',
            row: row
        })
    }
    upd = (record) => {
        this.setState({visible: true, row: record, type: '1'})
    }
    del = (record) => {
        this.props.del({id: record.id}).then(res => {
            this.props.list();

        })
    };
    status = (record) => {
        this.props.status({id: record.id,status:record.menuStatus === 1 ? 0 : 1}).then(res => {
            this.props.list();

        })
    };

    render() {
        const {row, type} = this.state;
        const {parentData, data} = this.props;
       

        return (
            <div><ProTable
                columns={this.columns}
                request={(params, s, f) => {
                    this.setState({params})
                    this.props.list(params)
                }}
                dataSource={data}
                columnsState={{
                    persistenceKey: 'pro-table-singe-demos',
                    persistenceType: 'localStorage',
                }}
                rowKey="id"
                search={false}

                pagination={{
                    pageSize: 20,
                }}
                revalidateOnFocus={true}
                dateFormatter="string"
                headerTitle="菜单列表"
                toolBarRender={() => [
                    <Button key="creat" type="primary" onClick={() => this.add()}>
                        添加
                    </Button>,

                ]}
            />
                <Modal destroyOnClose open={this.state.visible} title={type === '0' ? '新增' : '编辑'}
                       footer={null}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} pd={parentData} record={row} type={type}></Add>
                </Modal>
            </div>
        );

    }


}


const mapState = state => ({
    ...state.menus

});

const mapDispatch = dispatch => ({
    ...dispatch.menus
})

export default connect(mapState, mapDispatch)(Menus);