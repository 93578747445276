import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Badge, Modal } from 'antd';
import dayjs from 'dayjs';
class VipLogList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 20,
            current: 1,
            countData: {}
        }

        this.columns = [
            // {
            //     title: 'id',
            //     dataIndex: 'id',
            //     align: 'center',

            // },
            {
                title: '订单编号',
                dataIndex: 'tradeNo',
                align: 'center',


            },
            {
                title: '下单用户',
                dataIndex: 'mid',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {record.payStatus== 1&&record.firstPay==1 ? <div style={{ color: "blue" }}>{record.mid}</div> : 
                                <div>{record.mid}</div>}
                        </div>
                    );
                }



            },
          
            
            {
                title: '浏览器',
                dataIndex: 'bro',
                align: 'center',


            },
            {
                title: '支付域名',
                dataIndex: 'payDomain',
                align: 'center',


            },
            
            {
                title: '购买类型',
                dataIndex: 'type',
                align: 'center',

                render: (_, record) => {
                    return (
                        <div>
                            {_ === 1 ? <div style={{ color: "blue" }}>vip</div> : _ === 2 ?
                                <div>金币</div> : ''}
                        </div>
                    );
                }

            },
            {
                title: '描述',
                align: 'center',
                dataIndex: 'name',


            },
            {
                title: '金额',
                align: 'center',
                dataIndex: 'price',

                render: (_, record) => {
                    return (
                        <div style={{ color: "red" }}>
                            {_}
                        </div>
                    );
                }

            },
            {
                title: '渠道',
                align: 'center',
                dataIndex: 'channe',

                render: (_, record) => (
                    <span>
                        {record.channe ? _ : '-'}
                    </span>

                )

            },

            {
                title: '支付方式',
                align: 'center',
                dataIndex: 'remarks',

                render: (_, record) => (
                    <a>
                        {record.mchName}
                    </a>

                )

            },

            {
                title: '收款账户',
                align: 'center',
                dataIndex: 'mchCode',

                render: (_, record) => (
                    <a>
                        {_ + '(' + record.payMch + ')'}
                    </a>

                )
            },
            {
                title: '支付商订单号',
                align: 'center',
                dataIndex: 'transactionId',


            },
            {
                title: '支付IP',
                dataIndex: 'payIp',
                align: 'center',


            },


            {
                title: '创建时间',
                dataIndex: 'addTime',
                align: 'center',
            },
            
            {
                title: '支付时间',
                dataIndex: 'payTime',
                align: 'center',
            },
            {
                title: '支付状态',
                align: 'center',
                fixed: 'right',
                dataIndex: 'payStatus',

                render: (_, record) => (
                    <div>
                        <Badge
                            status={_ === 1 ? 'success' : _ === 0 ? 'default' : _ === -1 ? 'error' : 'error'}
                            text={_ === 1 ? <span style={{color:'blue'}}>成功</span> : _ === 0 ? '未支付' : _ === -1 ? <a onClick={() => this.catError(record)} style={{color:'red'}}>失败</a> : '未知'} />

                    </div>

                )

            },


        ];
    }

    componentDidMount() {
        this.init();
    }

    catError = (row) => {
        Modal.error({
            title: row.tradeNo,
            content: row.err,
        });
    }

    init = () => {
        this.props.vipLogList({ page: this.state.pageNum, size: this.state.pageSize })
        this.setState({ countData: this.props.vipLogsData.custom })
    }

    query = (current, pageSize) => {
        this.setState({ current })
        let newValues = this.props.newValues;
        newValues.page = current
        newValues.size = this.state.pageSize
        this.props.vipLogList(newValues)
    }


    render() {
        const { vipLogsData, userLoading } = this.props;
        const {countData} = this.state
        const pagination = {
            current: vipLogsData.current,//当前页
            total: vipLogsData.total,//总条数
            pageSize: vipLogsData.size,//页大小
            onChange: this.query,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div>

                    <div>
                        <span style={{margin:16,fontSize:18}}>订单总量:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.totalOrder : 0}</span>
                        <span style={{margin:16,fontSize:18}}>拉单人数:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.orderUserCount : 0}</span>
                        <span style={{margin:16,fontSize:18}}>成功订单:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.successOrder : 0}</span>
                        <span style={{margin:16,fontSize:18}}>订单总额:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.totalAmount : 0}</span>
                        <span style={{margin:16,fontSize:18}}>成功金额:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.amount : 0}</span>
                        <span style={{margin:16,fontSize:18}}>汇后:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.realPrice : 0}</span>
                        <span style={{margin:16,fontSize:18}}>首充:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.firstAmount : 0}</span>
                        <span style={{margin:16,fontSize:18}}>复充:{this.props.vipLogsData.custom?this.props.vipLogsData.custom.amount-this.props.vipLogsData.custom.firstAmount : 0}</span>
                    </div>



                </div>
                <Table columns={this.columns} loading={userLoading} dataSource={vipLogsData.records} scroll={{ x: 'max-content' }}
                    rowKey={record => record.id} pagination={pagination} />

            </div>

        );
    }
}


const mapState = state => ({
    ...state.vip
});

const mapDispatch = dispatch => ({
    ...dispatch.vip

})

export default connect(mapState, mapDispatch)(VipLogList);