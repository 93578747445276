import React, {Component} from "react";
import {connect} from 'react-redux';
import { VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { DragSortTable } from '@ant-design/pro-components';
import {Badge, Popconfirm, Image} from 'antd';

class MhInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            title:''

        };
        this.columns = [
            {
                title: '顺序',
                dataIndex: 'num',
                key: 'num',
                align: 'left',
                width:100,
                hideInSearch: true,
            },
            {
                title: '章节信息',
                align: 'left',
                dataIndex: 'title',
                key: 'title',
                width:150,
                hideInSearch: true,
                className: 'drag-visible',
                render: (_) => _,
                
            },
   
            {
                title: '内容',
                dataIndex: 'imgList',
                align: 'center',
                width:150,
                render: (_, record) => _? <div >
                    <Image.PreviewGroup items={_} >
                                <Image
                                width={80}
                                height={50}
                                src={_[0]}
                                />
                       </Image.PreviewGroup>
                    </div> : null
            },

            {
                title: '类型',
                dataIndex: 'feesType',
                align: 'center',
                width:100,
                hideInSearch: true,
                render: (_, record) => (
                    <a>
                         <Popconfirm
                                title="变更状态"
                                onConfirm={() => this.zjFeesType(record)}
                                okText="确定"
                                cancelText="取消"
                            >
                        <Badge
                            status={_=== 1 ? 'success':_=== 0? 'default':_=== 2? 'processing': 'error'}
                            text={_=== 1 ? 'vip':_=== 0? '免费':_=== 2? '金币': '暂无设置'}/>
                             </Popconfirm>
                    </a>

                 )

            },



        ];


    }
    componentDidMount() {
      
      //  this.setState({data:this.props.zjs})
      const {zjData,row} = this.props
     
      this.setState({data:zjData,title:row.title|'章节目录'})
      
     }
 


    zjFeesType = (record) => {
        const {row} = this.props
        this.props.zjFeesType({id: record.id, type: record.feesType === 0 ? row.feesType : 0})
        .then(res => {
           if(res.code===0)
            this.props.cancleZjModal()

        })
    };

     handleDragSort = (f,d,data) => {
    
       this.setState({data})
       this.props.sort(data)
      };
      

    render() {
        const { data} = this.state; 
       
        const {row} = this.props
        const customRender = (rowData, idx) => (
            <>
             <span style={{ cursor: 'grab', color: 'red',alignSelf:'flex-end'}} >
             &nbsp;&nbsp;{idx + 1} &nbsp; <Badge  status={ 'processing'} text={rowData.name} />&nbsp;&nbsp;
             <VerticalAlignMiddleOutlined style={{ cursor: 'grab', color: 'red' }}  />&nbsp;&nbsp;
           
             </span>
            </>
          );
        return (
            <>
       <DragSortTable
        headerTitle={row.title}
        columns={this.columns}
        rowKey="id"
        pagination={false}
        dataSource={data}
        search={false}
        dragSortKey="title"
        onDragSortEnd={this.handleDragSort}
        dragSortHandlerRender={customRender}
      />

            </>
        );

    }


}


const mapState = state => ({
    ...state.circleComment

});

const mapDispatch = dispatch => ({
    ...dispatch.circleComment
})

export default connect(mapState, mapDispatch)(MhInfo);