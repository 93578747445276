import React, { Component } from 'react'
import PromoteList from './PromoteList'
import style from './Promote.module.scss'
import moment from 'moment';
import { connect } from 'react-redux';
import url from '../../../utils/url';
// import moment from 'moment';
import { removeEmptyField } from '../../compoent/emptyFild';
import {
    Form,
    Button,
    DatePicker,
}
    from 'antd';
import download from "../../../services/download";

class Promote extends Component {
    state = {
        data: [],
        newValues: {}
    }
    onFinish = (values) => {
        //日期
        const date = values.beginTime
        values.beginTime = date ? moment(date[0]).format('YYYY-MM-DD') : ''
        values.endTime = date ? moment(date[1]).format('YYYY-MM-DD') : ''

        console.log('Success:', values);
        if (values) {
            values.page = 1
            values.size = 10
            const newValues = removeEmptyField(values)
            this.setState({ newValues: newValues })
            this.props.spread(newValues)
            //存values到本地
            this.setState({
                data: values
            })
        } else {
            this.props.spread()
        }
    };
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    //导出excel表格
    exportPromote = () => {
        const { data } = this.state;
        download(url.exportSpread,'GET',{beginTime: data.beginTime,endTime:data.endTime});
        /*  const { data } = this.state
          console.log(data);
          window.location.href = url.exportSpread + '?beginTime=' + data.beginTime +
              '&endTime=' + data.endTime + '';*/

    };
    render() {
        const { RangePicker } = DatePicker;
        const { newValues } = this.state
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{ marginLeft: 860, width: 450 }}
                            label="发起时间"
                            name="beginTime"
                            // rules={[{ message: 'Please input your username!' }]}
                        >
                            <RangePicker
                                // showTime
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }}></RangePicker>
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType="reset" style={{ marginLeft: 50 }}>
                                重置
                            </Button>
                            <Button type="primary" onClick={this.exportPromote} style={{ marginLeft: 50 }} >
                                导出
                            </Button>
                        </Form.Item>

                    </Form>
                </div>
                <div className={style.middle}>
                    <PromoteList newValues={newValues} />
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.spread
})
const mapDispatch = dispatch => ({
    ...dispatch.spread

})

export default connect(mapState, mapDispatch)(Promote);
