import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal, Popconfirm, Image, message } from 'antd';
import AddLabel from './component/AddLabel'
class LabelList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 20,
            visible: false,
            record: {},
            type: '',
            img: '',

        }

        this.columns = [

            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',

            },
            {
                title: '板块',
                dataIndex: 'parentName',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div style={{color:record.owner==='0'?'red':''}}>
                            {record.owner === '0'? '一级分类' : _}
                        </div>
                    )
                }
            },
            {
                title: '标签名称',
                dataIndex: 'name',
                align: 'center',
                render: (_, record) => record.count>0?<div>{record.name}<a>({record.count})</a></div>:<div>{_}</div>
             
            },

            {
                title: '推荐显示',
                dataIndex: 'showIndex',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div style={{color:record.showIndex===1&&record.owner !== '0'?'red':''}}>
                            {record.showIndex === 1&&record.owner !== '0'? '是' : '否'}
                        </div>
                    )
                }
            },
            {
                title: '显示位置',
                dataIndex: 'cateType',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div style={{color:_ === 'nav'?'red':_ === 'cate'?'blue':_==='recommend'?'green':_==='yy'?'pink':_==='cj'?'purple':''}}>
                            {_==='nav'?'导航标签':_==='cate'?'栏目标签':_==='recommend'?'推荐标题':_==='yy'?'演员':_==='cj'?'厂家':'普通标签'}
                        </div>
                    )
                }

            },

            {
                title: '图标',
                dataIndex: 'icon',
                align: 'center',
                render: (_, record) => record.icon ? <div ><Image style={{ width: 50, height: 50 }} src={record.icon} /> </div> : null

            },

            {
                title: '排序',
                dataIndex: 'orderNum',
            },
            {
                title: 'showType',
                dataIndex: 'showType',
                align: 'center',
            },
            {
                title: '扩展参数',
                dataIndex: 'params',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {_==="undefined" ? '' : _}
                        </div>
                    )
                }
            },

            {
                title: '备注',
                dataIndex: 'remarks',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {record.remarks === "undefined" ? '' : record.remarks}
                        </div>
                    )
                }
            },
            {
                title: '操作',
                // dataIndex: 'addTime',
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.updateLabel(record)}>编辑</a>
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.delCateTag(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a style={{ marginLeft: 30 }} >删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },


        ];
    }
    componentDidMount() {
        const { list } = this.props
        list({ page: 1, size: 20 })

    }
    handleCancel = () => {
        this.props.clear()
        this.setState({
            visible: false
        })
    }

    handleChangeLabelList = (current, pageSize) => {
        const {newValues} =  this.props;
        newValues.page=current;
        newValues.size=pageSize
        this.props.list(newValues)
    }
    updateLabel = (record) => {

        this.setState({ visible: true, record, type: '1' })
    }
    delCateTag = (record) => {
        this.props.delCateTag({ id: record.id }).then(res => {
            this.props.list({ page: 1, size: 20 })
        })
        message.success('删除成功')
    }
    render() {
        const { updateListData,  userLoading,parent,newValues } = this.props;
        const {  visible, record, type } = this.state;

        const pagination = {
            current: updateListData.current,//当前页
            total: updateListData.total,//总条数
            pageSize: updateListData.size,//页大小
            onChange: this.handleChangeLabelList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: true//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} 
                    loading={userLoading} dataSource={updateListData.records} rowKey={record => record.id} pagination={pagination}
                />
                <div>
                    <Modal destroyOnClose open={visible} title={type === '0' ? "新增" : '编辑'} footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <AddLabel handleCancel={this.handleCancel} record={record} type={type} newValues={newValues} parent={parent}></AddLabel>
                    </Modal>
                </div>
            </div>

        );
    }
}


const mapState = state => ({
    ...state.label
});

const mapDispatch = dispatch => ({
    ...dispatch.label

})

export default connect(mapState, mapDispatch)(LabelList);