import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Spin} from "antd";
import "./login.less";

const Login = props => {
    const {login, getAdminMenuList, getAdminMenuListData} = props;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [rememberMe, setRememberMe] = useState(true);
    // const [token, setToken] = useState()

    useEffect(() => {
        loadAccountInfo();
    }, []);

    const onFinish = value => {
        //setLoading(true);
        let user = {
            username: value.username,
            password: value.password
        }

        login(user).then(res => {
            if (res.code === 0) {
                localStorage.setItem("isLogin", "1");
                localStorage.setItem("userInfo", JSON.stringify(res.data));
                getAdminMenuList({
                    Authorization: "Bearer " + res.data.token,
                    id: res.data.userId
                }).then(res => {
                    if (res.code === 0) {
                        let permissions = [];
                        let permit = [];
                        res.data.map(item => {
                            permit.push(item)
                            permissions.push(item.menuUrl)
                            if (item.childMenu.length > 0) {
                                item.childMenu.map(ite => {
                                    permit.push(ite)
                                    permissions.push(ite.menuUrl)
                                })
                            }
                        })
                        localStorage.setItem(
                            "permit",
                            JSON.stringify(permit)
                        );
                        localStorage.setItem(
                            "permissions",
                            JSON.stringify(permissions)
                        );
                        setTimeout(() => {
                            if (permissions.length > 0)
                                props.history.push('/home');
                        }, 500);
                    }
                })




            }else{
                localStorage.clear()
                window.location.href = "/#/login"
            }
        });
    };

    const loadAccountInfo = () => {
        const data = localStorage.getItem("rememberData")
            ? JSON.parse(localStorage.getItem("rememberData"))
            : "";
        let username = data ? data.loginName : "";
        let password = data ? data.password : "";
        form.setFieldsValue({
            username,
            password
        });
    };
    //是否记住记住
    const handleCheckBox = e => {
        setRememberMe(e.target.value);
    };

    return (
        <Spin spinning={loading}>
            <div className="container">
                <div className="box">
                    <div className="login-box">
                        <div className="login-right">
                            <div className="login-right-inner">
                                <div className="login-title">SmallYBook</div>
                               
                                <Form
                                    name="basic"
                                    form={form}
                                    onFinish={onFinish}
                                    className="form-box"
                                    className="login-box-form"
                                    initialValues={{
                                        remember: true
                                    }}
                                >
                                    <Form.Item
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入账号!"
                                            }
                                        ]}
                                        className="form-item"
                                    >
                                        <Input
                                            prefix={<span style={{width: 200}}>账号</span>}
                                            className="input-box"
                                            placeholder="请输入账号"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入密码!"
                                            }
                                        ]}
                                        className="form-item form-item-password"
                                    >
                                        <Input
                                            prefix={<span style={{width: 200}}>密码</span>}
                                            type="password"
                                            className="input-box"
                                            placeholder="请输入密码"
                                        />
                                    </Form.Item>
                                    {/* <Form.Item name="remember" valuePropName="checked">
                    <Checkbox
                      onClick={handleCheckBox}
                      style={{ color: "#CBCED0" }}
                    >
                      记住密码
                    </Checkbox>
                  </Form.Item> */}
                                    <Form.Item>
                                        <Button
                                            className="btn-box"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            登录

                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

const mapState = state => ({
    ...state.login
});
const mapDispatch = dispatch => ({
    ...dispatch.login

});

export default connect(mapState, mapDispatch)(Login);
