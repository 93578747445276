/* eslint-disable import/no-anonymous-default-export */
import { preserve} from '../services/retainedApi'

export default {
    state: {
        data: [],
       
    },
    effects: {
        async list(params) {
            return  await preserve(params)

           
        },
      
    },
    reducers: {
        savePreserve(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        // saveRetainedSpread(state, paylode) {
        //     return {
        //         ...state,
        //         ...paylode
        //     }
        // },
    }
}