import React, {PureComponent} from "react";
import Header from "./header";
import Content from "./content";
import SideMenu from "./sideMenu";
import "./header.css";
import ProLayout from '@ant-design/pro-layout';

export default class LayoutIndex extends PureComponent {
    render() {
        return (
                
                <ProLayout className=""
                    headerRender={false}
                    menuHeaderRender={false}
                    siderWidth={185}
                    collapsed={false}
                    fixSiderbar={false}
                    fixedHeader={false}
                    menu={false}
                    title={false}
                    logo={false}
                    defaultCollapsed={false}
                    headerTitleRender={false}
                    headerContentRender={false}
                    menuItemRender={false}
                    subMenuItemRender={false}
                    menuDataRender={false}
                    onCollapse={()=>{}}
                   
                    actionRef={true}
                   
                >
                    
                   
                    <Header/>
                    <SideMenu/>
                    <Content/>
                    
            </ProLayout>
               
              
           
        );
    }

   /* render() {
        return (
            <div style={{height: "100vh"}}>
                <Layout style={{height: "100vh", width: "100%"}}>
                    <div style={{width: "100%", display: "flex", overflow: "hidden"}}>
                        <div style={{flex: 2}}>
                            <Header/>
                            <Layout className="site-layout" style={{marginLeft: 200}}>
                                <Content style={{ padding: 24, textAlign: 'center' }} />
                            </Layout>
                            <SideMenu/>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }*/
}
