import request from '../utils/request';
import url from '../utils/url';

//添加管理员
export function addAdmin(params) {
    return request(url.addAdmin, {
        method: 'POST',
        params
    })

}
//管理员列表
// export function adminList(params) {
//     return request(url.adminList, {
//         method: 'POST',
//         params
//     })

// }
//管理员列表分页
export function adminListPage(params) {
    return request(url.adminListPage, {
        method: 'POST',
        params
    })

}
//删除管理员
export function delAdmin(params) {
    return request(url.delAdmin, {
        method: 'POST',
        params
    })

}
//管理员详情
export function detailAdmin(params) {
    return request(url.detailAdmin, {
        method: 'POST',
        params
    })

}
//获取管理员菜单列表（登录成功
export function getAdminMenuList(params) {
    return request(url.getAdminMenuList, {
        method: 'POST',
        params
    })

}
//管理员修改
export function updateAdmin(params) {
    return request(url.updateAdmin, {
        method: 'POST',
        params
    })

}
export function roleListPage(params) {
    return request(url.roleListPage, {
        method: 'POST',
        params
    })

}
export function updateStatus(params) {
    return request(url.updateStatus, {
        method: 'POST',
        params
    })

}
