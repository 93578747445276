import React, { Component } from 'react'

import { connect } from "react-redux";
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormSelect } from '@ant-design/pro-components';
import AddInfo from './component/AddInfo'
import AddIp from './component/AddIp'
import Add from './component/Add'
import Parse from './component/Parse'
import { Button, Modal, Popconfirm, message } from 'antd';
import { ProFormText } from '@ant-design/pro-form';

class OfflineMsg extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            type: '',
            row: {},
            parent: [],
            params: {},
            showService: false,
            showServiceOpt: false,
            showIpOpt: false,
            serviceRow: null,
            ipRow: null,
            rowType: null,
            parseShow: false,
            parseRow: null,
            parseType: null,
            showCut: false

        };
        this.columns = [

            {
                title: '域名',
                align: 'center',
                copyable: true,
                dataIndex: 'doMain',
                hideInSearch: false


            },

            {
                title: '描述',
                dataIndex: 'tips',
                align: 'center',
                hideInSearch: true,

            },
            {
                title: '备注',
                dataIndex: 'remark',
                align: 'center',
                hideInSearch: true,

            },
            {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                hideInSearch: false,
                valueEnum: {
                    1: { text: '正常', status: "Success" },
                    0: { text: '失效', status: 'Error' }


                },

            },



            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a style={{ color: "blue", marginRight: 10 }} onClick={() => this.showParse(record, 0)}>解析</a>
                            <Popconfirm

                                onConfirm={() => this.upd(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a>编辑</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];

        this.serviceColumn = [

            {
                title: '服务器名称',
                align: 'center',
                copyable: true,
                dataIndex: 'name',
                hideInSearch: true


            },

            {
                title: '备注',
                dataIndex: 'remark',
                align: 'center',
                hideInSearch: true,

            },
            {
                title: '状态',
                dataIndex: 'status',
                align: 'center',
                hideInSearch: true,
                valueEnum: {
                    1: { text: '正常', status: "Success" },
                    0: { text: '失效', status: 'Error' }


                },

            },

            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.ipOpt(record, 1)} style={{ marginRight: 10, color: 'red' }}>添加ip</a>
                            <Popconfirm
                                title="！"
                                onConfirm={() => this.serviceOpt(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a>编辑</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];


    }


    handleCancel = () => {

        this.setState({
            visible: false,
            parseShow: false
        })
    };
    add = (row) => {
        this.setState({
            visible: true,
            type: '0',
            row: null
        })
    }
    showParse = (row, type) => {
        this.setState({ parseRow: row, parseShow: true, parseType: type })
    }
    showService = () => {
        this.setState({ showService: true })
    }
    serviceOpt = (row) => {
        this.setState({ showServiceOpt: true, serviceRow: row ? row : null })
    }
    ipOpt = (row, ipType) => {
        this.setState({ showIpOpt: true, ipRow: row, rowType: ipType })
    }
    handleOptCancel = () => {

        this.setState({
            showServiceOpt: false,
            showIpOpt: false,

        })
    };
    handleServiceCancel = () => {

        this.setState({
            showService: false,


        })
    };

    sync = () => {
        this.props.syncDomain()
            .then(res => {
                if (res.code == 0)
                    this.props.domainList(this.state.params)
                else
                    message.error(res.message)
            }

            )
    }
    upd = (record) => {

        this.setState({ visible: true, row: record, type: '1' })
    }
    openModalChange = (r,s) => {
        
        this.setState({ showCut: r })
        
    }

    del = (record) => {
        console.log('ii,', record.id)

        this.props.dnsDel({ id: record.id })
            .then(res => {
                if (res.code == 0)
                    this.props.domainList(this.state.params)
                else
                    message.error(res.message)
            })

    }


    render() {
        const { row, type, showService, ipRow, serviceRow, rowType, parseRow, parseType, showCut } = this.state;
        const { domain, serviceLists } = this.props;
        const pagination = {
            current: domain.current,//当前页
            total: domain.total,//总条数
            pageSize: 20,//页大小
            // onChange: this.handleChangeOrderList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        const expandedRowRender = (row) => {
            console.log('row', row)
            if (row && row.doMain)

                return (
                    <ProTable
                        columns={[
                            { title: '子域名', dataIndex: 'name', align: 'center', key: 'name', copyable: true, },
                            { title: '解析类型', dataIndex: 'ctype', align: 'center', key: 'ctype' },
                            { title: 'IP', dataIndex: 'ip', align: 'center', key: 'ip', copyable: true, },
                            { title: '代理', dataIndex: 'proxied', key: 'proxied' },
                            { title: '描述', dataIndex: 'tips', align: 'center', key: 'tips' },
                            { title: '备注', dataIndex: 'remark', align: 'center', key: 'remark' },
                            {
                                title: '操作',
                                dataIndex: 'operation',
                                key: 'operation',

                                valueType: 'option',
                                render: (_, record) => [<a onClick={() => this.showParse(record, 1)} key="Pause">编辑</a>,
                                <a key="Stop" onClick={() => this.del(record)}>删除</a>],
                            },
                        ]}
                        rowKey="id"
                        headerTitle={false}
                        search={false}
                        options={false}
                        dataSource={row.childs}
                        pagination={false}
                    />
                );
            else
                return (
                    <ProTable
                        columns={[
                            { title: 'IP', dataIndex: 'ip', align: 'center', key: 'ip', copyable: true, },
                            {
                                title: '状态', dataIndex: 'status', align: 'center', key: 'status', valueEnum: {
                                    1: { text: '正常', status: "Success" },
                                    0: { text: '失效', status: 'Error' }


                                },
                            },
                            { title: '描述', dataIndex: 'remark', align: 'center', key: 'remark' },

                            {
                                title: '操作',
                                dataIndex: 'operation',
                                key: 'operation',
                                align: 'center',
                                valueType: 'option',
                                render: (_, record) => { return <a key="Pause" onClick={() => this.ipOpt(record, 0)}>编辑</a> },
                            },
                        ]}
                        rowKey="ip"
                        headerTitle={false}
                        search={false}
                        options={false}
                        dataSource={row.childs}
                        pagination={false}
                    />
                )
        }


        return (
            <div>
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                        this.setState({ params })
                        this.props.domainList(params)
                    }}
                    dataSource={domain.records}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"

                    expandable={{ expandedRowRender }}
                    pagination={pagination}
                    revalidateOnFocus={true}
                    dateFormatter="string"

                    toolBarRender={() => [

                        <Button key="create" type="primary" onClick={() => this.sync()}>
                            同步域名
                        </Button>,
                        <Button key="service" type="primary" onClick={() => this.showService()}>
                            服务器列表
                        </Button>,
                        <Button key="service" danger onClick={() => this.openModalChange(true)}>
                            切换服务器
                        </Button>,

                    ]}
                />

                <Modal destroyOnClose open={this.state.visible} title={type === '0' ? '新增' : '编辑'}
                    footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <AddInfo handleCancel={this.handleCancel} row={row} params={this.state.params} />
                </Modal>

                <Modal destroyOnClose open={this.state.parseShow}
                    footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Parse handleCancel={this.handleCancel} row={parseRow} parseType={parseType} />
                </Modal>

                <div>
                    <Modal destroyOnClose open={showService} title='服务器信息' width={1000} footer={null}
                        onCancel={this.handleServiceCancel} handleCancel={this.handleServiceCancel}>
                        <ProTable
                            columns={this.serviceColumn}
                            request={(params, s, f) => {

                                this.props.serviceList(params)
                            }}
                            dataSource={serviceLists}
                            columnsState={{
                                persistenceKey: 'pro-table-singe-demos',
                                persistenceType: 'localStorage',
                            }}
                            rowKey="id"

                            expandable={{ expandedRowRender }}
                            pagination={{ pageSize: 50 }}

                            dateFormatter="string"
                            search={false}
                            toolBarRender={() => [
                                <Button key="create" type="primary" onClick={() => this.serviceOpt()}>
                                    新增
                                </Button>,

                            ]}
                        />
                        <Modal destroyOnClose open={this.state.showServiceOpt}
                            footer={null}
                            onCancel={this.handleOptCancel} handleCancel={this.handleOptCancel}>
                            <Add handleCancel={this.handleOptCancel} row={serviceRow} />
                        </Modal>

                        <Modal destroyOnClose open={this.state.showIpOpt}
                            footer={null}
                            onCancel={this.handleOptCancel} handleCancel={this.handleOptCancel}>
                            <AddIp handleCancel={this.handleOptCancel} row={ipRow} rowType={rowType} />
                        </Modal>


                    </Modal>
                </div>

                <ModalForm

                    title="切换服务器IP前 建议先同步域名 请确保目标IP可用性,切换成功后请重新同步域名信息获取域名变更信息。"
                    open={showCut}
                    onFinish={async (e) => {

                        message.success('提交成功..请等待..');
                        return this.props.serviceImport(e)
                            .then(res => {
                                
                                if (res.code == 0) {
                                    message.success(res.message);
                                    this.sync()
                                    return true;
                                } else {
                                    message.error(res.message);
                                }

                            })



                    }}
                    onOpenChange={this.openModalChange}

                >

                    <ProFormText

                        name="old"
                        width="md"
                        rules={[{ required: true, message: '请输入源IP' }]}
                        label="源IP"
                    />
                    <ProFormText
                        width="md"
                        name="news"
                        rules={[{ required: true, message: '请输入目标Ip' }]}
                        label="目标IP"
                    />

                </ModalForm>

            </div>
        );

    }
}
const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(OfflineMsg);