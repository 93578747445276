import React, { Component } from 'react'

import { connect } from "react-redux";
import { ProFormText, ProForm,  } from '@ant-design/pro-form';
import { Col, Space, Row } from 'antd';

class ShowUserInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mId: '',
            data: {},
        }
    }


    componentDidMount() {
        // this.setState({ data: this.props.data })
        //
    }


    onFinish = (e) => {
        
       
        const {  row } = this.props
       
        if (!e||!row) return
        e.id=row.id
        this.props.updVip(e)
        .then(res=>{
            if(res.code==0){
                this.props.handleCancelVip(true)
            }
        })

    

    };

    render() {
        const { row } = this.props
        return (
            <ProForm layout='inline' grid={true} rowProps={{ gutter: [12, 16] }} preserve={false}
                initialValues={{
                    vip: 0,
                    vb: row.vb | 0


                }}
                submitter={{
                    render: (props, doms) => {
                        return (

                            <Row style={{ paddingTop: 10 }}>
                                <Col span={12} offset={24}>
                                    <Space>{doms}</Space>
                                </Col>
                            </Row>
                        );
                    },



                }
                }
                onFinish={(v) => this.onFinish(v)}

            >
                <ProFormText type='number' colProps={{ span: 12 }} name="vip" label="vip" placeholder="单位天数  0关闭vip" />
                <ProFormText type='number' colProps={{ span: 12 }} name="vb" label="金币" placeholder="单位pc" />

            </ProForm>
        )
    }
}

const mapState = state => ({
    ...state.users

});

const mapDispatch = dispatch => ({
    ...dispatch.users
})

export default connect(mapState, mapDispatch)(ShowUserInfo);