/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form,Input} from 'antd';


class ChangeOrderNum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
        };
    }

    onFinish = (values) => {
        values.orderId = this.props.record.orderId
        this.props.orderPublishOrderNum(values).then(res => {
            this.props.sendOrder({...this.props.newValues})
        })
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };

    render() {
        const {record} = this.props;
        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      initialValues={{remember: true}}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      autoComplete="off" initialValues={{
                    orderNum: record.orderNum
                }}>
                    <Form.Item
                        label="排序："
                        name="orderNum"
                        rules={[{ required: true, message: '请输入参数!' }]}
                    >
                        <Input  type='number' placeholder="数值越大排序越高 " />
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )


    }

    a
}

const mapStateToProps = (state) => ({
    ...state.order

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.order

})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOrderNum);