/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal, Popconfirm, message } from 'antd';
import RoleAuth from './component/RoleAuth';
class RoleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
            authTree: false,
            record: {}
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',

            },
            {
                title: '角色名',
                dataIndex: 'roleName',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
            }, {
                title: '时间',
                dataIndex: 'addTime',
            },
            {
                title: '操作',
                // dataIndex: 'companyCommission',
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.roleAuthTree(record)}>分配权限</a>
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.delRole(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a style={{ marginLeft: 30 }}>删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },


        ];
    }
    componentDidMount() {
        this.init();
    }

    init = () => {
        const { roleListPage } = this.props
        roleListPage({ page: 1, size: 10 })
    }

    handleChangeOrderList = (current, pageSize) => {
        this.props.roleListPage({ page: current, size: 10 })
    }
    delRole = (record) => {
        const { delRole } = this.props
        delRole({ id: record.id }).then(res => {
            message.success('删除成功')
            this.props.roleListPage({ page: 1, size: 10 })
        }
        )
    }
    roleAuthTree = (record) => {
        this.setState({
            authTree: true,
            record
        })
    }
    handleCancel = () => {
        this.setState({
            authTree: false,
        })
    }
    render() {
        const { data, userTotal, userLoading } = this.props;
        const { pageNum, pageSize, authTree, record } = this.state;


        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeOrderList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns}
                    loading={userLoading} dataSource={data.records} rowKey={record => record.id} pagination={pagination}
                />
                <div>
                    <Modal destroyOnClose open={authTree} title='编辑' footer={null} onCancel={this.handleCancel}>
                        <RoleAuth handleCancel={this.handleCancel} record={record}></RoleAuth>
                    </Modal>
                </div>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.role
});

const mapDispatch = dispatch => ({
    ...dispatch.role

})

export default connect(mapState, mapDispatch)(RoleList);