import request from '../utils/request';
import url from '../utils/url';

//列表数据
export function list(params) {
    return request(url.systemParams, {
        method: 'POST',
        params
    });
}

export function edit(params) {
    return request(url.systemParamsEdit, {
        method: 'POST',
        params
    });
}
export function del(params) {
    return request(url.systemParamsDel, {
        method: 'POST',
        params
    });
}

//图片上传
export function img(params) {
    return request(url.img, {
        method: 'POST',
        params
    })

}

export function fileDel(params) {
    return request(url.systemFileDel, {
        method: 'POST',
        params
    })

}
export function sourceDel(params) {
    return request(url.systemSourceDel, {
        method: 'POST',
        params
    })

}

export function sourceClean(params) {
    return request(url.systemSourceClean, {
        method: 'POST',
        params
    })

}
