import request from '../utils/request';
import url from '../utils/url';


//推广数据列表
export function spread(params) {
    return request(url.spread, {
        method: 'POST',
        params,
    })

}

//推广配置列表
export function settingList(params) {
    return request(url.spreadSettingList, {
        method: 'GET',
        params,
    })

}
//添加推广配置
export function addSetting(params) {
    return request(url.addSpreadSetting, {
        method: 'POST',
        params,
    })

}

//删除推广配置
export function delSetting(params) {
    return request(url.delSpreadSetting, {
        method: 'POST',
        params,
    })

}

//盈利榜单自定义数据列表
export function profitList(params) {
    return request(url.profitList, {
        method: 'GET',
        params,
    })

}
//删除盈利榜单自定义数据
export function delProfitList(params) {
    return request(url.delProfitList, {
        method: 'POST',
        params,
    })

}

//添加盈利榜单自定义数据
export function addProfitList(params) {
    return request(url.addProfitList, {
        method: 'POST',
        params,
    })

}
//ios推广配置关闭
export function iosSwitch(params) {
    return request(url.iosSwitch, {
        method: 'GET',
        params,
    })

}


