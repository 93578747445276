import React, { useState } from 'react'

import { connect } from 'react-redux'
import {
    Form,
    Input,
    Button,
    Modal,

} from 'antd';
import AddSign from './component/AddSign';
import { removeEmptyField } from '../../compoent/emptyFild'
import style from './SignIn.module.scss'
import SingInList from './SignInList'


export const Activities = (props) => {
    const [newValues, setNewValues] = useState()
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState('')

    const onFinish = (values) => {

        if (values) {
            //
            values.page = 1
            values.size = 10
            let newValues = removeEmptyField(values)
            setNewValues(newValues)
            props.activityListPage(newValues)
        } else {
            props.activityListPage({ page: 1, size: 10 })
        }

    };
    const handleCancel = () => {
        setVisible(false)

    }
    const addAvtivity = () => {
        setVisible(true)
        setType('0')
    }
    return (
        <div>
            <div className={style.top}>
                <Form
                    style={{ marginTop: 20, marginLeft: 20 }}
                    name="basic"
                    layout='inline'
                    // wrapperCol={{ span: 6 }}
                    onFinish={onFinish}
                    autoComplete="off"

                >
                    <Form.Item
                        style={{ width: 320 }}
                        label="id"
                        name="id"
                    >
                        <Input placeholder='请输入发单人查询' />
                    </Form.Item>
                    <Form.Item
                        style={{ width: 320 }}
                        label="标题"
                        name="title"
                    >
                        <Input placeholder='请输入发单人查询' />
                    </Form.Item>



                    <Form.Item style={{ marginLeft: 600, marginTop: 0 }}>
                        <Button type="primary" htmlType="submit">
                            查询
                        </Button>
                        <Button type="primary" htmlType='reset' style={{ marginLeft: 42 }}>
                            重置
                        </Button>
                        <Button type="primary" onClick={addAvtivity} htmlType="submit" style={{ marginLeft: 42 }}>
                            新增
                        </Button>
                    </Form.Item>
                </Form>
            </div>

            <div className={style.middle}>
                <SingInList newValues={newValues}></SingInList>
            </div>
            <Modal destroyOnClose visible={visible} title='新增' footer={null} onCancel={handleCancel} handleCancel={handleCancel}>
                <AddSign handleCancel={handleCancel} type={type} ></AddSign>
            </Modal>
        </div>
    )
}
const mapStateToProps = (state) => ({
    ...state.activity

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.activity

})

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
