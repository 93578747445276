import React, {Component} from "react";
import {connect} from 'react-redux';
import ProTable from '@ant-design/pro-table';
import Add from './Add'
import RightsList from './RightsList'
import {Badge, Button, Modal, Popconfirm} from 'antd';

class VipSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            showList:false,
            row:null

        };
        this.columns = [
            {
                title: '类型',
                align: 'center',
                dataIndex: 'type',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                       
                           <a> <Badge
                                status={_ === 2 ? 'success' : 'error'}
                                text={_ === 2 ? '金币' : 'vip'}/></a>
                       

                      );
                }
               
            },

            {
                title: '销售名称',
                align: 'center',
                dataIndex: 'name',
               
                hideInSearch: true,
               
            },

            {
                title: '时长/数量',
                align: 'center',
                dataIndex: 'unit',
                tip: 'vip的数量单位是以天数作为单位 金币是pc 永久会员填写8888 该字段只能是数字 不要填写字母或中文',
                hideInSearch: true,
               
            },

            {
                title: '价格',
                align: 'center',
                dataIndex: 'price',
                tip: '真实价格',
                key: 'price',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                           <a style={{color:'red'}}>{_}</a>
                      );
                }
               
            },
            {
                title: '原价',
                align: 'center',
                dataIndex: 'originPrice',
                key: 'originPrice',
                hideInSearch: true,
               
            },
            {
                title: '支付通道',
                align: 'center',
                dataIndex: 'params',
                tip: '支付商使用的支付通道参数 ',
                hideInSearch: true,
               
            },
            {
                title: '赠送金币',
                align: 'center',
                dataIndex: 'stock',
                tip: '购买服务赠送的金币 请勿填写负数',
                hideInSearch: true,
               
            },
            {
                title: '商品描述',
                align: 'center',
                dataIndex: 'remarks',
                key: 'remarks',
                hideInSearch: true,
               
            },

            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={()=>this.upd(record)}>修改</a>
                            <Popconfirm
                                title="确定操作？"
                                onConfirm={() => this.del(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a style={{marginLeft: 10, color: 'red'}}
                            >删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];


    }


    handleCancel = () => {
        this.setState({
            visible: false,
            showList:false,
            row:null
        })
    };
    add = () => {
        this.setState({
            visible: true
        })
    }
    upd = (row) => {
        this.setState({
            visible: true,
            row:row
        })
    }



    del = (record) => {
        this.props.delVip({id: record.id}).then(res => {
            this.props.list();

        })
    };


    render() {
        const {vipData} = this.props;
        const {row} = this.state;
        return (
            <div>
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                     
                        this.props.list()
                    }}
                    dataSource={vipData}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"
                    search={false}
                    revalidateOnFocus={true}
                    dateFormatter="string"
                    headerTitle="vip配置列表"
                    toolBarRender={() => [
                       
                        <Button key="create" type="primary" onClick={() => this.add()}>
                            添加
                        </Button>

                    ]}
                />

                    <Modal destroyOnClose open={this.state.visible}
                           footer={null} 
                           onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} row={row} ></Add>
                    </Modal>


            </div>
        );

    }


}


const mapState = state => ({
    ...state.vip

});

const mapDispatch = dispatch => ({
    ...dispatch.vip
})

export default connect(mapState, mapDispatch)(VipSetting);