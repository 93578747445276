/* eslint-disable import/no-anonymous-default-export */
import {
    list,syncVideo,child,parse
} from '../services/momentsCommentApi';
import {

    sourceDel,sourceClean
} from '../services/MonitorApi';
export default {
    state: {
        data: [],
        childs:[],
    },
    effects: {
        async list(params) {

            const response = await list(params)

            if (response.code === 0) {
                this.savelist({
                    data: response.data,

                })
            }
        },
        async child(params) {

            const response = await child(params)

            if (response.code === 0) {
                this.saveChild({
                    childs: response.data,

                })
            }
        },


        async parse(params) {
          return   await parse(params)
        },
        async sourceDel(params) {
            return   await sourceDel(params)
          },

          async sourceClean(params) {
            return   await sourceClean(params)
          },

    
        async syncVideo(params) {
          return   await syncVideo(params)
        },


    },
    reducers: {
        savelist(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveChild(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }
    }
}