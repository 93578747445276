/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { Form, Input, Button, Select, message } from 'antd';
import { removeEmptyField } from '../../../compoent/emptyFild'
import { connect } from "react-redux";
// var CryptoJS = require("crypto-js");
const { Option } = Select;

class updateAdmin extends Component {
    state = {
        res: []
    }
    componentWillMount() {
        const { roleListPage } = this.props
        roleListPage().then(response => {
            if (response.code === 0) {
                this.setState({ res: response.data.records })

            }
        })
    }
    onFinish = (values) => {
        if (values) {
            // values.page = 1
            // values.size = 10
            values.id = this.props.record.id
            const newValues = removeEmptyField(values)
            this.props.updateAdmin(newValues).then(res => {
                message.success('修改成功')
                this.props.adminListPage({ page: 1, size: 10 })
            }
            )
        } this.props.handleCancel()
    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { res } = this.state
        //
        const { record, roleListData } = this.props
        //
        // const bytes = CryptoJS.AES.decrypt(password, 'password')
        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        name: record.loginName ? record.loginName : '',
                        roleId: record.roleId ? record.roleId : '',
                        password: record.password ? record.password : '',
                        remarks: record.remarks ? record.remarks : '',
                    }}
                >


                    <Form.Item
                        label="账户名："
                        name="name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="角色："
                        name="roleId"
                    >
                        <Select
                            showSearch
                            placeholder='请选择订单状态'
                        // style={{ marginLeft:10}}
                        // placeholder=""

                        >
                            {res.map(item => (
                                <Option key={item.id} value={item.id}>
                                    {item.roleName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="密码："
                        name="password"
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="备注："
                        name="remarks"
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit" >
                            确认
                        </Button>
                        <Button type="primary" htmlType="reset" style={{ marginLeft: 30 }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.admin

});

const mapDispatch = dispatch => ({
    ...dispatch.admin
})

export default connect(mapState, mapDispatch)(updateAdmin);