/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Col,  Row, Space } from 'antd';
import { ProFormText, ProForm, ProFormTextArea,ProFormUploadButton} from '@ant-design/pro-form';


class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            imgUrl:null,
            fileList:[]
        };
    }

    onFinish = (e) => {
        const {imgUrl} = this.state
        const {row} = this.props
        console.log('e',e)
        if(row){
            e.id=row.id
        }
        if(imgUrl)
         e.url = imgUrl

        this.props.edit(e)
        .then(res=>{
            if(res.code ==0){
                this.props.list()
               
            }
        })
        this.setState({imgUrl:null})
        this.props.handleCancel()
    };

 

    render() {
        const {tagList,status,feesType,fileList} = this.state;
        const {img,type,row} = this.props
      
       
        return (
          <ProForm grid={true}  
          initialValues={row?{
            type: row.type||'',
            params: row.params||'',
            remark:row.remark||''
        }:{}}
        onFinish={(v)=>this.onFinish(v)}
          submitter={{
                    render: (props, doms) => {
                      return (
                        <Row>
                          <Col  >
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                
                  }}
                
                >
        <ProFormText
          width="md"
          name="type"
          label="参数类型"
          tooltip="最长为 24 位"
          placeholder="请输入名称"
        />

        <ProFormText
          width="md"
          name="remark"
          label="参数描述"
          placeholder="请输入名称"
        />

        <ProFormUploadButton 
         width="md"
         name="urlParams" max={1} tooltip="图片参数" label="图片参数" placeholder="图片参数"
            fieldProps={{
                name: 'file',
                beforeUpload: async (file) => {
                    this.setState({loading: true})
                    img({file: file,type:"img"}).then(r => {
                        if (r.code === 0)
                            this.setState({imgUrl:r.data})
                        return true
                    })


                    return false
                }
            }}
        />
        <ProFormTextArea  width="md"  name="params" label="文本参数" placeholder="文本参数"/>
                </ProForm>
          
        )


    }


}

const mapState = state => ({
    ...state.apiMonitor

});

const mapDispatch = dispatch => ({
    ...dispatch.apiMonitor
})

export default connect(mapState, mapDispatch)(Add);