import {init} from "@rematch/core";
import login from "../models/login";
// import system from "../models/system";
import homeData from "../models/home";

import users from "../models/users";
import pay from '../models/pay'
import finance from "../models/finance";
import spread from "../models/spread";
import label from "../models/label";
import admin from "../models/admin";
import role from "../models/role";
import moments from "../models/moments";
import feedback from '../models/feedback'
import complaint from '../models/complaint'
import preserve from '../models/retained'
//import level from '../models/level'
//import sell from '../models/level'
import systemInfo from '../models/systemInfo'
import activity from '../models/activity'
import orderComment from '../models/orderComment'
import momentsComment from '../models/momentsComment'
import face from '../models/face'
import vcs from '../models/vcs'
//import agreement from '../models/agreement'
import memberLog from '../models/memberLog'
import menus from '../models/menus'
import banner from '../models/banner'
import payControl from '../models/payControl'

import circleContent from '../models/circleContent'
import circleComment from '../models/circleComment'
//import identity from '../models/identity'
import apiMonitor from '../models/apiMonitor'
import vip from '../models/vip'


export default init({
    models: {
        login,
        homeData,
      //  order,
        users,
        pay,
        finance,
        spread,
        label,
        admin,
        role,
        moments,
        feedback,
        complaint,
      //  preserve,
      //  level,
        systemInfo,
        activity,
        orderComment,
        momentsComment,
        face,
        vcs,
       // agreement,
  
        memberLog,
        menus,
        banner,
        payControl,
     //   circleType,
     //   circleName,
        circleContent,
        circleComment,
    //    identity,
        apiMonitor,
        preserve,
        vip
    }
});
