import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, Table, Tooltip} from 'antd';
import Add from './component/Add'

class AgreementList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            record: {},
            type: '',
            pageNum: 1,
            pageSize: 10,
            current: 1
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',

            },
            {
                title: '协议名称',
                dataIndex: 'name',

            },
            {
                title: '协议内容',
                render: (_, record) => {
                    return (
                        <Tooltip placement="top" title={record.content}>
                            <div style={{
                                width: 80,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>
                                {record.content}
                            </div>

                        </Tooltip>
                    );
                }

            },

            {
                title: '添加时间',
                dataIndex: 'createTime',

            },
            {
                title: '添加人',
                dataIndex: 'createUserId',

            },
            {
                title: '操作',
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.upd(record)} style={{marginLeft: 2}}>编辑</a>
                            <a onClick={() => this.del(record)} style={{marginLeft: 30,color:'red'}}>删除</a>
                        </div>
                    )
                }
            }
        ];
    }

    componentDidMount() {
        this.init();
    }


    init = () => {
        const {list} = this.props
        list({page: 1, size: 10})
    }

    query = (current, pageSize) => {
        this.setState({current})
        this.props.list({ page: current, size: this.state.pageSize })
    }
    del = (record) => {
        const {data} = this.props
        this.props.del({id: record.id}).then(res => {
            this.props.list({page:data.current,size:this.state.pageSize})
        })
    };
    handleCancel = () => {
        this.setState({
            visible: false
        })
    };
    upd = (record) => {
        this.setState({visible: true, record, type: '1'})
    }

    render() {
        const {data, userLoading} = this.props;
        const {visible, record, type, current} = this.state;
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.query,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data}
                       rowKey={record => record.id} pagination={pagination}/>
                <div>
                    <Modal destroyOnClose visible={visible} title={type === '0' ? "新增" : '编辑'} footer={null}
                           onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} record={record} type={type}
                             current={current}></Add>
                    </Modal>
                </div>
            </div>

        );
    }
}


const mapState = state => ({
    ...state.agreement
});

const mapDispatch = dispatch => ({
    ...dispatch.agreement

})

export default connect(mapState, mapDispatch)(AgreementList);