/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Col, Row, Space } from 'antd';
import {ProFormRadio, ProFormText, ProForm, ProFormTextArea} from '@ant-design/pro-form';

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            type: '',
            wx: true,
            payType:[
                {label:'微信',value:'1'},
                {label:'支付宝',value:'2'}
            ]

        };
    }
    componentDidMount(){
        const {type,row} = this.props
        if(type==="1"){
            this.setState({wx:row.type==='1'})
            }
    }

    onChangeType = (e) => {
        let h5 = e.target.value !== '2'
        this.setState({wx: h5})
    };
    onFinish = (e) => {
        const {type,row} = this.props
        if (type === '1')
             e.id = row.id
         this.props.upd(e).then(res => {
                    this.props.list()
                })
        this.props.handleCancel()

    };


    render() {
        const {row} = this.props
        const {wx,payType} = this.state
 
        return (
           
          <ProForm  grid={true} 
          initialValues={row?{
            type:row?row.type:"1",
            name:row?row.name:'',
            privateKey:row?row.privateKey:'',
            publicKey:row?row.publicKey:'',
            appId:row?row.appId:'',
            mchId:row?row.mchId:'',
            cert:row?row.cert:'',
            signType:row?row.signType:'',
            icon:row?row.icon:'',
            params:row?row.params:'',
           

          }:{}}
          submitter={{
                    render: (props, doms) => {
                      return (
                        <Row >
                          <Col  >
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                
                  }}
                  onFinish={ (v) => this.onFinish(v)}
                >
              <ProFormRadio.Group
                    rules={[ {required: true, message: '请选择支付类型!' } ]}
                        name="type"
                        label="支付类型"
                        options={payType}
                        onChange={this.onChangeType}
                    />          
             <ProFormText rules={[ {required: true, message: '请输入支付名称!' } ]}  name="name" label="支付名称" placeholder="请输入支付名称 "/>
             <ProFormText rules={[ {required: false, message: '请输入AppId!' } ]}  name="appId" label="AppId" placeholder="请输入Appid "/>
             <ProFormText rules={[ {required: true, message: '请输入MchId!' } ]} tooltip="商户号id"  name="mchId" label="mchId" placeholder="请输入mchId "/>
             <ProFormText rules={[ {required: true, message: '!!!' } ]}  tooltip="支付渠道 支付id " name="icon" label="icon" placeholder="参考商户后台调用的支付id "/> 
             <ProFormText rules={[ {required: true, message: '支付时显示的名称!' } ]}  tooltip="用户支付时显示的支付描述 " name="params" label="支付窗口显示的支付商品信息" placeholder="支付窗口显示的支付商品信息 "/>
             <ProFormText   tooltip="回调 " name="cert" label="回调" placeholder="cert "/>
             <ProFormText   tooltip="正序排序 " name="signType" label="排序" placeholder="正序排序 "/>
             <ProFormTextArea  rules={[ {required: true, message: '请输入私钥!' } ]} tooltip="支付密钥" name="privateKey" label="privateKey" placeholder="privateKey "/>
             <ProFormTextArea  rules={[ {required: true, message: '支付接口API!' } ]} tooltip="支付接口API" name="publicKey" label="支付接口API" placeholder="支付接口API "/>

            
        </ProForm>
           
        )


    }


}

const mapState = state => ({
    ...state.payControl

});

const mapDispatch = dispatch => ({
    ...dispatch.payControl
})

export default connect(mapState, mapDispatch)(Add);