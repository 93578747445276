/* eslint-disable import/no-anonymous-default-export */
import {list,del,allType,allNames} from '../services/circleContentApi';

export default {
    state: {
        data: []
    },
    effects: {
        async list(params) {
            const response = await list(params)
            if (response.code === 0) {
                this.saveData({
                    data: response.data,

                })
            }
        },

        async del(params) {
            await del(params)
        },

        async allType() {
            return await allType();
        },
        async allNames() {
           return await allNames();
        },

    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },

    }
}