import request from '../utils/request';
import url from '../utils/url';

//意见反馈列表
//视频列表
export function path() {
    return request(url.sortPaths, {
        method: 'POST'
    });
}

//视频列表
export function list(params) {
    return request(url.sortList, {
        method: 'POST',
        params
    });
}

//同步资源库
export function syncSort() {
    return request(url.sortSync, {
        method: 'POST'
    });
}
//标签数据
export function child(params) {
    return request(url.childTag, {
        method: 'POST',
        params
    });
}

//转码
export function parse(params) {
    return request(url.sortParse, {
        method: 'POST',
        params
    });
}

//修改
export function batchUpdate(params) {
    return request(url.sortUpdate, {
        method: 'POST',
        params
    });
}


//修改
export function status(params) {
    return request(url.sortStatus, {
        method: 'POST',
        params
    });
}
