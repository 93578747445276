/* eslint-disable import/no-anonymous-default-export */
import { list, status,upd,del} from '../services/payControlApi';


export default {
    state: {
        data: [],
    },
    effects: {
        async list(params) {
            const response = await list(params)
            if (response.code === 0) {
                this.saveData({
                    data: response.data,

                })
            }
            return response.data
        },
  
        async status(params) {
            await status(params)
        },
        async del(params) {
            await del(params)
        },
        async upd(params) {
            await upd(params)
        },

    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode,

            }
        },
    }
}