import React, {Component} from 'react'
import ContentList from './ContentList'
import {connect} from "react-redux";
import {Col, Row, Space ,Form,Select} from 'antd';
import {ProFormSwitch, ProFormText, ProForm,ProFormRadio} from '@ant-design/pro-form';

class Content extends Component {
    state = {
        data: [],
        status:[
            {label:'正常',value:1},
            {label:'下架',value:0}  
        ],
        feesType:[
            {label:'vip',value:"1"},
            {label:'金币',value:"2"},
            {label:'免费',value:"0"},
        ],
        tagList:[],
        type:'mh',
        newValues:{page:1,size:20}

    };


    componentDidMount() {
        if(this.state.tagList.length==0){
            this.props.child({type:this.state.type});
            let arr=[]
            this.props.childs.map(item=>{
                let obj={}
                obj.label=item.name
                obj.value=item.id
                arr.push(obj)
            })
            this.setState({tagList:arr})
        }
      

    }

    onFinish = (e) => {
      

        if (e) {
            e.status = e.status==true?1:0
            const {newValues} = this.state
           const params =   Object.assign(newValues,e )
           this.setState({newValues})
            this.props.list(params)
        } else {
            this.props.list()
        }

    };
    reset = () => {
        this.setState({newValues:{page:1,size:20}})

    };

    render() {
      
        const{ tagList,status,feesType,newValues} = this.state
          return (
              <div>
                  
                  <ProForm 
                   initialValues={ {status:true}}
                  submitter={{
                      render: (props, doms) => {
                        return (
                          <Row>
                            <Col  offset={2}>
                              <Space>{doms}</Space>
                            </Col>
                          </Row>
                        ) ;
                      },
                    }}
                    layout='inline'
                    onFinish={ (v) => this.onFinish(v)}
                    onReset={()=>this.reset()}
                  >
                      <ProFormText width="sm" name="title" label="标题" placeholder="搜索"/>
                      {/* <ProFormSelect width="xs" name="tagId" label="标签" placeholder="请选择标签"  options={tagList} />
                      <ProFormSelect width="xs" name="feesType" label="类型" placeholder="请选择类型" options={feesType} />
                      <ProFormSelect width="xs" name="status" label="状态" placeholder="请选择状态"  options={status} /> */}
                      <Form.Item
                            style={{ width: 200, marginLeft: 10 }}
                          
                            name="tagId"
                        >
                            <Select
                            width="xs"
                                showSearch
                                placeholder='请选择标签类型'
                                options={tagList}
                            >
                             
                            </Select>
                        </Form.Item>
                      
                        <ProFormRadio.Group
                            name="feesType"
                            layout="vertical"
                            radioType="button"
                            options={feesType}
                        />
                          <ProFormSwitch name="status" label="状态" />
                  </ProForm>
           
                  <div >
                   
                      <ContentList newValues={newValues} />
                  </div>
              </div>
  
          )
      }
}

const mapState = state => ({
    ...state.circleComment

});

const mapDispatch = dispatch => ({
    ...dispatch.circleComment
})

export default connect(mapState, mapDispatch)(Content);