import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table,Tooltip } from 'antd';

const tradeType = 1;
class PayList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',

        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'mid',
            },
            {
                title: '用户名',
                dataIndex: 'nickName',
            },
            {
                title: '真实姓名',
                dataIndex: 'name',
            },
            {
                title: '手机号',
                dataIndex: 'mobile',
            },
            {
                title: '充值金额',
                dataIndex: 'money',
            },
            {
                title: '渠道',
                dataIndex: 'method',
                render: (_, record) => {
                    return (
                        <div>
                            {record.method === '1' ? '微信' : record.method === '2' ? '支付宝' : ''}
                        </div>
                    )
                }
            },
            {
                title: '类型',
                dataIndex: 'payType',
                render: (_, record) => {
                    return (
                        <div>
                            {record.payType === '1' ? '在线支付' : record.payType === '2' ? '手工' : ''}
                        </div>
                    )
                }
            },
            {
                title: '余额',
                dataIndex: 'balance',
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (_, record) => {
                    return (
                        <div>
                            {record.status === '0' ? <Tooltip placement="top"  title={record.tips}>
                                <div style={{
                                    width: 100,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    color:'red'
                                }}>
                                    {'支付失败'}
                                </div>

                            </Tooltip> : record.status === '1' ? '支付成功' : ""}
                        </div>
                    )
                }
            },
            {
                title: '发起时间',
                dataIndex: 'addTime',
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',

            },


        ];
    }
    componentDidMount() {
        this.init();
    }

    init = () => {
        const { balanceLog } = this.props
        balanceLog({ tradeType: tradeType, page: 1, size: 10 })
    }

    handleChangePayList = (current, pageSize) => {
        this.props.balanceLog({tradeType: tradeType, page: current, size: 10 })
    }

    render() {
        const { data } = this.props;
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangePayList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table  columns={this.columns}  dataSource={data.records} rowKey={record => record.tradeNo} pagination={pagination} />
            </div>
        );
    }
}


const mapState = state => ({
    ...state.pay
});

const mapDispatch = dispatch => ({
    ...dispatch.pay
})

export default connect(mapState, mapDispatch)(PayList);