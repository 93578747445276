import React, { Component } from 'react'
import SystemInfoList from './SystemInfoList'
import style from './SystemInfo.module.scss'
import { connect } from "react-redux";

import AddInfo from './component/AddInfo'
import {
    Form,
    Input,
    Button,
    Modal,
    TimePicker,
    DatePicker,
    Select,

} from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;
// const orderStatus = ['未接单', '已接单', '进行中', '已完成', '订单取消', '中场取消']

class SystemInfo extends Component {
    state = {
        visible: false,
        type: ''

    }
    componentDidMount() {
        // this.props.list({ page: 1, size: 10 })
    }
    onFinish = (values) => {

    };
    onFinishFailed = (errorInfo) => {

    };

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    addInfo = () => {
        this.setState({ visible: true, type: '0' })
    }
    render() {
        const { RangePicker } = DatePicker;
        const { visible, type } = this.state
        const { updateListData } = this.props

        //
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >

                        <Form.Item style={{ marginLeft: 100, marginTop: 0 }}>
                            <Button type="primary" style={{ marginLeft: 1300 }}>
                                查询
                            </Button>
                            <Button onClick={this.addInfo} type="primary" style={{ marginLeft: 30 }}>
                                新增
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <SystemInfoList />

                </div>
                <div>
                    <Modal destroyOnClose visible={visible} title='新增' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <AddInfo handleCancel={this.handleCancel} type={type}></AddInfo>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(SystemInfo);