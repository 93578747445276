import React, { Component } from 'react'
import LabelList from './LabelList'
import style from './Label.module.scss'
import { connect } from "react-redux";
import { removeEmptyField } from '../compoent/emptyFild'
import AddLabel from './component/AddLabel'
import {
    Form,
    Input,
    Button,
    Modal,
    Select

} from 'antd';

class Label extends Component {
    state = {
        visible: false,
        type: '',
        parents:[
            {id:1,name:'视频'},
            {id:2,name:'漫画'},
            {id:3,name:'短视频'},
            {id:4,name:'小说'},
        ],
        cateTypes:[
            {id:'tag',name:'普通标签'},
            {id:'cate',name:'栏目标签'},
            {id:'nav',name:'导航栏标签'},
            {id:'recommend',name:'推荐标签'},
            {id:'yy',name:'演员标签'},
            {id:'cj',name:'厂家标签'}
        ],
        newValues:{}


    }
    componentDidMount() {
        
       
     //   this.props.parentTag();
     //   const {parentTagData} = this.props
        // const  parents =[]
        // parentTagData.map(item=>{
        //     let obj ={}
        //     obj.id=item.id;
        //     obj.name=item.name;
        //     parents.push(obj)
        // })
       
    //   this.setState({parents})
        this.props.list({ page: 1, size: 20 })
    }
    onFinish = (values) => {


        if (values) {
            //
            values.page = 1
            values.size = 20
            let newValues = removeEmptyField(values)
            this.setState({newValues: newValues})
            this.props.list(newValues)
        } else {
            this.props.list()
        }

    };
    onFinishFailed = (errorInfo) => {

    };

    addLabel = () => {
        this.setState({
            visible: true,
            type: '0'
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    render() {
        
        const { visible, type ,parents,newValues,cateTypes} = this.state

        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{ width: 260 }}
                            label="名称："
                            name="name"
                        >
                            <Input placeholder='请输入名称查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="板块"
                            name="owner"
                        >
                            <Select
                                showSearch
                                placeholder='请选择板块类型'

                            >
                                {parents.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="类型："
                            name="cateType"
                        >
                            <Select
                                showSearch
                                placeholder='请选择标签类型'

                            >
                                {cateTypes.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>


                        <Form.Item style={{ marginLeft: 8, marginTop: 0 }}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{ marginLeft: 18 }}>
                                重置
                            </Button>
                            <Button onClick={this.addLabel} type="primary" style={{ marginLeft: 18 }}>
                                新增
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <LabelList parent={parents} newValues={newValues}/>

                </div>
                <div>
                    <Modal destroyOnClose open={visible} title='新增' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <AddLabel handleCancel={this.handleCancel} type={type} parent={parents} newValues={newValues}/>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.label

});

const mapDispatch = dispatch => ({
    ...dispatch.label
})

export default connect(mapState, mapDispatch)(Label);