/* eslint-disable import/no-anonymous-default-export */
import { activityListPage, addAactivity, imgs, delAactivity } from '../services/activityApi'

export default {
    state: {
        data: [],
        addAactivitydata: [],
        imgData: [],
        delAactivityData: []
    },
    effects: {
        async activityListPage(params) {
            const response = await activityListPage(params)

            if (response.code === 200) {
                this.saveActivityListPage({
                    data: response.data
                })

            }
        },
        async addAactivity(params) {
            const response = await addAactivity(params)

            if (response.code === 200) {
                this.saveAddAactivity({
                    addAactivitydata: response.data
                })

            }
        },
        async imgs(params) {
            const response = await imgs(params)
            if (response.code === 200) {
                this.saveImg({
                    imgData: response.data[0].relativePath
                })

            }
        },
        async delAactivity(params) {
            const response = await delAactivity(params)
            if (response.code === 200) {
                this.saveDelAactivity({
                    delAactivityData: response.data
                })

            }
        },
    },
    reducers: {
        saveActivityListPage(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveAddAactivity(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveImg(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        saveDelAactivity(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
        clear(state, payload) {
            return {
                ...state,
                imgData: [],
            }
        },
    },

}