import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Image, message, Modal, Popconfirm, Table, Tooltip} from 'antd';
import ChangeOrderNum from './component/ChangeOrderNum'


export const SendOrderList = (props) => {
    const [current, setCurrent] = useState(1)
    const [visible, setVisible] = useState(false)
    const [record, setRecord] = useState({})
    const columns = [
        {
            title: '订单编号',
            dataIndex: 'orderId',
            width: 200,
            align: 'center',

        },
        {
            title: '发单人',
            dataIndex: 'nickName',
            width: 200,
            align: 'center',
            render: (_, record) => {
                return (
                    <div>
                        {_}({record.pid})
                    </div>
                );
            },

        },
        {
            title: '类型',
            dataIndex: 'category',
            width: 150,
            align: 'center',
        },
        {
            title: '订单金额',
            dataIndex: 'price',
            width: 150,
            align: 'center',
        },
        {
            title: '排序',
            dataIndex: 'orderNum',
            width: 150,
            align: 'center',
            sorter: true,
            render: (_, record) => {
                return (
                    <a onClick={() => changeOrderNum(record)}>{_}</a>
                )
            }
        },
        {
            title: '内容',
            dataIndex: 'otherDesc',
            width: 200,
            align: 'center',
            render: otherDesc => (
                <Tooltip placement="topLeft" title={otherDesc}>
                    <div style={{
                        width: 100,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>
                        {otherDesc}
                    </div>
                </Tooltip>
            ),

        },

        {
            title: '图片',
            dataIndex: 'imgs',
            width: 300,
            align: 'center',
            render: (_, record) => (<div>
                {record.imgs ? record.imgs.map((i, k) => (
                    <Image style={{width: 40, height: 40, marginLeft: 10}} key={k} src={i}/>)) : ''}
            </div>)
        },
        {
            title: '开始时间',
            dataIndex: 'date',
            width: 150,
            align: 'center',

        },

        {
            title: '操作',
            align: 'center',
            // dataIndex: 'addTime',
            render: (_, record) => {
                return (record.audit === '1' ?
                        <div>
                            {record.status === 0 ? <a onClick={() => delOrder(record, 1)}
                                                      style={{color: "grey"}}>关闭</a> : record.status === 1 ?
                                <Popconfirm
                                    title="确定关闭该订单吗？"
                                    onConfirm={() => delOrder(record, 0)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <a>正常</a>
                                </Popconfirm> : record.status === 2 ? '已下架' : ''}
                        </div>

                        : <div>

                                <a  onClick={() => pass(record)}
                                    style={{color: "green",marginRight:20}}>通过</a>

                                <Popconfirm
                                    title="不通过后 该订单会被关闭 "
                                    onConfirm={() => delOrder(record, 0)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <a style={{color: "red"}}>不通过</a>
                                </Popconfirm>

                        </div>
                )
            }
        },
    ];

    useEffect(() => {
        props.sendOrder({page: 1, size: 10})
    }, [])

    const handleCancel = () => {
        setVisible(false)
    };
    const handleChangeOrderList = (current, pageSize) => {
        props.newValues.pageNo = current
        props.newValues.pageSize = 10
        if (!props.newValues.audit)
            props.newValues.audit = props.audit
        setCurrent(current)
        props.sendOrder({...props.newValues})
    }
    const changeOrderNum = (record) => {
        setVisible(true)
        setRecord(record)
    }
    const pagination = {
        current: props.sendOrderData.current,//当前页

        total: props.sendOrderData.total,//总条数
        pageSize: props.sendOrderData.size,//页大小
        onChange: handleChangeOrderList,
        showQuickJumper: true,//快速跳转
        showSizeChanger: false//大于50时显示pageSize切换器
    };

    const handleTableChange = (newPagination, filters, sorter) => {
        props.newValues.sort = sorter.order

        props.newValues.pageNo = newPagination.current
        props.newValues.pageSize = 10
        props.sendOrder({...props.newValues});
    };

    const delOrder = (record, status) => {

        // const { orderPublishCancel } = this.props
        props.orderPublishCancel({orderId: record.orderId, status: status}).then(res => {
            // setStatus(false)
            message.success('关闭成功')
            props.sendOrder({pageNo: current, pageSize: 10})

        })
    }
    const pass = (record) => {

        // const { orderPublishCancel } = this.props
        props.orderAuditPass({id: record.orderId}).then(res => {
            // setStatus(false)
            message.success('成功')
            props.sendOrder({pageNo: current, pageSize: 10})

        })
    }
    return (
        <div>
            <Table
                scroll={{y: 625}}
                columns={columns}
                // loading={userLoading}
                dataSource={props.sendOrderData.records}
                rowKey={record => record.id}
                pagination={pagination}
                onChange={handleTableChange}
            />
            <Modal destroyOnClose visible={visible} footer={null} title={'数值越大排序越靠前'}
                   onCancel={() => handleCancel()} handleCancel={() => handleCancel()}>
                <ChangeOrderNum handleCancel={() => handleCancel()} record={record}
                                newValues={props.newValues}></ChangeOrderNum>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.order

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.order

})

export default connect(mapStateToProps, mapDispatchToProps)(SendOrderList)
