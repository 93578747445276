import React, { Component } from 'react'
import ComplaintList from './ComplaintList'
import { connect } from "react-redux";
import { removeEmptyField } from '../../compoent/emptyFild'
import moment from 'moment';
import {
    Form,
    Input,
    Button,
    DatePicker,
    Select,

} from 'antd';
const { Option } = Select;

class Complaint extends Component {
    state = {
        statusData: [
            {
                name: '待处理',
                id: 0
            },
            {
                name: '已禁用',
                id: 1
            },
            {
                name: '不处理',
                id: 2
            },
        ]

    }
    componentDidMount() {

    }
    onFinish = (values) => {
        const beginTime = values.beginTime
        values.beginTime = beginTime ? moment(beginTime[0]).format('YYYY-MM-DD') : ''
        values.endTime = beginTime ? moment(beginTime[1]).format('YYYY-MM-DD') : ''

        if (values) {
            //
            values.page = 1
            values.size = 5
            let newValues = removeEmptyField(values)
            this.props.reportListPage(newValues)
        } else {
            this.props.reportListPage()
        }

    };
    onFinishFailed = (errorInfo) => {

    };

    render() {
        const { RangePicker } = DatePicker;
        const { statusData } = this.state

        return (
            <div>
                <div>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{ width: 320 }}
                            label="用户名"
                            name="name"
                        >
                            <Input placeholder='请输入发单人查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="类型"
                            name="status"
                        >
                            <Select
                                showSearch
                                placeholder='请选择订单类型'

                            >
                                {statusData.map((item, id) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: 0 }}
                            label="时间:"
                            name="beginTime"
                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }} />


                        </Form.Item>
                        <Form.Item style={{ marginLeft: 100, marginTop: 0 }}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{ marginLeft: 42 }}>
                                重置
                            </Button>

                        </Form.Item>

                    </Form>
                </div>
                <div 
            >
                    <ComplaintList />
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.complaint


});

const mapDispatch = dispatch => ({
    ...dispatch.complaint
})

export default connect(mapState, mapDispatch)(Complaint);