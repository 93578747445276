/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {Button, Form, Input, Radio, Select,Modal} from 'antd';
import {connect} from "react-redux";
import {removeEmptyField} from '../../compoent/emptyFild';


const {Option} = Select;

class CancelModal extends Component {


    onFinish = (values) => {
        const newValues = removeEmptyField(values)
         //测试
         const listParams = this.props.newValues
            if (newValues) {
                this.props.publishCancel(newValues).then(res => {
                    this.props.OrderList({...listParams});
                })
            }

        this.props.handleCancel()
    };

    onFinishFailed = (errorInfo) => {

    };

    render() {
        const { record } = this.props
        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 14}}
                    initialValues={{remember: true}}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        id: record ? record.orderId : ''
                    }}
                >

                    <Form.Item
                        label="订单id："
                        name="id"
                        rules={[{ required: true, message: '请输入参数!' }]}

                    >
                        <Input  disabled  placeholder="订单id " />
                    </Form.Item>
                    <Form.Item
                        label="手续费："
                        name="type"
                        rules={[{required: true, message: '请选择!'}]}
                    >
                        <Radio.Group options={[{label: '不扣取', value: 0}, {label: '扣除', value: 1}]}/>
                    </Form.Item>



                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                        <Button type="primary" htmlType="reset" style={{marginLeft: 30}}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.order

});

const mapDispatch = dispatch => ({
    ...dispatch.order
})

export default connect(mapState, mapDispatch)(CancelModal);