/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form} from 'antd';
import { ProFormSelect, ProFormText} from '@ant-design/pro-form';


class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            type: '',

        };
    }

    onFinish = (values) => {
        const {type, record} = this.props
        if (values.pid === '菜单栏')
            values.pid = 0
        else if (record&&values.pid === record.parentName)
            values.pid = '';
        if (type === '0') {
            if (values) {
                this.props.add(values).then(res => {
                    this.props.list()
                })
            }
        }
        else if (type === '1') {
            //编辑接口
            if (values) {

                values.id = this.props.record.id
                this.props.upd(values).then(res => {
                    this.props.list()
                })
            }

        }
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };

    render() {
        const {record, pd} = this.props
        const parents = [{label: '菜单栏', values: 0}];
        if (pd && pd.length > 0) {
            pd.forEach(item => {
                let option = {};
                option.value = item.id;
                option.label = item.menuName;
                parents.push(option)
            })
        }

        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      autoComplete="off" initialValues={{
                    menuName: record ? record.menuName : '',
                    pid: record ? record.pid : '',
                    remarks: record ? record.remarks : '',
                    menuUrl: record ? record.menuUrl : '',
                    pid: record ? record.parentName : '',
                }}>

                    <ProFormText rules={[
                        {
                            required: true,
                            message: '请输入菜单名称!',
                        },
                    ]} width="md" name="menuName" label="菜单名称" placeholder="菜单名称 "/>
                    <ProFormSelect rules={[
                        {
                            required: true,
                            message: '请输选择菜单类型!',
                        },
                    ]} options={parents} width="md" name="pid" label='菜单类型'/>
                    <ProFormText tooltip="确保唯一性" width="md" name="menuUrl" label="路由路径"
                                 placeholder="路由路径"
                                 rules={[
                                     {
                                         required: true,
                                         message: '请输入路由路径!',
                                     },
                                 ]}/>
                    <ProFormText tooltip="确保唯一性" width="md" name="remarks" label="权限字符" placeholder="权限字符 "
                                 rules={[
                                     {
                                         required: true,
                                         message: '请输入权限字符!',
                                     },
                                 ]}/>

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="reset">
                            重置
                        </Button>
                        <Button type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        )


    }

    a
}

const mapState = state => ({
    ...state.menus

});

const mapDispatch = dispatch => ({
    ...dispatch.menus
})

export default connect(mapState, mapDispatch)(Add);