import React, {Component} from 'react';
import {connect} from "react-redux";
import {Table, Tooltip} from 'antd';

class LogList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            current: 1
        }

        this.columns = [
            {
                title: '用户id',
                dataIndex: 'userId',

            },
            {
                title: '用户名',
                dataIndex: 'name',

            },
            {
                title: '操作类型',
                render: (_, record) => {
                    return (
                        <div>
                            {record.type === 1 ? <div style={{color: "blue"}}>发单</div> : record.type === 2 ?
                                <div>发动态</div> : ''}
                        </div>
                    );
                }
            },
            {
                title: '操作内容',
                render: (_, record) => {
                    return (
                        <Tooltip placement="top" title={record.requestParam}>
                            <div style={{
                                width: 100,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>
                                {record.requestParam}
                            </div>

                        </Tooltip>
                    );
                }
            },

            {
                title: 'app版本',
                dataIndex: 'appVersion',

            },

            {
                title: 'ip',
                dataIndex: 'requestIp',

            },
            {
                title: '操作地址',
                dataIndex: 'requestUrl',

            },
            {
                title: '操作时间',
                dataIndex: 'createTime',

            },
            {
                title: '操作系统',
                render: (_, record) => {
                    return (
                        <div>
                            {record.requestPlatform === 2 ? <div>ios</div> :
                                <div style={{color: "blue"}}>Android</div>}
                        </div>
                    );
                }
            },
            {
                title: '手机型号',
                dataIndex: 'mobileType',
            }
        ];
    }

    componentDidMount() {
        this.init();
    }


    init = () => {
        this.props.list({page: this.state.pageNum, size: this.state.pageSize})
    }

    query = (current, pageSize) => {
        this.setState({current})
        this.props.list({page: current, size: this.state.pageSize})
    }


    render() {
        const {data, userLoading} = this.props;
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.query,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records}
                       rowKey={record => record.id} pagination={pagination}/>

            </div>

        );
    }
}


const mapState = state => ({
    ...state.memberLog
});

const mapDispatch = dispatch => ({
    ...dispatch.memberLog

})

export default connect(mapState, mapDispatch)(LogList);