import React, { Component } from 'react'

import LogList from './VipLogList'
import { connect } from "react-redux";
import { ProFormRadio, ProFormText, ProForm, ProFormDateRangePicker, ProFormSelect } from '@ant-design/pro-form';
import { Col, Row, Space } from 'antd';




class VipLog extends Component {
    state = {
        newValues: {},
        status: [
            { label: 'vip', value: 1 },
            { label: '金币', value: 2 }
        ],
        pay: [
            { label: '已支付', value: 1 },
            { label: '待支付', value: 0 },
            { label: '支付失败', value: -1 }
        ],
        first: [
            { label: '首充', value: "1" },
            { label: '复充', value: "0" }

        ],
        pays: [],
        channe: []
    }

    onFinish = (values) => {
        console.log('v', values)
        values.page = 1
        values.size = 20
        let newValues = values
        if (values.dateRange) {
            newValues.beginTime = values.dateRange[0]
            newValues.endTime = values.dateRange[1]
        }
        this.setState({ newValues })
        this.props.vipLogList(newValues)
    };
    reset = () => {
        this.setState({ newValues: { page: 1, size: 20 } })

    };

    componentDidMount() {
        this.props.vipoi();
        const { oi } = this.props
        this.setState({ pays: oi.pay, channe: oi.channe })
    }

    render() {
        const { status, pay,first } = this.state
        return (
            <div>
                <ProForm
                    grid={true}
                    submitter={{
                        searchConfig: {
                            submitText: '查询',
                        },
                        render: (props, doms) => {
                            return (
                                <Row>
                                    <Col span={24} offset={1}>
                                        <Space>{doms}</Space>
                                    </Col>
                                </Row>
                            );
                        },
                    }}
                    layout='inline'
                    onFinish={(v) => this.onFinish(v)}
                    onReset={() => this.reset()}
                >
                    <ProFormText colProps={{ md: 8, xl: 6 }} name="id" label="订单号" placeholder="请输入订单编号搜索" />
                    <ProFormDateRangePicker
                        colProps={{ md: 8, xl: 6 }}
                        label="时间"
                        name="dateRange"
                    />
                    <ProFormSelect colProps={{ md: 8, xl: 4 }} name="payMch" label="支付商" placeholder="收款账户" options={this.state.pays} />
                    <ProFormSelect colProps={{ md: 8, xl: 4 }} name="channe" label="渠道" placeholder="请选择" options={this.state.channe} />

                    <ProFormRadio.Group
                        name="type"

                        radioType="button"
                        options={status}
                        colProps={{ md: 4, xl: 4 }}
                    />
                    {/* <ProFormSelect width="sm" name="status" label="支付状态" placeholder="请选择" options={pay} /> */}

                    <ProFormRadio.Group
                        name="status"
                        colProps={{ md: 4, xl: 6 }}

                        options={pay}
                    />

                    <ProFormRadio.Group
                        name="first"
                        colProps={{ md: 4, xl: 4 }}

                        options={first}
                    />


                </ProForm>

                <LogList newValues={this.state.newValues} />


            </div>
        )
    }
}

const mapState = state => ({
    ...state.vip

});

const mapDispatch = dispatch => ({
    ...dispatch.vip
})

export default connect(mapState, mapDispatch)(VipLog);