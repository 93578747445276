import React, {Component} from 'react'
import {Image, Select, Table} from 'antd';
import {connect} from "react-redux";


class ApplyList extends Component {

    constructor(props) {
        super(props);


        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
            },
            {
                title: '申请人',
                dataIndex: 'name',
                render: (_, record) => {
                    return (
                        <div>
                            {_}({record.sauto})
                        </div>
                    );
                },
            },
            {
                title: '头像',
                dataIndex: 'img',
                render: (_, record) => (<div>
                    <Image style={{width: 60, height: 60}} key={_} src={_}/>
                </div>)
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (_, record) =>
                    (_ === -1 ? <div style={{color: 'gray'}}>无效</div> :
                    _ === 0 ? <div style={{color: 'gray'}}>申请中</div> :
                        _ === 1 ? <div style={{color: 'lime'}}>进行中</div> :
                            _ === 2 ? <div style={{color: 'red'}}>已完成</div> :
                                _ ===4 ? <div style={{color: 'red'}}>订单取消</div> : '')
            },
            {
                title: '申请接单时间',
                dataIndex: 'applyTime',
            },
            {
                title: '申请时间',
                dataIndex: 'addTime',
            },

        ];
    }


    componentDidMount() {
       this.props.dispatchOrderApplyList({page:1,size:20,id: this.props.record.id});
    }


    handleChangeList = (current, pageSize) => {
        this.props.dispatchOrderApplyList({page:current,size:20,id: this.props.record.id})
    }



    render() {
        const {applyData} = this.props;

        const pagination = {
            current: applyData.current,//当前页
            total: applyData.total,//总条数
            pageSize: applyData.size,//页大小
            onChange: this.handleChangeList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <Table columns={this.columns} dataSource={applyData.records} rowKey={record => record.id}
                   pagination={pagination}
            />

        );
    }
}

const mapState = state => ({
    ...state.order

});

const mapDispatch = dispatch => ({
    ...dispatch.order
})

export default connect(mapState, mapDispatch)(ApplyList);