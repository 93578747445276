/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Col, message, Row, Space } from 'antd';
import {ProFormSelect, ProFormText, ProForm} from '@ant-design/pro-form';


class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            type: '',
            loading: false,
            tagList:[],
            
        }
    }

    componentDidMount() { 
        let arr=[]
        this.props. childs.map(item=>{
            let obj={}
            obj.label=item.name
            obj.value=item.id
            arr.push(obj)
        })
      
        this.setState({tagList:arr})
        
    }

    onFinish = (e) => {
        const {tagList} = this.state
        const {row,mp} = this.props
        e.paths=mp
        const rowPath =[]
       if(row&&row.name){
           rowPath.push(row.name);
           e.paths=rowPath
       }
        if(e.tagId){
            let obj = tagList.filter((item)=>{ return e.tagId.some(curVal => (curVal === item.value)) })
            e.tag =obj.map(i=>{return i.label})

        }

       this.props.create(e)   
       .then(res=>{
        if (res.code===0) {
            message.info('提交成功');
            this.props.paths()
        }else{
            message.error('提交失败');
        }
        this.props.handleCancel() 
     })

    };


    render() {
        const {tagList} = this.state;
        return (
          
                <ProForm 
                layout='vi'
                submitter={{
                    render: (props, doms) => {
                      return (
                        <Row>
                          <Col  offset={16}>
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                  }}
                  onFinish={ (v) => this.onFinish(v)}
                >
                    <ProFormText  name="title" label="标题" placeholder="默认选取漫画封面图片名称作为标题 "/>
                    <ProFormSelect rules={[ {required: true, message: '请选择标签!' } ]}   name="tagId" label="标签" placeholder="请选择标签" mode="multiple" options={tagList} />
                    <ProFormText  name="descs" label="描述" placeholder="默认选取漫画封面图片名称 "/>
                    <ProFormText  name="hots" label="热度" placeholder="不填写有默认数值"/>
                    <ProFormText  name="collect" label="收藏" placeholder="不填写有默认数值"/>
                    <ProFormText  name="auth" label="作者" placeholder="作者"/>
                    <ProFormText  name="finish" label="连载" placeholder="0连载 1完结"/>
                    <ProFormText  name="feesCount" label="免费章节" placeholder="不填写默认前2章节免费阅读"/>
                    <ProFormText  name="viewer" label="观看人数" placeholder="不填写有默认数值"/>  
                </ProForm>
          
        )


    }


}

const mapState = state => ({
    ...state.circleComment

});

const mapDispatch = dispatch => ({
    ...dispatch.circleComment
})

export default connect(mapState, mapDispatch)(Add);