/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Col, message, Row, Space } from 'antd';
import {ProFormSelect, ProFormText, ProForm} from '@ant-design/pro-form';


class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            type: '',
            loading: false,
            tagList:[],
            // status:[
            //     {label:'正常',value:1},
            //     {label:'下架',value:0}  
            // ],
            feesType:[
                {label:'vip',value:1},
                {label:'金币',value:2},
                {label:'免费',value:0},
            ],
            
        }
    }

    componentDidMount() { 
        let arr = [];
        this.props.childs.map(item => {
            let obj = {}
            obj.label = item.name
            obj.value = item.id
            if (item.cateType === 'cj')
                obj.desc = '厂家'

            else if (item.cateType == 'yy')
                obj.desc = '演员'
            else if (item.cateType == 'tag') {
                obj.desc = '标签'

            }
            else if (item.cateType == 'nav') {
                obj.desc = '导航'
            }

            arr.push(obj)
        })
        this.setState({tagList:arr})
        
    }

    onFinish = (e) => {
        const {tagList} = this.state
        const {row,paths} = this.props
        e.paths=paths
         const rowPath =[]
        if(row&&row.name){
            rowPath.push(row.name);
            e.paths=rowPath
        }
        
        if(e.tagId){
            let obj = tagList.filter((item)=>{ return e.tagId.some(curVal => (curVal === item.value)) })
            e.tag =obj.map(i=>{return i.label})

        }

       this.props.parse(e)   
       .then(res=>{
        if (res.code===0) {
            message.info('任务提交成功');
            this.props.list()
        }else{
            message.error('提交失败');
        }
        this.props.handleCancel() 
     })

    };


    render() {
        const {tagList,status,feesType} = this.state;
        return (
          
                <ProForm 
                submitter={{
                    render: (props, doms) => {
                      return (
                        <Row>
                          <Col  offset={16}>
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                  }}
                  onFinish={ (v) => this.onFinish(v)}
                >
                    <ProFormText width="md" name="title" label="视频标题" placeholder="默认选取视频名字作为标题 "/>
                    <ProFormText width="md" name="descs" label="视频描述" placeholder="默认选取视频名字作为描述 "/>
                    <ProFormText width="md" name="hots" label="视频热度" placeholder="不填写有默认数值"/>
                    <ProFormText width="md" name="collect" label="视频收藏" placeholder="不填写有默认数值"/>
                    <ProFormText width="md" name="viewer" label="观看人数" placeholder="不填写有默认数值"/>
                    <ProFormText width="md" name="tmpTime" label="免费时长" placeholder="非vip免费时长 默认60s 请勿大于视频总时长"/>
                    {/* <ProFormSelect width="md" name="status" label="状态" placeholder="默认下架状态"  options={status}/>           */}
                    <ProFormSelect width="md" name="feesType" label="类型" placeholder="默认vip" options={feesType}/> 
                    <ProFormText width="md" name="feesPrice" label="金币价格" placeholder="付费类型为金币时的价格 默认3"/>         
                    <ProFormSelect width="md" rules={[ {required: true, message: '请选择标签!' } ]} name="tagId" label="视频标签" placeholder="请选择视频标签" mode="multiple" options={tagList}
                               fieldProps={{
                                optionItemRender(item) {
                                    return item.label + ' - ' + item.desc;
                                },
                            }} />     

                    
                </ProForm>
          
        )


    }


}

const mapState = state => ({
    ...state.momentsComment

});

const mapDispatch = dispatch => ({
    ...dispatch.momentsComment
})

export default connect(mapState, mapDispatch)(Add);