import React, { Component } from 'react'
import FeedbackList from './FeedbackList'

import { connect } from "react-redux";
import {Col, Row, Space ,Form,Select} from 'antd';
import {ProFormRadio,ProFormSwitch, ProFormText, ProForm} from '@ant-design/pro-form';

class Feedback extends Component {
    state = {
        data: [],
        status:[
            {label:'正常',value:1},
            {label:'下架',value:0}  
        ],
        feesType:[
            {label:'vip',value:"1"},
            {label:'金币',value:"2"}
         
        ],
        tagList:[],
        type:'dsp',
        newValues:{page:1,size:20}

        
    }
    componentDidMount() {
        if(this.state.tagList.length==0){
            this.props.child({type:this.state.type});
            let arr=[]
            this.props.childs.map(item=>{
                let obj={}
                obj.label=item.name
                obj.value=item.id
                arr.push(obj)
            })
            this.setState({tagList:arr})
        }
      

    }

    onFinish = (e) => {
       

        if (e) {
            const {newValues} = this.state
            e.status = e.status==true?1:0
           const params =   Object.assign(newValues,e )
            params.page=1
            this.setState({newValues:params})
            this.props.sortList(params)
        } else {
            this.props.sortList()
        }

    };
    reset = () => {
        this.setState({newValues:{page:1,size:20}})

    };

    render() {
      
      const{ tagList,status,feesType,newValues} = this.state
        return (
            <div>
                
                <ProForm 
                 initialValues={ {status:true}}
                submitter={{
                    searchConfig: {
                        submitText: '查询',
                    },
                    render: (props, doms) => {
                      return (
                        <Row>
                          <Col  offset={2}>
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                  }}
                  layout='inline'
                  onFinish={ (v) => this.onFinish(v)}
                  onReset={()=>this.reset()}
                >
                    <ProFormText width="sm"  name="title" label="标题" placeholder="搜索"/>
                    <Form.Item
                            style={{ width: 200, marginLeft: 10 }}
                          
                            name="tagId"
                        >
                            <Select
                            width="xs"
                                showSearch
                                placeholder='请选择标签类型'
                                options={tagList}
                            >
                             
                            </Select>
                        </Form.Item>
                      
                        <ProFormRadio.Group
                            name="feesType"
                            layout="vertical"
                            radioType="button"
                            options={feesType}
                        />
                          <ProFormSwitch name="status" label="视频状态" />
                </ProForm>
         
                <div >
                 
                    <FeedbackList newValues={newValues} />
                </div>
            </div>

        )
    }
}
const mapState = state => ({
    ...state.feedback

});

const mapDispatch = dispatch => ({
    ...dispatch.feedback
})

export default connect(mapState, mapDispatch)(Feedback);