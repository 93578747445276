/**
 * path: 路径名称
 * title: 导航名称
 * permission: 该页面是否有限权
 * hideInMenu: 该页面是否显示
 */

import Members from "../views/members/Members";
import Home from '../views/Home/Home'
import Order from '../views/OrderManage/Order/Order'
import Pay from '../views/Pay/Pay'
import Withdraw from "../views/Withdraw/WithdrawList";
import Label from "../views/Label/Label";
import Admin from "../views/system/Admin/Admin";
import Role from "../views/system/Role/Role";
import Feedback from "../views/Feedback/Feedback/Feedback";
import Complaint from '../views/Feedback/Complaint/Complaint'
import Promote from "../views/report/PromoteReport/Promote";
import Financial from "../views/report/FinancialReport/Financial";
import Moments from '../views/Moments/MomentList'
import Level from "../views/Level/Level";
import Retained from "../views/report/Retained/Retained";
import SendOrder from "../views/OrderManage/sendOrder/SendOrder";
import SystemInfo from "../views/SystemInfo/SystemInfo";
import SignIn from '../views/Activities/SignIn/SignIn';
import comment from '../views/OrderManage/comment/comment';
import MomentsComment from '../views/Moments/MomentsComment/Comment';
import Face from '../views/members/face/Face';
import Vcs from '../views/system/vcs/Vcs';
import Agreement from '../views/system/agreement/Agreement';
import MemberLog from '../views/members/log/Log';
import Menus from '../views/system/menus/Menus';
import Banner from '../views/Activities/banner/Banner'
import PayControl from '../views/system/payControl/PayControl'
//import CircleType from '../views/circle/type/Type'
//import CircleName from '../views/circle/name/Name'
import CircleContent from '../views/circle/content/Content'
import CircleComment from '../views/circle/comment/Comment'
import Identity from '../views/members/identity/Identity'
import SpreadSetting from '../views/system/spreadSetting/SpreadSetting'
import MonitorList from '../views/system/monitor/MonitorList'
import DispatchOrder from '../views/OrderManage/dispatch/DispatchOrder'
import Record from '../views/system/chatRecord/Record'
import VipSetting from '../views/system/vip/VipSetting'
import offlineMsg from '../views/system/offlineMsg/OfflineMsg'
import FilterKw from '../views/system/filter/FilterKw'
import Scale from '../views/system/scale/Scale'
import VipLogs from '../views/members/vipLog/VipLog'


const menus = [

    {
        path: "/home",
        title: "系统首页",
        permission: true,
        icon: 5,
        auth: "@home",
        component: Home

    },
    {
        path: "/report",
        title: "报表管理",
        permission: true,
        icon: 6,
        auth: "@report",
        children: [
            {
                path: "/promoteReport",
                title: "推广报表",
                permission: true,
                icon: 3,
                auth: "@report.promote",
                component: Promote
            },
            {
                path: "/financialReport",
                title: "内容分析",
                permission: true,
                icon: 3,
                auth: "@report.financial",
                component: Financial
            },
            {
                path: "/retainedReport",
                title: "数据分析",
                permission: true,
                icon: 3,
                auth: "@report.retained",
                component: Retained
            },

        ]
    },
    {
        path: "/member",
        title: "用户管理",
        permission: true,
        icon: 6,
        auth: "@member",
        children: [

            {
                path: "/members",
                title: "会员管理",
                permission: true,
                icon: 3,
                auth: "@members",
                component: Members
            },
            {
                path: "/face",
                title: "真人审核",
                permission: true,
                icon: 3,
                auth: "@members.face",
                component: Face
            },
            {
                path: "/identity",
                title: "实名认证",
                permission: true,
                icon: 3,
                auth: "@members.identity",
                component: Identity
            },
            {
                path: "/log",
                title: "用户日志",
                permission: true,
                icon: 3,
                auth: "@members.log",
                component: MemberLog
            }, {
                path: "/vipLogs",
                title: "vip记录",
                permission: true,
                icon: 3,
                auth: "@vipLogs",
                component: VipLogs
            },


        ]
    },
        {
        path: "/dynamic",
        title: "视频",
        permission: true,
        icon: 3,
        auth: "@moments",
        // component: Activities
        children: [
            {
                path: "/moments",
                title: "视频列表",
                permission: true,
                icon: 3,
                auth: "@.moments",
                component: Moments
            },
            {
                path: "/momentscomment",
                title: "资源库",
                permission: true,
                icon: 3,
                auth: "@moments.momentscomment",
                component: MomentsComment
            }
        ]
    },
    {
        path: "/circle",
        title: "漫画管理",
        permission: true,
        icon: 3,
        auth: "@circle",
        children: [
            // {
            //     path: "/type",
            //     title: "圈子类型",
            //     permission: true,
            //     icon: 3,
            //     auth: "@circle.type",
            //     component: CircleType
            // },
            // {
            //     path: "/name",
            //     title: "圈子名称",
            //     permission: true,
            //     icon: 3,
            //     auth: "@circle.name",
            //     component: CircleName
            // },
            {
                path: "/content",
                title: "漫画列表",
                permission: true,
                icon: 3,
                auth: "@circle.content",
                component: CircleContent
            },
            {
                path: "/circleComment",
                title: "漫画资源库",
                permission: true,
                icon: 3,
                auth: "@circle.comment",
                component: CircleComment
            },

        ]
    },


    {
        path: '/ordermanage',
        title: '订单管理',
        permission: true,
        icon: 3,
        auth: "@orders",
        // component: Order,
        children: [
            {
                path: '/order',
                title: '订单管理',
                permission: true,
                icon: 3,
                auth: "@orders.order",
                component: Order,
            },
            {
                path: '/sendOrder',
                title: '发单管理',
                permission: true,
                icon: 3,
                auth: '@orders.sendOrder',
                component: SendOrder,
            },
            {
                path: '/dispatchOrder',
                title: '派单管理',
                permission: true,
                icon: 3,
                auth: '@orders.dispatchOrder',
                component: DispatchOrder,
            },
            {
                path: '/comment',
                title: '评价管理',
                permission: true,
                icon: 3,
                auth: '@orders.comment',
                component: comment,
            }
        ]
    },
    {
        path: '/pay',
        title: '充值记录',
        permission: true,
        icon: 3,
        auth: "@pay",
        component: Pay
    },
    {
        path: "/withdraw",
        title: "提现记录",
        permission: true,
        icon: 3,
        auth: "@withdraw",
        component: Withdraw
    },

    {
        path: "/label",
        title: "标签管理",
        permission: true,
        icon: 3,
        auth: "@label",
        component: Label
    },
    {
        path: "/system",
        title: "系统管理",
        permission: true,
        icon: 6,
        auth: "@system",
        children: [


            {
                path: "/admin",
                title: "管理员",
                permission: true,
                icon: 3,
                auth: "@system.admin",
                component: Admin
            },

            {
                path: "/role",
                title: "角色管理",
                permission: true,
                icon: 3,
                auth: "@system.role",
                component: Role
            },

            {
                path: "/system/vcs",
                title: "app版本",
                permission: true,
                icon: 3,
                auth: "@system.vcs",
                component: Vcs
            },
            {
                path: "/system/agreement",
                title: "用户协议",
                permission: true,
                icon: 3,
                auth: "@system.agreement",
                component: Agreement
            },
            {
                path: "/menus",
                title: "菜单设置",
                permission: true,
                icon: 3,
                auth: "@system.menus",
                component: Menus
            },
            {
                path: "/payControl",
                title: "菜单设置",
                permission: true,
                icon: 3,
                auth: "@system.payControl",
                component: PayControl
            },
            {
                path: "/spreadSetting",
                title: "推广配置",
                permission: true,
                icon: 3,
                auth: "@system.spreadSetting",
                component: SpreadSetting
            },
            {
                path: "/apiMonitor",
                title: "接口统计",
                permission: true,
                icon: 3,
                auth: "@system.apiMonitor",
                component: MonitorList
            },

            {
                path: "/chatRecord",
                title: "聊天记录",
                permission: true,
                icon: 3,
                auth: "@system.record",
                component: Record
            },

            {
                path: "/vipRule",
                title: "vip配置",
                permission: true,
                icon: 3,
                auth: "@system.vipRule",
                component: VipSetting
            },
            {
                path: "/offlineMsg",
                title: "离线消息推送",
                permission: true,
                icon: 3,
                auth: "@system.offlineMsg",
                component: offlineMsg
            },
            {
                path: "/filterKeyword",
                title: "关键词过滤",
                permission: true,
                icon: 3,
                auth: "@system.filterKeyword",
                component: FilterKw
            },
            {
                path: "/scale",
                title: "关键词过滤",
                permission: true,
                icon: 3,
                auth: "@system.scale",
                component: Scale
            },


        ]
    },
    {
        path: "/back",
        title: "用户反馈",
        permission: true,
        icon: 6,
        auth: "@back",
        children: [
            {
                path: "/feedback",
                title: "意见反馈",
                permission: true,
                icon: 3,
                auth: "@back.feedback",
                component: Feedback
            },
            {
                path: "/complaint",
                title: "举报列表",
                permission: true,
                icon: 3,
                auth: "@back.complaint",
                component: Complaint
            },
        ]
    },
    {
        path: "/level",
        title: "用户等级",
        permission: true,
        icon: 3,
        auth: "@level",
        component: Level
    },


  
    {
        path: "/systemInfo",
        title: "系统消息",
        permission: true,
        icon: 3,
        auth: "@systemInfo",
        component: SystemInfo
    },
    {
        path: "/activities",
        title: "活动管理",
        permission: true,
        icon: 3,
        auth: "@activities",
        // component: Activities
        children: [
            {
                path: "/signIn",
                title: "签到活动",
                permission: true,
                icon: 3,
                auth: "@activities.signIn",
                component: SignIn
            },
            {
                path: "/banner",
                title: "banner",
                permission: true,
                icon: 3,
                auth: "@activities.banner",
                component: Banner
            },
        ]
    },

];

const routes = [];
const menu = [];

function handleRouters(data) {
    data.forEach(item => {
        if (item.children) {
            handleRouters(item.children);
        } else {
            if (item.component) {
                routes.push(item);
            }
        }
    });
}

handleRouters(menus);

export {menus, routes};
