import React, { Component } from 'react'
import OrderList from './OrderList'
import style from './Order.module.scss'
import { connect } from "react-redux";
import { removeEmptyField } from '../../compoent/emptyFild'
import moment from 'moment';
import {
    Form,
    Input,
    Button,
    DatePicker,
    Select,

} from 'antd';

const { Option } = Select;

class Order extends Component {
    state = {
        type: [
            {
                id: '0',
                cname: '待接单'
            },
            // {
            //     id: '1',
            //     cname: '已接单'
            // },
            {
                id: '1',
                cname: '进行中'
            },
            {
                id: '3',
                cname: '已完成'
            },
            {
                id: '4',
                cname: '订单取消'
            },
            // {
            //     id: '5',
            //     cname: '中场取消'
            // },
        ],
        newValues: {}
        // order: [
        //     {
        //         id: 0,
        //         cname: '未接单'
        //     },
        //     {
        //         id: 1,
        //         cname: '已接单'
        //     },
        //     {
        //         id: 2,
        //         cname: '进行中'
        //     },
        //     {
        //         id: 3,
        //         cname: '已完成'
        //     },
        //     {
        //         id: 4,
        //         cname: '订单取消'
        //     },
        //     {
        //         id: 5,
        //         cname: '中场取消'
        //     },
        // ],

    }
    componentDidMount() {
        const { OrderList, list } = this.props
       // OrderList({ page: 1, size: 10 })
        list({ size: 50 })
    }
    onFinish = (values) => {
        const beginTime = values.beginTime
        values.beginTime = beginTime ? moment(beginTime[0]).format('YYYY-MM-DD') : ''
        values.endTime = beginTime ? moment(beginTime[1]).format('YYYY-MM-DD') : ''
        const publishBeginTime = values.publishBeginTime
        values.publishBeginTime = publishBeginTime ? moment(publishBeginTime[0]).format('YYYY-MM-DD') : ''
        values.publishEndTime = publishBeginTime ? moment(publishBeginTime[1]).format('YYYY-MM-DD') : ''
        //选择框判断
        values.category = values.category ? values.category : ''
        values.orderStatus = values.orderStatus ? values.orderStatus : ''

        if (values) {
            //
            values.page = 1
            values.size = 10
            const newValues = removeEmptyField(values)
            this.setState({ newValues: newValues })
            this.props.OrderList(newValues)
        } else {
            this.props.OrderList({ page: 1, size: 10 })
        }

    };
    onFinishFailed = (errorInfo) => {

    };
    onChange = (value) => {

        this.setState({ num: value })

    }


    render() {
        const { RangePicker } = DatePicker;
        const { listData } = this.props
        const { type, newValues } = this.state
        return (
            <div >
                <div className={style.top} >
                    <Form
                        style={{ marginTop: 20, marginLeft: 10 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{ width: 200 }}
                            label="订单编号"
                            name="orderId"
                        >
                            <Input placeholder='请输入订单编号' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 200 }}
                            label="发单人"
                            name="p_member"
                        >
                            <Input placeholder='请输入发单人查询' />
                        </Form.Item>

                        <Form.Item
                            style={{ width: 200, marginLeft: 1 }}
                            label="接单人"
                            name="c_member"
                        >
                            <Input placeholder='请输入接单人查询' />
                        </Form.Item>

                        <Form.Item
                            style={{ width: 200, marginLeft: 1 }}
                            label="类型"
                            name="category"
                        >
                            <Select
                                showSearch
                                placeholder='请选择订单类型'

                            >
                                {listData.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.caption}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ width: 200, marginLeft: 1 }}
                            label="状态"
                            name="orderStatus"

                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            <Select
                                showSearch
                                placeholder='请选择订单状态'
                            // style={{ marginLeft:10}}
                            // placeholder=""

                            >
                                {type.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.cname}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ width: 250, marginLeft: 1 }}
                            label="开始时间"
                            name="beginTime"
                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }} />


                        </Form.Item>
                        <Form.Item
                            style={{ width: 250, marginLeft: 1 }}
                            label="添加时间"
                            name="publishBeginTime"

                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{ marginLeft: 20 }}>
                                重置
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <OrderList newValues={newValues} />
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.order

});

const mapDispatch = dispatch => ({
    ...dispatch.order
})

export default connect(mapState, mapDispatch)(Order);