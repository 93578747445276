import echarts from 'echarts';
export function schart1(data,type) {
    var chartDom = document.getElementById('main');
    var myChart = echarts.init(chartDom);
    var option;
    //const data = [["2000-06-05", 116], ["2000-06-06", 129], ["2000-06-07", 135], ["2000-06-08", 86], ["2000-06-09", 73], ["2000-06-10", 85], ["2000-06-11", 73], ["2000-06-12", 68], ["2000-06-13", 92], ["2000-06-14", 130], ["2000-06-15", 245], ["2000-06-16", 139], ["2000-06-17", 115], ["2000-06-18", 111], ["2000-06-19", 309], ["2000-06-20", 206], ["2000-06-21", 137], ["2000-06-22", 128], ["2000-06-23", 85], ["2000-06-24", 94], ["2000-06-25", 71], ["2000-06-26", 106], ["2000-06-27", 84], ["2000-06-28", 93], ["2000-06-29", 85], ["2000-06-30", 73], ["2000-07-01", 83], ["2000-07-02", 125], ["2000-07-03", 107], ["2000-07-04", 82], ["2000-07-05", 44], ["2000-07-06", 72], ["2000-07-07", 106], ["2000-07-08", 107], ["2000-07-09", 66], ["2000-07-10", 91], ["2000-07-11", 92], ["2000-07-12", 113], ["2000-07-13", 107], ["2000-07-14", 131], ["2000-07-15", 111], ["2000-07-16", 64], ["2000-07-17", 69], ["2000-07-18", 88], ["2000-07-19", 77], ["2000-07-20", 83], ["2000-07-21", 111], ["2000-07-22", 57], ["2000-07-23", 55], ["2000-07-29", 60],["2000-08-20", 1160],];
    const dateList = data.map(function (item) {
      return item.day;
    });
    let title = 'ip访问量'
    const valueList = data.map(function (item) {
        switch (type){
            case 'ip' :  title = "ip访问量"; return item.access;
            case 'register':  title = "用户注册";  return item.register;   
            case 'order':  title = "订单累计"; return item.orderCount;  
            case 'pay':   title = "支付订单"; return item.payOrder;   
            case 'amount':  title = "收入总计";  return item.income;   
            default : return item.access;
        }
      return item.type;
    });
    option = {
        // title: {
        //     text: '注册人数/盈利总额'
        // },
        tooltip: {
            trigger: 'axis'

        },
        legend: {
            data: ['人数', '金额'],
            textStyle: {
                fontSize: 15,
            }
        },
        grid: {
            left: '5%',
            right: '6%',
            bottom: '5%',
            containLabel: true,


        },

        xAxis: {
            type: 'category',
            boundaryGap: false,
            name: '时间',
            nameTextStyle: { "fontSize": 18 },
            data: data?dateList:'',
            axisLine: {
                lineStyle: {
                    color: 'grey'
                }
            },


        },
        yAxis: {
            type: 'value',
            name: '数量',
            nameTextStyle: { "fontSize": 18 },
            axisLine: {
                lineStyle: {
                    color: 'grey',
                }
            },
        },
        series: [

            {
                name: title,
                type: 'line',
                nameTextStyle: { "fontSize": 18 },
                stack: 'Total',
                color: 'rgb(218,178,120)',
                data: data?valueList:100
            },
 
           

        ]
    };


    option && myChart.setOption(option);
}