import React, {Component} from "react";
import {connect} from 'react-redux';
import ProTable from '@ant-design/pro-table';
import Add from './Add'
import {Button, Modal} from 'antd';

class Scale extends Component {

    constructor(props) {
        super(props);

        this.state = {
            row: {}

        };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                hideInSearch: true,
                width: 80,
                valueType: 'indexBorder',
            },
            {
                title: '名称',
                align: 'center',
                copyable: true,
                ellipsis: true,
                dataIndex: 'name',
                key: 'name',
                hideInSearch: true,
                width: 80,

            },

            {
                title: '比例',
                align: 'center',
                ellipsis: true,
                copyable: true,
                tip: '抽成比例',
                hideInSearch: true,
                dataIndex: 'scale',
                key: 'scale',
                width: 80,
            },


            {
                title: '操作',
                width: 80,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.upd(record)} style={{marginLeft: 2}}>编辑</a>
                        </div>
                    )
                }
            }

        ];


    }


    handleCancel = () => {
        this.setState({
            visible: false
        })
    };

    upd = (record) => {
        this.setState({visible: true, row: record, type: '1'})
    }


    render() {
        const {row, type} = this.state;
        const {spreadData} = this.props;

        return (
            <div>
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                        this.props.spreadList(params)
                    }}
                    dataSource={spreadData}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"
                    search={false}
                    pagination={{
                        pageSize: 20,
                    }}

                    dateFormatter="string"
                    headerTitle="抽成列表"

                />
                <Modal destroyOnClose visible={this.state.visible} title={type === '0' ? '新增' : '编辑'}
                       footer={null}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} record={row} type={type}></Add>
                </Modal>
            </div>
        );

    }


}


const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(Scale);