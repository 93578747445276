import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Popconfirm, Button, Modal, QRCode, Tag, message } from 'antd';
import Add from './Add'
import DetailList from './DetailList';
import Xl from './Xl';
import Account from './Account';
import { Col, Row, Space } from 'antd';
import { ProForm, ProFormSelect } from '@ant-design/pro-form';
import ProTable from '@ant-design/pro-table';

class WithdrawList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNum: 1,
            pageSize: 20,
            visible: false,
            row: null,
            detail: false,
            acc: {},
            showAcc: false,
            params: {},
            showXl:false,

        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                aligin: 'center',
                hideInSearch: false,
            },
            {
                title: '渠道名称',
                dataIndex: 'name',
                hideInSearch: true,
                align: 'center'

            },
            {
                title: '落地页链接',
                dataIndex: 'link',
                hideInSearch: true,
                align: 'center',
                render: (_, record) => { return record.link? 'https://' + record.link + '/' + record.id + '.html':'-' }
            },
            // {
            //     title: 'html',
            //     dataIndex: 'fileName',
            //     align: 'center'
            // },
            {
                title: '历史链接',
                dataIndex: 'urls',
                hideInSearch: true,
                align: 'center',
                width: 160,
                render: (_, record) => (<div>
                    {_ ? _.map((i, k) => (
                        <Tag style={{ marginTop: 5 }} key={k} color="error" >
                            {i}
                        </Tag>)) : ''}
                </div>)
            },
            // {
            //     title: '分享码',

            //     render: (_, record) => <div id={record.id + 'qr'} onClick={() => this.downloadQRCode(record.id)}>
            //         <QRCode size='120' bgColor='white' errorLevel='H' value={record.link ? record.link : "-"} />

            //     </div>

            // },
            {
                title: '当前链接',
                dataIndex: 'remark',
                hideInSearch: true,
                align: 'center',
                copyable: true
            },
            {
                title: 'ip',
                dataIndex: 'totalUser',
                hideInSearch: true,
                align: 'center'
            },
            {
                title: '用户',
                dataIndex: 'register',
                hideInSearch: true,
                align: 'center'
            },

            {
                title: '金额',
                dataIndex: 'totalAmount',
                hideInSearch: true,
                align: 'center'
            },

            {
                title: '创建时间',
                dataIndex: 'addTime',
                hideInSearch: true,
                align: 'center'
            },
            {
                title: '账号/新增链接',
                align: 'center',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.showAcc(record)}>查看</a>
                            <a style={{ marginLeft: 12, color: 'green' }}  onClick={() => this.showXl(record)}>添加</a>
                        
                        </div>
                    )
                }
            },

            {
                title: '操作',
                align: 'center',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a  onClick={() => this.detailList(record)}>详情</a>
                            <a style={{ marginLeft: 12 }} onClick={() => this.update(record)}>编辑</a>
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.del(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a style={{ marginLeft: 12, color: 'red' }} >删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },


        ];
    }

    componentDidMount() {
        this.init();
    }
    downloadQRCode = (id) => {
        const canvas = document.getElementById(id + 'qr')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = 'code.png';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    init = () => {
        const { channelList, domainListAll, domainListAcc } = this.props
        channelList({ page: 1, size: 10 })
        domainListAll();
        domainListAcc();
    }


    query = (current, pageSize) => {
        let { newValues } = this.props;
        newValues.page = current;

        this.props.channelList({ ...newValues })
    }

    update = (record) => {
        this.setState({ visible: true, type: "1", title: '编辑', row: record })
    }
    showXl = (record) => {
        this.setState({ showXl: true, row: record })
    }
    showAcc = (record) => {
        this.props.channelAcc({ id: record.id })
            .then(res => {
                if (res.code == 0)
                    this.setState({ showAcc: true, acc: res.data })
                else
                    message.error(res.message)
            })

    }
    detailList = (record) => {
        this.setState({ detail: true, row: record })
    }
    add = (record) => {
        this.setState({ visible: true, type: "0", title: '新增', })
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            detail: false,
            row: null,
            acc: null,
            showAcc: false,
            showXl:false,
        })
    };

    del = (record) => {
        const { newValues } = this.state

        this.props.channelStatus({ id: record.id }).then(res => {
            this.props.channelList({ ...newValues })
        })
    };

    render() {
        const {  channelData } = this.props;
        const { row, type, acc } = this.state;
        const pagination = {
            current: channelData.current,//当前页
            total: channelData.total,//总条数
            pageSize: 10,//页大小
            //  onChange: this.query,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div >

                {/* <Table columns={this.columns} loading={userLoading} dataSource={channelData.records}
                    rowKey={record => record.id} pagination={pagination} /> */}
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                        this.setState({ params })
                        this.props.channelList(params)
                    }}
                    dataSource={channelData.records}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"

                    pagination={pagination}
                    revalidateOnFocus={true}
                    dateFormatter="string"
                    toolBarRender={() => [
                        <ProForm
                        submitter={{
                            resetButtonProps: {
                                style: {
                                    // 隐藏重置按钮
                                    display: 'none',
                                },
                            },
                            render: (props, doms) => {
                                return (
                                    <Row>
                                        <Col  >
                                            <Space>{doms}</Space>
                                        </Col>
                                    </Row>
                                );
                            },
                        }}
                        layout='inline'
                        onFinish={async (e) => {
                            message.success('设置中.请等待..');
                            if(!e.link){
                                message.error('非法操作..');
                                return;
                            }
                           
                            return this.props.updAccessLink(e)
                                .then(res => {
                                    if (res.code == 0) {
                                        message.success(res.message);
                                        this.props.channelList()
                                        return true;
                                    } else {
                                        message.error(res.message);
                                    }

                                })



                        }}
                        onReset={() => this.reset()}
                    >
                        <ProFormSelect name="link" label="访问域名" placeholder="批量设置访问域名" />

                    </ProForm>,
                        <Button type="primary" onClick={() => this.add()} >
                        新增
                    </Button>

                    ]}

                />



                <Modal destroyOnClose open={this.state.visible} title={type === '0' ? '新增' : '编辑'}
                    footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} row={row} type={type}></Add>
                </Modal>
                <Modal destroyOnClose open={this.state.showAcc} title='账号密码'
                    footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Account handleCancel={this.handleCancel} row={acc} ></Account>
                </Modal>

                <Modal destroyOnClose open={this.state.detail} title='渠道数据详情' width={1000}
                    footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <DetailList handleCancel={this.handleCancel} row={row} />
                </Modal>
                <Modal destroyOnClose open={this.state.showXl} title='新增链接' 
                    footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Xl handleCancel={this.handleCancel} row={row} params={this.state.params} />
                </Modal>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.pay


});

const mapDispatch = dispatch => ({
    ...dispatch.pay

})

export default connect(mapState, mapDispatch)(WithdrawList);