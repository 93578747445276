import request from '../utils/request';
import url from '../utils/url';

//
export function configPower(params) {
    return request(url.configPower, {
        method: 'POST',
        params
    })

}
//删除角色
export function delRole(params) {
    return request(url.delRole, {
        method: 'POST',
        params
    })
}
//角色详情
export function detailRole(params) {
    return request(url.detailRole, {
        method: 'POST',
        params
    })

}
//角色关联菜单（树形结构
export function getRoleMenu(params) {
    return request(url.getRoleMenu, {
        method: 'POST',
        params
    })

}
//角色列表
// export function roleList(params) {
//     return request(url.roleList, {
//         method: 'POST',
//         params
//     })

// }
//角色列表分页
export function roleListPage(params) {
    return request(url.roleListPage, {
        method: 'POST',
        params
    })

}
//角色修改
export function updateRole(params) {
    return request(url.updateRole, {
        method: 'POST',
        params
    })

}
export function addRole(params) {
    return request(url.addRole, {
        method: 'POST',
        params
    })

}