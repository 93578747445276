import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal, Popconfirm, Image, message } from 'antd';
import ChangeLevel from './components/ChangeLevel';
class Level extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
            visible: false,
            record: {},
            type: ''
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center'

            },

            {
                title: '漫画标题',
                dataIndex: 'title',
                align: 'center'


            },
            {
                title: '封面',
                dataIndex: 'titleImg',
                align: 'center',
                render: (_, record) => record.icon ? <div ><Image style={{ width: 50, height: 50 }} src={record.icon} /> </div> : null
            },
            {
                title: '标签',
                dataIndex: 'tagList',
                align: 'center'


            },
            {
                title: '类型',
                dataIndex: 'feesType',
                align: 'center'

            },
            {
                title: '价格',
                dataIndex: 'feesPrice',
                align: 'center'

            },
            {
                title: '章节状态',
                dataIndex: 'finish',
                align: 'center'

            },
            {
                title: '观看',
                dataIndex: 'viewer',
                align: 'center'

            },
            {
                title: '收藏',
                dataIndex: 'collect',
                align: 'center'

            },
            {
                title: 'hots',
                dataIndex: '热度',
                align: 'center'

            },
            {
                title: '作者',
                dataIndex: 'auth',
                align: 'center'

            },

            {
                title: 'descs',
                dataIndex: '描述',
                align: 'center'

            },
            {
                title: '漫画状态',
                dataIndex: 'status',
                align: 'center'

            },
            {
                title: '章节信息',
                dataIndex: 'status',
                align: 'center'

            },
 
            {
                title: '操作',
                dataIndex: 'maxConfition',
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.changeLevel(record)}>编辑</a>
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.delLevel(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a style={{ marginLeft: 30 }}>删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            },



        ];
    }
    componentDidMount() {
        this.init();
    }

    init = () => {
        const { memberLevelList } = this.props
        memberLevelList({ page: 1, size: 10 })
    }

    handleChangeLevel = (current, pageSize) => {
        this.props.memberLevelList({ page: current, size: 10 })
    }
    changeLevel = (record) => {
        this.setState({
            visible: true,
            record: record,
            type: '1'
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    delLevel = (record) => {
        this.props.delLevel({ id: record.id }).then(res => {
            message.success('删除成功')
            this.props.memberLevelList({ page: 1, size: 10 })
        }
        )
    }

    render() {
        const { data,userLoading } = this.props;
        const { visible, record, type } = this.state;



        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeLevel,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table scroll={{ y: 625 }} columns={this.columns}
                    loading={userLoading} dataSource={data} rowKey={record => record.id} pagination={pagination}
                />
                <Modal destroyOnClose visible={visible} title='编辑等级' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <ChangeLevel handleCancel={this.handleCancel} record={record} type={type}></ChangeLevel>
                </Modal>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.level
});

const mapDispatch = dispatch => ({
    ...dispatch.level

})

export default connect(mapState, mapDispatch)(Level);