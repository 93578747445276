/* eslint-disable import/no-anonymous-default-export */
import { reportListPage, reportUpdate } from '../services/complaintApi'

export default {
    state: {
        data: [],
        reportUpdateData: []
    },
    effects: {
        async reportListPage(params) {
            const response = await reportListPage(params)

            if (response.code === 200) {
                this.saveReportListPage({
                    data: response.data
                })
            }
        },
        async reportUpdate(params) {
            const response = await reportUpdate(params)

            if (response.code === 200) {
                this.saveReportUpdate({
                    reportUpdateData: response.data
                })
            }
        },
    },
    reducers: {
        saveReportListPage(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveReportUpdate(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }
    }
}
