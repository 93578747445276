import React, { Component } from 'react'
import { Form, Input, Button, Select } from 'antd';
import { removeEmptyField } from '../../../compoent/emptyFild'
import { connect } from "react-redux";


class AddRole extends Component {

    onFinish = (values) => {
        if (values) {
            values.page = 1
            values.size = 10
            const newValues = removeEmptyField(values)
            this.props.addRole(newValues).then(res => {
                this.props.roleListPage({ page: 1, size: 10 })
            }
            )
        } this.props.handleCancel()
    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        // const { Data } = this.props
        //
        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="角色名："
                        name="roleName"
                        rules={[{ required: true, message: '请输入标签名称!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="备注"
                        name="remarks"
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                        <Button type="primary" htmlType="reset" style={{ marginLeft: 30 }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.role

});

const mapDispatch = dispatch => ({
    ...dispatch.role
})

export default connect(mapState, mapDispatch)(AddRole);