import request from '../utils/request';
import url from '../utils/url';

//支付-提现记录
export function balanceLog(params) {
    return request(url.balanceLog, {
        method: 'POST',
        params
    })

}

export function channelList(params) {
    return request(url.channelPage, {
        method: 'POST',
        params
    })

}
export function channelDetailList(params) {
    return request(url.channelDetailList, {
        method: 'POST',
        params
    })

}
export function channelUpd(params) {
    return request(url.channelUpd, {
        method: 'POST',
        params
    })

}
export function channelStatus(params) {
    return request(url.channelStatus, {
        method: 'POST',
        params
    })

}
export function channelDetailStatus(params) {
    return request(url.channelDetailStatus, {
        method: 'POST',
        params
    })

}

export function domainListAll(params) {
    return request(url.domainListAll, {
        method: 'POST',
        params
    })

}
export function domainListAcc(params) {
    return request(url.domainListAcc, {
        method: 'POST',
        params
    })

}
//updAccessLink
export function updAccessLink(params) {
    return request(url.setLink, {
        method: 'POST',
        params
    })

}
//channelAcc
export function channelAcc(params) {
    return request(url.channelAcc, {
        method: 'POST',
        params
    })

}
//channelXl
export function channelXl(params) {
    return request(url.channelXl, {
        method: 'POST',
        params
    })

}