import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal, Popconfirm, message,Tooltip } from 'antd';
import AddInfo from './component/AddInfo'
class LabelList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
            visible: false,
            record: {}
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',

            },
            {
                title: '消息类型',
                dataIndex: 'type',
                render: (_, record) => {
                    return (
                        <div>
                            {record.type === 1 ? '系统消息' : '其他'}
                        </div>
                    )
                }

            },
            {
                title: '消息内容',
                dataIndex: 'content',
                render: content => (
                    <Tooltip placement="topLeft" title={content}>
                        <div style={{width: 500, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            {content}
                        </div>
                    </Tooltip>
                ),

            },
            {
                title: '添加时间',
                dataIndex: 'createTime',


            },
            {
                title: '操作',
                // dataIndex: 'addTime',
                render: (_, record) => {
                    return (
                        <div>
                            {/* <a onClick={() => this.updateInfo(record)}>编辑</a> */}
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.confirm(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a style={{ marginLeft: 30 }} >删除</a>
                            </Popconfirm>,
                        </div>
                    )
                }
            },


        ];
    }
    componentDidMount() {
        this.init();
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    init = () => {
        const { msgList } = this.props
        msgList({ pageNo: 1, pageSize: 10 })
    }

    handleChangeLabelList = (current, pageSize) => {
        this.props.msgList({ pageNo: current, pageSize: 10 })
    }
    updateInfo = (record) => {
        this.setState({ visible: true, record })
    }
    // delInfo = (record) => {
    //     this.props.delCateTag({ id: record.id }).then(res => {
    //         this.props.msgList({ page: 1, size: 10 })
    //     })
    // }
    confirm(record) {
        const { delMessage } = this.props
        delMessage({ id: record.id }).then(res => {
            message.success('删除成功');
            this.props.msgList({ pageNo: 1, pageSize: 10 })

        }
        )
    }


    render() {
        const { data, userTotal, userLoading } = this.props;
        const { pageNum, pageSize, visible, record } = this.state;
        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.pageSize,//页大小
            onChange: this.handleChangeLabelList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} sortDirections={["descend", "ascend",]}
                    loading={userLoading} dataSource={data.records} rowKey={record => record.id}
                    pagination={pagination}
                />
                <div>
                    <Modal destroyOnClose visible={visible} title='编辑' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <AddInfo handleCancel={this.handleCancel} record={record}  ></AddInfo>
                    </Modal>
                </div>
            </div>

        );
    }
}


const mapState = state => ({
    ...state.systemInfo
});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo

})

export default connect(mapState, mapDispatch)(LabelList);