import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { Table, Image, Popconfirm, message } from 'antd';
import {sc} from "../../../constant/consts";

export const ActivitiesList = (props) => {
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: 150,

        },
        {
            title: '活动名称',
            dataIndex: 'title',
            width: 250,


        },
        {
            title: '活动详情',
            dataIndex: 'content',
            textWrap: 'word-break',
            width: 500,
        },
        {
            title: '图片',
            dataIndex: 'imgUrl',
            width: 150,
            render: (_, record) => record.imgUrl ? <div ><Image style={{ width: 50, height: 50 }} src={record.imgUrl} /> </div> : null
        },
        {
            title: '添加时间',
            dataIndex: 'addTime',
            width: 250

        },
        {
            title: '操作',
            dataIndex: 'date',
            // width: 150,
            render: (_, record) => {
                return (
                    <div>
                        <Popconfirm
                            title="确定删除吗？"
                            onConfirm={() => handleDel(record)}
                            okText="删除"
                            cancelText="取消"
                        >
                            <a  >
                                删除
                            </a>
                        </Popconfirm>

                    </div>
                )
            }

        },
    ];
    useEffect(() => {
        props.activityListPage({ page: 1, size: 10 })

    }, []);

    const handleChangeActivityList = (current, pageSize) => {
        props.newValues.page = current
        props.activityListPage({ ...props.newValues })
    }
    const pagination = {
        current: props.data.current,//当前页
        total: props.data.total,//总条数
        pageSize: props.data.size,//页大小
        onChange: handleChangeActivityList,
        showQuickJumper: true,//快速跳转
        showSizeChanger: false//大于50时显示pageSize切换器
    };
    const handleDel = (record) => {
        props.delAactivity({ id: record.id }).then(res => {
            message.success('删除成功')
            props.activityListPage({ page: 1, size: 10 })
            // message.success('删除成功')
        })
    }
    return (
        <div>
            <Table
                scroll={{ sc }}
                columns={columns}
                // loading={userLoading}
                dataSource={props.data.records}
                rowKey={record => record.id}
                pagination={pagination}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.activity

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.activity

})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesList)
