/* eslint-disable import/no-anonymous-default-export */
import {updateTVipRule,addRights,delVip,delRights, list, rightsList,upload,vipLogs} from '../services/vipApi';

import {
 
    vipoi
} from '../services/usersApi'

export default {
    state: {
        vipData: [],
        rightsData:[],
        img:'',
        vipLogsData:[],
        oi:{},
    },
    effects: {
        async list(params) {
            const response = await list(params)
            if (response.code === 0) {
                this.saveData({
                    vipData: response.data,

                })
            }
            return response.data
        },


        async vipLogList(params) {
            const response = await vipLogs(params)
            if (response.code === 0) {
                this.saveData({
                    vipLogsData: response.data,
                    vipData: [],
                    rightsData:[],
                    img:''
                })
            }
            return response.data
        },



        async updateTVipRule(params) {
            await updateTVipRule(params)
        },
        async addRights(params) {
            await addRights(params)
        },
        async delVip(params) {
            await delVip(params)
        },
        async delRights(params) {
            await delRights(params)
        },
        async upload(params) {
            const response = await upload(params)
            if (response.code === 0) {

                this.saveData({
                    img: response.data
                })

            }
            return response
        },
        async vipoi(params) {
            const response = await vipoi(params)

            if (response.code === 0) {

                this.saveoi({
                    oi: response.data
                })
            }
        },


    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode,

            }
        },
        saveVipLogData(state, paylode) {
            return {
                ...state,
                ...paylode,

            }
        },
        saveoi(state, payload) {
            return {
                ...state,
                ...payload
            }
        },
    }
}