import React, { Component } from 'react';
import { connect } from "react-redux";
import { Image, Col, Row, Popconfirm, Table, Modal, Tag, Badge, Button, Space, Select, Form } from 'antd';
import ReactPlayer from 'react-player'
import Hls from 'hls.js';
import Add from './Add'

import { ProFormSelect, ProFormText, ProForm, ProFormSwitch, ProFormRadio } from '@ant-design/pro-form';
class MomentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            play: false,
            visible: false,
            row: {},
            selectedRowKeys: [],
            loading: false,
            title: "",
            type: '',
            sopen: false,
            current: 1,
            newValues: { page: 1, size: 20 },
            status: [
                { label: '正常', value: 1 },
                { label: '下架', value: 0 }
            ],
            feesType: [
                { label: 'vip', value: "1" },
                { label: '金币', value: "2" },
                { label: '免费', value: "0" },
            ],
            tagList: [],
            types: 'sp',
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',



            },
            {
                title: '标题',
                dataIndex: 'title',
                align: 'center',
                width: 260,
            },
            {
                title: '封面图片',
                dataIndex: 'titleImg',
                align: 'center',
                render: (_, record) => (

                    <Image style={{ width: 70, height: 50 }}
                        preview={{
                            imageRender: () => (
                                <ReactPlayer className='react-player'
                                    //这里是由上级页面传过来的视频地址
                                    url={record.videoPath}
                                    playing={this.state.play}
                                    width='80%'
                                    height="80%"
                                    controls

                                />
                            ),
                            maxScale: 40,
                            onVisibleChange: (flag) => {
                                console.log('eee', flag)
                                this.auto(!flag)
                                console.log('dd', this.state.play)
                            },

                        }}
                        src={_} />

                )


            },
            {
                title: '详情图',
                dataIndex: 'details',
                align: 'center',

                render: (_, record) => (
                    record.details && record.details.length > 0 ?
                        <Image.PreviewGroup
                            items={_}
                        >
                            <Image
                                style={{ width: 70, height: 50 }}
                                src={record.titleImg}
                            />
                        </Image.PreviewGroup>
                        : <div></div>


                )


            },
            {
                title: '付费类型',
                align: 'center',
                tip: '11',
                dataIndex: 'feesType',
                hideInSearch: true,//processing' : 'default'
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_ === 1 ? 'success' : _ === 0 ? 'default' : _ === 2 ? 'processing' : 'error'}
                            text={_ === 1 ? 'vip' : _ === 0 ? '免费' : _ === 2 ? '金币' : '暂无设置'} />
                    </a>

                )
            },
            {
                title: '价格',
                align: 'center',
                tip: '只有金币付费该价格才有效',
                dataIndex: 'feesType',
                hideInSearch: true,//processing' : 'default'
                render: (_, record) => (
                    <a>
                        {
                            _ === 2 ? record.feesPrice : '-'
                        }
                    </a>

                )
            },
            {
                title: '标签',
                dataIndex: 'tagList',
                align: 'center',
                width: 160,
                render: (_, record) => (<div>
                    {_ ? _.map((i, k) => (
                        <Tag key={k} color="processing" >
                            {i}
                        </Tag>)) : ''}
                </div>)
            },


            {
                title: '时长',
                dataIndex: 'seeTime',
                align: 'center',
            },
            {
                title: '收藏',
                dataIndex: 'collect',
                align: 'center',
            },

            {
                title: '热度',
                dataIndex: 'hots',
                align: 'center',
            },
            {
                title: '观看',
                dataIndex: 'viewer',
                align: 'center',
            },
            {
                title: '时间',
                dataIndex: 'updateTime',
                align: 'center',
            },
       
            {
                title: '是否上架',
                align: 'center',
                tip: '11',
                dataIndex: 'status',
                fixed: 'right',
                render: (_, record) => {
                    return (
                     
                                <a>
                                    <Popconfirm
                                        title="变更状态"
                                        onConfirm={() => this.status(record)}
                                        okText="确定"
                                        cancelText="取消"
                                    >


                                        <Badge
                                            status={_ === 1 ? 'success' : _ === 0 ? 'default' : 'error'}
                                            text={_ === 1 ? '已上架' : _ === 0 ? '未上架' : '错误'} />
                                    </Popconfirm>
                                </a>
                              
                    )

                }


            },

            {
                title: '操作',
                align: 'center',
                fixed: 'right',
                render: (_, record) => {
                    return (
                        <div>
                            <a disabled={this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0} onClick={() => this.update(record)}>编辑</a>
                            {record.status == 0 ?
                                <a style={{ marginLeft: 10, color: 'red' }}>
                                    <Popconfirm
                                        title="删除"
                                        onConfirm={() => this.del(record)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        删除

                                    </Popconfirm>
                                </a> : ''
                            }
                        </div>
                    )
                }
            },


        ];
    }

    componentDidMount() {

        this.props.child({ type: this.state.types });
        let arr = []
        this.props.childs.map(item => {
            let obj = {}
            obj.label = item.name
            obj.value = item.id
            arr.push(obj)
        })
        this.setState({ tagList: arr })

        this.props.moments({ ...this.state.newValues })
    }

    onFinish = (e) => {

        e.status = e.status == true ? 1 : 0
        if (e) {
            const { newValues } = this.state
            const params = Object.assign(newValues, e)
            params.page = 1
            console.log('ppp', params)
            this.setState({ newValues: params })
            console.log('thsi', this.state.newValues)
            this.props.moments(params)
        } else {
            this.props.moments()
        }

    };
    reset = () => {
        this.setState({ newValues: { page: 1, size: 20 } })

    };


    handleChangeMomentsList = (current, pageSize) => {
        const { newValues } = this.state
        newValues.page = current
        newValues.size = pageSize
        this.setState({ newValues })
        this.props.moments(newValues)
    }
    update = (record) => {
        this.setState({ visible: true, type: "0", title: '编辑', row: record })
    }
    add = (record) => {
        this.setState({ visible: true, type: "2", title: '新增', })
    }

    del = (record) => {
        this.props.fileDel({ id: record.id, type: this.state.types })
            .then(res => {
                if (res.code === 0) {
                    this.props.moments(this.state.newValues)
                }
            })
    }
    status = (row) => {
        this.props.changeStatus({ id: row.id, status: row.status == 1 ? 0 : 1 })
            .then(res => {
                if (res.code === 0) {
                    this.props.moments(this.state.newValues)
                }
            })

    }

    cache = (row) => {
        this.props.cache({ id: row.id })
            .then(res => {
                if (res.code === 0) {
                    this.props.moments(this.state.newValues)
                }
            })

    }
    refresh = (row) => {
        this.props.refresh({ id: row.id })
            .then(res => {
                if (res.code === 0) {
                    this.props.moments(this.state.newValues)
                }
            })

    }

    auto = (e) => {
        this.setState({
            play: e
        })

    }
    openSelect = () => {
        this.setState({ sopen: true })
    }
    closeSelect = () => {
        this.setState({ sopen: false })
    }
    start = () => {
        this.setState({ loading: true, visible: true, type: "1", title: '批量修改(' + this.state.selectedRowKeys.length + ")" })
        setTimeout(() => {
            this.setState({ loading: false });

        }, 1000);
    };
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({ selectedRowKeys: newSelectedRowKeys })
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            selectedRowKeys: [],
            row: null
        })
        this.props.moments(this.state.newValues)
    };

    render() {
        const { momentsData, userLoading } = this.props;
        const { feesType, tagList } = this.state
        const { visible, title, row, selectedRowKeys, loading, type } = this.state
        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = { selectedRowKeys, onChange: this.onSelectChange, }
        const pagination = {
            current: momentsData.current,//当前页
            total: momentsData.total,//总条数
            pageSize: momentsData.size,//页大小
            onChange: this.handleChangeMomentsList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: true//大于50时显示pageSize切换器
        };
        return (
            <div >

                <div style={{ marginBottom: 12 }}>
                    <ProForm
                        initialValues={{ status: true }}

                        submitter={{
                            searchConfig: {
                                submitText: '查询',
                            },
                            render: (props, doms) => {
                                return (
                                    <Row>
                                        <Col offset={2}>
                                            <Space>{doms}</Space>
                                        </Col>
                                    </Row>
                                );
                            },
                        }}
                        layout='inline'
                        onFinish={(v) => this.onFinish(v)}
                        onReset={() => this.reset()}
                    >
                        <ProFormText name="title" label="标题" placeholder="搜索" />
                        <Form.Item
                            style={{ width: 200, marginLeft: 10 }}

                            name="tagId"
                        >
                            <Select
                                width="xs"
                                showSearch
                                placeholder='请选择标签类型'
                                options={tagList}
                            >

                            </Select>
                        </Form.Item>

                        <ProFormRadio.Group
                            name="feesType"
                            layout="vertical"
                            radioType="button"
                            options={feesType}
                        />
                        <ProFormSwitch name="status" label="是否上架" />


                    </ProForm>
                </div>


                <Row >
                    <Col span={2} >
                        <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
                            批量操作
                        </Button>
                    </Col>
                    <Col span={2} >
                        {/* <Button disabled={true} icon={<PlusOutlined />} type="primary" onClick={this.add} >
                    新增
                </Button> */}
                    </Col>
                </Row>



                <Table columns={this.columns} loading={userLoading} scroll={{ x: 'max-content' }}
                    rowSelection={rowSelection} dataSource={momentsData.records} rowKey={record => record.id} pagination={pagination}
                />
                <Modal destroyOnClose open={visible} title={title} footer={null}
                    onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add current={this.state.current} handleCancel={this.handleCancel} childs={tagList} row={row} ids={selectedRowKeys} type={type} />
                </Modal>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.moments
});

const mapDispatch = dispatch => ({
    ...dispatch.moments

})

export default connect(mapState, mapDispatch)(MomentList);