import React, {Component} from 'react'
import FaceList from './IdentityList'
import style from './Identity.module.scss'
import {connect} from "react-redux";
import {removeEmptyField} from '../../compoent/emptyFild'

import {Button, Form, Input, Select,} from 'antd';

const {Option} = Select;

class Identity extends Component {
    state = {
        newValues:{idStatus:1},
    }

    onFinish = (values) => {

            let newValues = removeEmptyField(values);
            this.setState({ newValues: newValues })
            this.props.faceList(newValues)

    };
    onFinishFailed = (errorInfo) => {

    };

    render() {
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{marginTop: 20, marginLeft: 20}}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >

                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="审核"
                            name="status"
                        >
                            <Select>
                                <Option key={-1} value={-1}>已拒绝</Option>
                                <Option key={2} value={2}>通过</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{marginLeft: 0, marginTop: 0}}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{marginLeft: 30}}>
                                重置
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <FaceList newValues = {this.state.newValues}/>
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.identity

});

const mapDispatch = dispatch => ({
    ...dispatch.identity
})

export default connect(mapState, mapDispatch)(Identity);