import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Layout from '../views/layout/index';
import Login from '../views/login/login';
import AuthRouter from '../views/auth/authRouter';

const Router = () => {

    return (
        <HashRouter>
            <div >
                <Switch>
                    <Route component={Login} exact path='/login' />
                    <AuthRouter path='/' component={Layout} />
                </Switch>
            </div>
        </HashRouter>
    )
}

export default Router;