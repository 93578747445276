import request from '../utils/request';
import url from '../utils/url';

//动态管理列表分页
export function reportListPage(params) {
    return request(url.reportListPage, {
        method: 'POST',
        params
    })

}
export function reportUpdate(params) {
    return request(url.reportUpdate, {
        method: 'POST',
        params
    })

}
