import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal, Button, Image ,Tooltip} from 'antd';
import {sc} from "../../../constant/consts";

class ComplaintList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
            record: {},
            visible: false,
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 100


            },
            {
                title: '举报用户',
                dataIndex: 'sourceName',

            },
            {
                title: '被举报人',
                dataIndex: 'targetName',

            },
            {
                title: '举报内容',
                dataIndex: 'reportContent',
                render: reportContent => (
                    <Tooltip placement="topLeft" title={reportContent}>
                        <div style={{width: 150, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            {reportContent}
                        </div>
                    </Tooltip>
                ),
            },
            {
                title: '图片',
                dataIndex: 'img',
                render: (_, record) =>
                    record.img ? <div><Image style={{ width: 50, height: 50 }} src={record.img} /> </div> : null
            },
            {
                title: '时间',
                dataIndex: 'addTime',
            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (_, record) => {
                    return (

                        <div >

                            {record.status === 0 ? <a onClick={() => this.processed(record)}>待处理</a> : record.status === 1 ? '已禁用' : '不处理'}
                        </div>
                    )
                }
            },


        ];
    }
    componentDidMount() {
        this.init();
    }



    init = () => {
        const { reportListPage } = this.props
        reportListPage({ page: 1, size: 5 })
    }

    handleChangeComplaint = (current, pageSize) => {
        this.props.reportListPage({ page: current, size: 5 })
    }

    processed = (record) => {
        this.setState({
            visible: true,
            record
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    ignore = () => {
        this.props.reportUpdate({ id: this.state.record.id, status: 2, targetId: this.state.record.targetId }).then(
            this.props.reportListPage({ page: 1, size: 5 })
        )
        this.handleCancel()
    }

    disable = () => {
        this.props.reportUpdate({ id: this.state.record.id, status: 1, targetId: this.state.record.targetId }).then(
            this.props.reportListPage({ page: 1, size: 5 })
        )
        this.handleCancel()

    }
    render() {
        const { data, userLoading } = this.props;
        const {visible } = this.state;


        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeComplaint,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table scroll={{ sc }} columns={this.columns} loading={userLoading} dataSource={data.records} rowKey={record => record.id} pagination={pagination}
                />
                <Modal visible={visible} title='用户禁用' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <div >禁用该用户？</div>
                    <div >
                        <Button type='primary' onClick={this.ignore}>不处理</Button>
                        <Button type='primary' onClick={this.disable} style={{ marginLeft: 30 }}>禁用</Button>
                    </div>

                </Modal>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.complaint
});

const mapDispatch = dispatch => ({
    ...dispatch.complaint

})

export default connect(mapState, mapDispatch)(ComplaintList);