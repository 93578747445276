import React, {Component} from 'react'
import RecordList from './RecordList'
import style from './Record.module.scss'
import {Button, Form, Input, Tabs} from 'antd';
import {connect} from "react-redux";
import {removeEmptyField} from '../../compoent/emptyFild'

const {TabPane} = Tabs;


class Record extends Component {
    state = {
        status: 0,
        newValues:{}

    }

    componentDidMount() {

    }

    onFinish = (values) => {
        if (values) {
            //
            values.pageNo = 1
            values.pageSize = 20;
            values.status = this.state.status;
            let newValues = removeEmptyField(values)
            this.setState({newValues:newValues})
            this.props.chatRecordList(newValues)
        } else {
            this.props.chatRecordList({pageNo: 1, pageSize: this.props.chatRecordData.size, status: this.state.status})
        }
    };

    onChange = (key) => {
        this.setState({status: key})
        this.props.chatRecordList({pageNo: 1, pageSize: 20, status: key})
    };


    render() {
        const {status,newValues} = this.state

        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{marginTop: 20, marginLeft: 20}}
                        name="basic"
                        layout='inline'
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{width: 280}}
                            label="发送人"
                            name="sender"
                        >
                            <Input placeholder='请输入发送人查询'/>
                        </Form.Item>

                        <Form.Item
                            style={{width: 280}}
                            label="接收人"
                            name="receiver"
                        >
                            <Input placeholder='请输入接收人查询'/>
                        </Form.Item>

                        <Form.Item
                            style={{width: 260, marginLeft: 5}}
                            label="消息内容"
                            name="content"
                        >
                            <Input placeholder='请输入消息内容'/>
                        </Form.Item>

                        <Form.Item
                            style={{width: 300, marginLeft: 5}}
                            label="会话"
                            name="sessionKey"
                        >
                            <Input placeholder='请输入会话id查询'/>
                        </Form.Item>


                        <Form.Item style={{marginLeft: 20, marginTop: 2}}>

                            <Button type="primary" htmlType='reset'>
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit" style={{marginLeft: 15}}>
                                查询
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                <div >

                    <Tabs defaultActiveKey={0} onChange={this.onChange}>
                        <TabPane tab="未审核" key={0}>
                            <RecordList newValues={newValues} status={0}/>
                        </TabPane>
                        <TabPane tab="审核拒绝" key={-1}>
                            <RecordList  newValues={newValues} status={-1}/>
                        </TabPane>
                        <TabPane tab="审核通过" key={1}>
                            <RecordList  newValues={newValues} status={1}/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(Record);