import request from '../utils/request';
import url from '../utils/url';

//版本信息列表
export function list(params) {
    return request(url.memberLogList, {
        method: 'POST',
        params
    });
}

