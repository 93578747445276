import React, { Component } from 'react'
import AdminList from './AdminList'
import style from './Admin.module.scss'
import { connect } from "react-redux";
import { removeEmptyField } from '../../compoent/emptyFild'
import moment from 'moment';
import {
    Form,
    Input,
    Button,
    Modal,
    TimePicker,
    DatePicker,
    Select,

} from 'antd';
import AddAdmin from './component/AddAdmin';
const { Option } = Select;

class Admin extends Component {
    state = {
        visible: false,
        newValues: {}

    }
    componentDidMount() {

    }
    onFinish = (values) => {
        const beginTime = values.beginTime
        values.beginTime = beginTime ? moment(beginTime[0]).format('YYYY-MM-DD') : ''
        values.endTime = beginTime ? moment(beginTime[1]).format('YYYY-MM-DD') : ''

        if (values) {
            values.page = 1
            values.size = 10
            let newValues = removeEmptyField(values)
            this.setState({ newValues: newValues })
            this.props.adminListPage(newValues)
        } else {
            this.props.adminListPage()
        }

    };
    onFinishFailed = (errorInfo) => {

    };
    addAdmin = () => {
        this.setState({
            visible: true
        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    render() {
        const { RangePicker } = DatePicker;
        const { visible, newValues } = this.state
        //
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{ marginTop: 20, marginLeft: 20 }}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{ width: 320 }}
                            label="账户名"
                            name="name"
                        >
                            <Input placeholder='请输入发单人查询' />
                        </Form.Item>
                        <Form.Item
                            style={{ width: 320 }}
                            label="ID"
                            name="id"
                        >
                            <Input placeholder='请输入发单人查询' />
                        </Form.Item>

                        <Form.Item
                            style={{ marginTop: 0 }}
                            label="开始时间"
                            name="beginTime"
                        // rules={[{ message: 'Please input your username!' }]}
                        >
                            <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }} />


                        </Form.Item>
                        <Form.Item style={{ marginLeft: 100, marginTop: 0 }}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{ marginLeft: 42 }}>
                                重置
                            </Button>
                            <Button onClick={this.addAdmin} type="primary" style={{ marginLeft: 30 }}>
                                新增
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <AdminList newValues={newValues} />
                </div>
                <div>
                    <Modal destroyOnClose visible={visible} title='新增管理员' footer={null} onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <AddAdmin handleCancel={this.handleCancel} ></AddAdmin>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.admin

});

const mapDispatch = dispatch => ({
    ...dispatch.admin
})

export default connect(mapState, mapDispatch)(Admin);