import request from '../utils/request';
import url from '../utils/url';

//支付控制信息列表
export function list(params) {
    return request(url.payControlList, {
        method: 'GET',
        params
    });
}

//添加
export function del(params) {
    return request(url.delPayType, {
        method: 'POST',
        params
    });
}




export function status(params) {
    return request(url.updPayStatus, {
        method: 'POST',
        params
    });

}
//修改
export function upd(params) {
    return request(url.editPayType, {
        method: 'POST',
        params
    });
}




