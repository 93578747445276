import request from '../utils/request';
import url from '../utils/url';

//列表数据
export function list(params) {
    return request(url.memberFaceAuthList, {
        method: 'GET',
        params
    });
}
//修改状态
export function change(params) {
    return request(url.memberFaceAuthHandle, {
        method: 'POST',
        params
    });
}
