/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Col, Row, Space } from 'antd';
import {ProFormRadio, ProFormText, ProForm} from '@ant-design/pro-form';

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sellType:[
                {label:'vip',value:1},
                {label:'金币',value:2}
            ],
            visible: false,
        };
    }


    onFinish = (values) => {
        const {row} = this.props
        if(row&&row.id)
            values.id = row.id
  
            this.props.updateTVipRule(values).then(res => {
                this.props.list()
            })
     
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };


    render() {
        const {row}  = this.props
        const{sellType} = this.state
        return (
            <ProForm  grid={true} 
            layout='horizontal'
            initialValues={row?{
              type:row?row.type:1,
              name:row?row.name:'',
              price:row?row.price:'',
              unit:row?row.unit:'',
              originPrice:row?row.originPrice:'',
              remarks:row?row.remarks:'',
              params:row?row.params:'',
              stock:row?row.stock:''
             
  
            }:{}}
            submitter={{
                      render: (props, doms) => {
                        return (
                          <Row >
                            <Col  >
                              <Space>{doms}</Space>
                            </Col>
                          </Row>
                        ) ;
                      },
                  
                    }}
                    onFinish={ (v) => this.onFinish(v)}
                  >
                <ProFormRadio.Group
                      rules={[ {required: true, message: '请选择类型!' } ]}
                          name="type"
                          label="类型"
                          options={sellType}
                      />          
               <ProFormText  width="md" rules={[ {required: true, message: '请输入!' } ]}   name="name" label="销售名称" placeholder="请输销售名称 "/>
               <ProFormText  width="md" rules={[ {required: true, message: '请输入!' } ]}  name="price" label="销售价格" placeholder="售卖价格 "/>
               <ProFormText width="md" rules={[ {required: true, message: '请输入!' } ]}  name="originPrice" label="原始价格" placeholder="原始价格 "/>
               <ProFormText  width="md" rules={[ {required: true, message: '请输入!' } ]}  name="unit" label="时长/数量" placeholder="时长/数量 "  tooltip="销售单位 vip时长单位以天为计算 金币按参数数值 永久会员填写参数8888"/>
               <ProFormText  width="md" rules={[ {required: true, message: '请输入!' } ]}  name="stock" label="赠送金币" placeholder="赠送金币" />
               <ProFormText width="md"  rules={[ {required: false, message: '请输入!' } ]} tooltip="支付商提供的支付通道" name="params" label="支付通道" placeholder="支付通道"/>
               <ProFormText width="md" rules={[ {required: true, message: '请输入!' } ]}  name="remarks" label="商品描述" placeholder="商品描述 "/>
          </ProForm>
        )


    }


}

const mapState = state => ({
    ...state.vip

});

const mapDispatch = dispatch => ({
    ...dispatch.vip
})

export default connect(mapState, mapDispatch)(Add);