/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form} from 'antd';
import {ProFormText,ProFormUploadButton} from '@ant-design/pro-form';
import {removeEmptyField} from '../../compoent/emptyFild'

class AddRights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
        };
    }


    onFinish = (values) => {
        const {img} = this.props
        values.upload = '';
        values.icon = img;
        let params = removeEmptyField(values);
        if (params) {
            this.props.addRights(params).then(res => {
                this.props.rightsList()
            })
        }
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };


    render() {
        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      initialValues={{remember: true}}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      autoComplete="off"
                >
                    <ProFormText rules={[
                        {
                            required: true,
                            message: '请输入权益名称',
                        },
                    ]} width="md" name="rights" label="权益名称" placeholder="权益名称 "/>


                    <ProFormUploadButton
                        max={1} tooltip="限制1张图片 注意尺寸" width="md" name="upload" label="icon"
                        placeholder="图片"
                        fieldProps={{
                            name: 'file',
                            beforeUpload: async (file) => {
                                this.setState({loading: true})
                                this.props.upload({file: file, type: 'image'}).then(r => {
                                    if (r.code === 200)
                                        this.setState({loading: false})
                                })


                                return false
                            }
                        }}
                    />


                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="success" htmlType="reset">
                            重置
                        </Button>
                        <Button  type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        )


    }


}

const mapState = state => ({
    ...state.vip

});

const mapDispatch = dispatch => ({
    ...dispatch.vip
})

export default connect(mapState, mapDispatch)(AddRights);