import React, {Component} from "react";
import {connect} from 'react-redux';
import ProTable from '@ant-design/pro-table';
import Add from './AddProfitList'
import {Badge, Button, Modal, Popconfirm} from 'antd';

class ProfitList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                hideInSearch: true,
                width: 80,


            },
            {
                title: '用户名',
                align: 'center',
                copyable: true,
                ellipsis: true,
                dataIndex: 'name',
                key: 'name',
                hideInSearch: true,
                width: 80,
            },

            {
                title: '金额',
                align: 'money',
                dataIndex: 'money',
                hideInSearch: true,
                width: 80,

            },


            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <Popconfirm
                                title="确定操作？"
                                onConfirm={() => this.del(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a style={{marginLeft: 10, color: 'red'}}
                            >删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];


    }
    componentDidMount() {
       this.props.profitList();
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    };
    add = (row) => {
        this.setState({
            visible: true
        })
    }


    del = (record) => {
        this.props.delProfitList({id: record.id}).then(res => {
            this.props.profitList();

        })
    };


    render() {
        const {profitListData} = this.props;
        console.log('ppp',profitListData)

        return (
            <div>
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                        this.props.profitList()
                    }}
                    dataSource={profitListData}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"

                    revalidateOnFocus={true}
                    dateFormatter="string"
                    headerTitle="盈利榜单,自定义数据。 如果自定义数据不存在则使用真实数据"
                    toolBarRender={() => [
                        <Button key="add" type="primary" onClick={() => this.add()}>
                            添加
                        </Button>,

                    ]}
                />

                    <Modal destroyOnClose visible={this.state.visible}
                           footer={null}
                           onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} ></Add>
                    </Modal>

            </div>
        );

    }


}


const mapState = state => ({
    ...state.spread

});

const mapDispatch = dispatch => ({
    ...dispatch.spread
})

export default connect(mapState, mapDispatch)(ProfitList);