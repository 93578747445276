import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Image, message, Modal, Popconfirm, Table, Tooltip} from 'antd';
import ChangeOrderNum from './component/ChangeOrderNum'
import ApplyList from './component/ApplyList'
import CancelModal from './component/CancelModal'

export const DispatchOrderList = (props) => {
    const [current, setCurrent] = useState(1)
    const [visible, setVisible] = useState(false)
    const [cancels, setCancels] = useState(false)
    const [applyVisible, setApplyVisible] = useState(false);
    const [record, setRecord] = useState({})
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 60,
            align: 'center',

        },
        {
            title: '订单编号',
            dataIndex: 'orderId',
            width: 200,
            align: 'center',

        },
        {
            title: '派单人',
            dataIndex: 'dname',
            width: 200,
            align: 'center',
            render: (_, record) => {
                return (
                    <div>
                        {_}({record.dauto})
                    </div>
                );
            },

        },
        {
            title: '接单人',
            dataIndex: 'sname',
            width: 200,
            align: 'center',
            render: (_, record) => {
                if (_)
                    return (
                        <div>
                            {_}({record.sauto})
                        </div>);
                else
                    return (<div>-</div>)

            },

        },
        {
            title: '类型',
            dataIndex: 'category',
            width: 150,
            align: 'center',
        },
        {
            title: '定金',
            dataIndex: 'beforePrice',
            width: 150,
            align: 'center',
        },
        {
            title: '尾款',
            dataIndex: 'afterPrice',
            width: 150,
            align: 'center',
            render: (_, record) => {
                if (record.payStatus === '1')
                    return _;
                else
                    return '未支付'

            },
        },
        {
            title: '总价',
            dataIndex: 'price',
            width: 150,
            align: 'center',
        },


        {
            title: '派单内容',
            dataIndex: 'content',
            width: 200,
            align: 'center',
            render: content => (
                <Tooltip placement="topLeft" title={content}>
                    <div style={{
                        width: 100,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>
                        {content}
                    </div>
                </Tooltip>
            ),

        },
        {
            title: '图片',
            dataIndex: 'imgs',
            width: 300,
            align: 'center',
            render: (_, record) => (<div>
                {record.images ? record.images.map((i, k) => (
                    <Image style={{width: 40, height: 40, marginLeft: 10}} key={k} src={i}/>)) : ''}
            </div>)
        },

        {
            title: '排序',
            dataIndex: 'orderNum',
            width: 150,
            align: 'center',
            sorter: true,
            render: (_, record) => {
                return (
                    record.status === -1 ?
                        <a>{_}</a> :
                        <a onClick={() => changeOrderNum(record)}>{_}</a>
                )
            }
        },


        {
            title: '发布时间',
            dataIndex: 'addTime',
            width: 220,
            align: 'center',

        },
        {
            title: '支付时间',
            dataIndex: 'payTime',
            width: 150,
            align: 'center',

        },

        {
            title: '开始时间',
            dataIndex: 'beginTime',
            width: 150,
            align: 'center',

        }, {
            title: '订单状态',
            dataIndex: 'status',
            width: 150,
            align: 'center',
            render: (_, record) => {
                return (
                    <div>
                            <span
                                style={{color: record.status === 0 ? '' : record.status === 1 ? 'lime' : record.status === 2 ? 'blue' : record.status === 3 ? "red" : record.status === -2 ? 'darkgray' : 'orange'}}>
                                {record.status === 0 ? '待处理' : record.status === 1 ? '进行中' : record.status === 2 ? '已完成' : record.status === 4 ? '已取消' : record.status === -2 ? '已关闭' : '未知'}</span>
                    </div>
                )
            }

        },

        {
            title: '操作',
            align: 'center',
            fixed: 'right',
            width: 320,
            render: (_, record) => {
                return (record.audit === 0 ?

                        <div>

                            <a onClick={() => changeStatus(record, 1)}
                               style={{color: "green", marginRight: 20}}>通过</a>

                            <Popconfirm
                                title="不通过后 该订单会被关闭 "
                                onConfirm={() => changeStatus(record, -2)}
                                okText="确定"
                                cancelText="取消"
                            >
                                <a>不通过</a>
                            </Popconfirm>

                        </div>
                        :
                        <div>
                            {record.status === 0 || record.status === 1 ?
                                <a onClick={() => cancel(record)}>取消</a> : ''}
                            <a onClick={() => applyList(record)}
                               style={{marginLeft: 20}}>申请列表{record.applyCount === 0 ? '' : '(' + record.applyCount + ')'}</a>
                        </div>


                )
            }
        },
    ];

    useEffect(() => {
        props.dispatchList({page: 1, size: 10, status: 0, audit: props.audit})
    }, [])

    const handleCancel = () => {
        setVisible(false)
        setApplyVisible(false)
        setCancels(false)
    };


    const handleChangeOrderList = (current, pageSize) => {
        props.newValues.page = current
        props.newValues.size = 10
        props.newValues.audit = props.audit
        setCurrent(current)
        props.dispatchList({...props.newValues})
    }

    const pagination = {
        current: props.dispatchData.current,//当前页
        total: props.dispatchData.total,//总条数
        pageSize: props.dispatchData.size,//页大小
        onChange: handleChangeOrderList,
        showQuickJumper: true,//快速跳转
        showSizeChanger: false//大于50时显示pageSize切换器
    };

    const applyList = (record) => {
        if (record.applyCount > 0) {
            setApplyVisible(true)
            setRecord(record)
        }

    }

    const handleTableChange = (newPagination, filters, sorter) => {
        props.newValues.sort = sorter.order
        props.newValues.page = newPagination.current
        props.newValues.size = 10
        props.newValues.audit = props.audit;
        props.dispatchList({...props.newValues});
    };

    const changeOrderNum = (record) => {
        setVisible(true)
        setRecord(record)
    }

    const cancel = (record) => {
        setCancels(true)
        setRecord(record)
    }

    const changeStatus = (record, status) => {

        // const { orderPublishCancel } = this.props
        props.changeDispatchOrderStatus({id: record.id, status: status}).then(res => {
            // setStatus(false)
            message.success('成功')
            props.dispatchList({page: current, size: 10, status: 0, audit: props.audit})

        })
    }
    return (
        <div>
            <Table

                columns={columns}
                // loading={userLoading}
                dataSource={props.dispatchData.records}
                rowKey={record => record.id}
                pagination={pagination}

            />
            <Modal destroyOnClose visible={visible} footer={null} title={'数值越大排序越靠前'}
                   onCancel={() => handleCancel()} handleCancel={() => handleCancel()}>
                <ChangeOrderNum handleCancel={() => handleCancel()} record={record}
                                newValues={props.newValues}/>
            </Modal>
            <Modal destroyOnClose visible={applyVisible} footer={null} title={'派单用户申请列表'} width={1200}
                   onCancel={() => handleCancel()} handleCancel={() => handleCancel()}>
                <ApplyList handleCancel={() => handleCancel()} record={record}/>
            </Modal>

            <Modal destroyOnClose visible={cancels} footer={null} title={'取消订单'}
                   onCancel={() => handleCancel()} handleCancel={() => handleCancel()}>
                <CancelModal handleCancel={() => handleCancel()} record={record} newValues={props.newValues}/>
            </Modal>

        </div>
    )
}

const mapStateToProps = (state) => ({
    ...state.order

})

const mapDispatchToProps = (dispatch) => ({
    ...dispatch.order

})

export default connect(mapStateToProps, mapDispatchToProps)(DispatchOrderList)
