/* eslint-disable import/no-anonymous-default-export */
import {
    moments, status,batchUpdate,add,cdnRefresh,cdnCache
} from '../services/moments';
import {child} from '../services/momentsCommentApi';
import {img } from '../services/cateApi'
import {
    fileDel
} from '../services/MonitorApi';
export default {
    state: {
        momentsData: {},
        delDynamicData: {},
        childs:[]
    },
    effects: {
        async moments(params) {

            const response = await moments(params)
            if (response.code === 0) {

                this.saveMoments({
                    momentsData: response.data,

                })
            }
        },
        async child(params) {

            const response = await child(params)

            if (response.code === 0) {
                this.saveChild({
                    childs: response.data,

                })
            }
        },
        async img(params) {
           return await img(params)  
        },
        async update(params) {
            return await batchUpdate(params)

         },
         async cache(params) {
            return await cdnCache(params)

         },
         async refresh(params) {
            return await cdnRefresh(params)

         },
         async add(params) {
            return await add(params)
         },
   
         async fileDel(params) {
            return   await fileDel(params)
          },
        async changeStatus(params) {
          return await status(params)
         
        },
    },
    reducers: {
        saveMoments(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveDelDynamic(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveChild(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }
    }
}