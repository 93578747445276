import React from 'react';
import './App.less';
import Router from './router/index';
import {Provider} from 'react-redux';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {ConfigProvider, Spin} from 'antd';
import store from './redux/store';
import 'antd/dist/reset.css';
import load from './utils/loading/loading'


moment.locale('zh-cn');

export default class App extends React.Component {
    state = {
        loading:false
    };

    componentDidMount() {
        load.subscribe(() => {
            this.setState({
                loading: load.getState().loading
            })
        });
    }

    render() {
        let { loading } = this.state
        return (
            <Spin spinning={loading}>
                <Provider store={store}>
                    <ConfigProvider locale={zhCN}>
                        <Router/>
                    </ConfigProvider>
                </Provider>
            </Spin>
        )
    }
}
