import request from '../utils/request';
import url from '../utils/url';

//菜单信息列表
export function list(params) {
    return request(url.menuList, {
        method: 'POST',
        params
    });
}

//添加菜单
export function add(params) {
    return request(url.addMenu, {
        method: 'POST',
        params
    });
}

//修改菜单
export function upd(params) {
    return request(url.updMenu, {
        method: 'POST',
        params
    });
}

//shanchu菜单
export function del(params) {
    return request(url.delMenu, {
        method: 'POST',
        params
    });
}

//一级菜单
export function parent(params) {
    return request(url.parentMenuList, {
        method: 'POST',
        params
    });
}  //一级菜单
export function status(params) {
    return request(url.updMenuStatus, {
        method: 'POST',
        params
    });
}


