/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { Col, Row, Space, message } from 'antd';
import { ProFormSelect, ProFormText, ProForm } from '@ant-design/pro-form';
import { connect } from "react-redux";

class AddIp extends Component {
    state = {
        status: [
            { label: '正常', value: 1 },
            { label: '失效', value: 0 }
        ],

    };

    onFinish = (values) => {

        if (this.props.row && this.props.row.id)
            values.id = this.props.row.id

        if(this.props.rowType==1){
            //新增
            this.props.serviceIpAdd(values)
            .then(res => {
                if (res.code == 0) {
                    message.info('操作成功')
                    this.props.serviceList()
                    this.props.handleCancel()
                }
                else
                    message.info(res.message)
            })
        }else{

            this.props.serviceIpUpd(values)
            .then(res => {
                if (res.code == 0) {
                    message.info('操作成功')
                    this.props.serviceList()
                    this.props.handleCancel()
                }
                else
                    message.info(res.message)
            })
        }

    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { row,rowType } = this.props
       
        return (
            <ProForm
            initialValues={row&&rowType==0 ? {
                    
                    remark: row.remark,
                    status: row.status,
                    ip: row.ip

                } : { status: 1 }}
                layout='vertical'
                submitter={{
                    render: (props, doms) => {
                        return (
                            <Row>
                                <Col offset={16}>
                                    <Space>{doms}</Space>
                                </Col>
                            </Row>
                        );
                    },
                }}
                onFinish={(v) => this.onFinish(v)}
            >
                
            
                <ProFormText disabled={rowType==0} name="ip" label="IP" />
                <ProFormSelect rules={[{ required: true, message: '请选择状态!' }]} name="status" label="状态" placeholder="请选择状态" options={this.state.status} />
                <ProFormText name="remark" label="备注" />
            </ProForm>
        )
    }
}

const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(AddIp);