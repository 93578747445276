import React, {Component} from "react";
import {connect} from 'react-redux';
import ProTable from '@ant-design/pro-table';
import Add from './Add'
import {Badge, Button, Modal, Popconfirm} from 'antd';

class Banner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            type: '',
            row: {},
            parent: [],
            params: {}

        };
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                hideInSearch: true,
                width: 80,


            },
            {
                title: '标题',
                align: 'center',
              
                ellipsis: true,
                dataIndex: 'name',
                key: 'name',
                hideInSearch: false,
                width: 80,
                render: (_) => <a>{_}</a>,
            },
            {
                title: '类型',
                align: 'center',
                ellipsis: true,
                dataIndex: 'type',
                key: 'type',
                hideInSearch: false,
                width: 80,
                render: (_,row) => <a>{row.type==1?'视频':'漫画'}</a>,
            },

            {
                title: 'category',
                align: 'center',
                dataIndex: 'category',
                tip: '1-跳转类型h5 2-跳转类型 内部 只能有效一种 ',
                hideInSearch: true,
                width: 80,
                render: (_, record) => {
                    return (
                        <Badge
                            status={ 'success' }
                            text={record.category === '1'?'外链':'内部'}/>
                    );
                }

            },

            {
                title: 'html',
                align: 'center',
                ellipsis: true,
                copyable: true,
                hideInSearch: true,
                dataIndex: 'action',
                tip: 'h5跳转地址 不区分ios或android  category:1 该h5地址有效 category:2 app内部跳转路径有效 ',
                key: 'action',
                width: 100,
                render: (_, record) => {
                    return (
                            <a> <Badge
                                status={record.category === '1' ?'processing' : 'default'}
                                text={record.action}/></a>
                    );
                }
            },
            {
                title: 'path',
                align: 'center',
                ellipsis: true,
                copyable: true,
                hideInSearch: true,
                dataIndex: 'params',
                key: 'path',
                tip: '内部跳转路径  category:2 路径有效',
                width: 100,
                render: (_, record) => {
                    return (
                        <a> <Badge
                            status={record.category === '2' ? 'processing' : 'default'}
                            text={record.params}/></a>
                    );
                }
            }, 

            {
                title: '展示图片',
                dataIndex: 'imageUrl',
                align: 'center',
                key: 'image',
                hideInSearch: true,
                valueType: 'image',
                width: 80,
            },
         

            {
                title: '创建时间',
                dataIndex: 'addTime',
                align: 'center',
                hideInSearch: true,
                width: 80,
            },

            {
                title: '操作',
                align: 'center',
                width: 100,
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.upd(record)} style={{marginLeft: 2}}>编辑</a>
                            <Popconfirm
                                title="确定操作？"
                                onConfirm={() => this.status(record)}
                                okText="确定"
                                cancelText="取消"
                            > <a style={{marginLeft: 10, color: 'red'}}
                            >删除</a>
                            </Popconfirm>

                        </div>
                    )
                }
            }

        ];


    }


    handleCancel = () => {
        this.props.clear();
        this.setState({
            visible: false
        })
    };
    add = (row) => {
        this.setState({
            visible: true,
            type: '0',
            row: row
        })
    }
    upd = (record) => {
        this.setState({visible: true, row: record, type: '1'})
    }
    del = (record) => {
        this.props.del({id: record.id}).then(res => {
            this.props.list();

        })
    };
    status = (record) => {
        this.props.status({id: record.id, status: 0}).then(res => {
            this.props.list();

        })
    };

    render() {
        const {row, type} = this.state;
        const {data} = this.props;

        return (
            <div>
                <ProTable
                    columns={this.columns}
                    request={(params, s, f) => {
                        this.setState({params})
                        this.props.list(params)
                    }}
                    dataSource={data}
                    columnsState={{
                        persistenceKey: 'pro-table-singe-demos',
                        persistenceType: 'localStorage',
                    }}
                    rowKey="id"
                    search={false}

                    pagination={{
                        pageSize: 50,
                    }}
                    revalidateOnFocus={true}
                    dateFormatter="string"
                    headerTitle="banner列表"
                    toolBarRender={() => [
                        <Button key="create" type="primary" onClick={() => this.add()}>
                            添加
                        </Button>,

                    ]}
                />

                    <Modal destroyOnClose open={this.state.visible} title={type === '0' ? '新增' : '编辑'}
                           footer={null}
                           onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                        <Add handleCancel={this.handleCancel} record={row} type={type}></Add>
                    </Modal>

            </div>
        );

    }


}


const mapState = state => ({
    ...state.banner

});

const mapDispatch = dispatch => ({
    ...dispatch.banner
})

export default connect(mapState, mapDispatch)(Banner);