import React, {PureComponent} from "react";
import {Layout, Menu} from "antd";
import permitData from "../../router/permit";
import {Link, withRouter} from "react-router-dom";
import "./sideMenu.less";
import {
    CarOutlined,
    ClearOutlined,
    LineChartOutlined,
    ProfileOutlined,
    SettingOutlined,
    ShopOutlined,
    ShoppingOutlined,
    SolutionOutlined,
    UserOutlined
} from "@ant-design/icons";

const {Sider} = Layout;
const {SubMenu} = Menu;

const iconArr = [
    "",
    CarOutlined,
    ShopOutlined,
    UserOutlined,
    ShoppingOutlined,
    ProfileOutlined,
    SettingOutlined,
    ClearOutlined,
    SolutionOutlined,
    LineChartOutlined
];


class SildeMenu extends PureComponent {
    state = {
        openKeys: [""],
        rootSubmenuKeys: [],
        permit: {}
    };

    componentDidMount() {
        const permiss = JSON.parse(localStorage.getItem("permit")) ? JSON.parse(localStorage.getItem("permit")) : []
        const permit = permitData(permiss)
        const {rootSubmenuKeys} = this.state;
        //
        permit.menu.forEach(item => {
            if (item.children && item.permission) {
                rootSubmenuKeys.push(item.path);
            }
        });
        this.setState({permit, rootSubmenuKeys, openKeys: [rootSubmenuKeys[0]]});
    }

    //渲染菜单111
    renderMenu = (data, permissions) => {
        //
        return data.map(item => {
            //判断是否有子集
            if (item.children) {
                if (item.permission) {
                    let Icon = iconArr[item.icon];
                    return (
                        <SubMenu
                            icon={<Icon/>}
                            className="sub-menu"
                            key={item.path}
                            title={item.title}
                        >
                            {this.renderMenu(item.children, permissions)}
                        </SubMenu>
                    );
                }
            } else {

                //
                let Icon = item.icon ? iconArr[item.icon] : "";
                return (
                    <Menu.Item key={item.path} icon={item.icon ? <Icon/> : ""}>
                        <Link to={item.path}>{item.title}</Link>
                    </Menu.Item>
                );

            }

            return false; //解决eslint警告
        });
    };

    onOpenChange = keys => {
        const {openKeys, rootSubmenuKeys} = this.state;
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({openKeys: keys});
        } else {
            this.setState({openKeys: latestOpenKey ? [latestOpenKey] : []});
        }
    };

    render() {

        const {openKeys, permit} = this.state;
        const menuSelected = this.props.history.location.pathname;
        const menuOpened = `/${menuSelected.split("/")[1]}`;
        const permissions = JSON.parse(localStorage.getItem("permissions"))
            ? JSON.parse(localStorage.getItem("permissions"))
            : [];
        let permits = [];
        if (!permit.menu) {
            const permiss = JSON.parse(localStorage.getItem("permit")) ? JSON.parse(localStorage.getItem("permit")) : []
            permits = permitData(permiss)
        } else
            permits = permit
        return (
            <Sider style={{
                overflow: 'auto',
                zIndex:99,
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }} trigger={null} className="sider-wrap">
                <div className="slider-header">
                   
                    <div className='slider-header-title'>SmallYBook</div>
                </div>

                <Menu
                    mode="inline"
                    // style={{ color: "pink" }}
                    defaultOpenKeys={[menuOpened]}
                    defaultSelectedKeys={[menuSelected]}
                    selectedKeys={[menuSelected]}
                    className="menu-wrap"
                    theme="dark"
                    openKeys={openKeys}
                    onOpenChange={this.onOpenChange}

                >
                    {this.renderMenu(permits.menu, permissions)}
                </Menu>
            </Sider>
        );
    }
}

export default withRouter(SildeMenu);



