/* eslint-disable import/no-anonymous-default-export */
import { add, del, list, status, upd, upload } from '../services/bannerApi';


export default {
    state: {
        data: [],
        img: ''
    },
    effects: {
        async list(params) {
            const response = await list(params)
            if (response.code === 0) {
              //  let key = 'https://prod.smybook.com' + response.secret
                let word = response.data;
               // console.log('data',word)
                // let secretKey = CryptoJS.enc.Utf8.parse(key);
                // let decrypt = CryptoJS.AES.decrypt(word, secretKey, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
                // let data =  CryptoJS.enc.Utf8.stringify(decrypt).toString();
              // let data =  window.atob(word)
                
              //  let obj = JSON.parse (data);
              //  console.log('realData', data)
                this.saveData({
                    data: word,
                    img: ''

                })
            }
            return response.data
        },
        async add(params) {
            await add(params)
        },
        async del(params) {
            await del(params)
        },
        async status(params) {
            await status(params)
        },
        async upd(params) {
            await upd(params)
        },
        async upload(params) {
            const response = await upload(params)
            if (response.code === 0) {

                this.saveImg({
                    img: response.data
                })

            }
            return response
        },
    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode,

            }
        },
        saveImg(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        clear(state, payload) {
            return {
                ...state,
                img: '',
            }
        },
    }
}