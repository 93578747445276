import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table, Modal, Popconfirm, message } from 'antd';
import UpdateAdmin from './component/updateAdmin';

class AdminList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
            updateVisible: false,
            record: {}
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',

            },
            {
                title: '用户名',
                dataIndex: 'loginName',

            },
            // {
            //     title: '昵称',
            //     dataIndex: 'name',

            // },
            {
                title: '角色',
                dataIndex: 'roleId',
                render: (_, record) => {
                    return (
                        <div>
                            {record.roleName ? record.roleName : ''}
                        </div>
                    )
                }

            },
            {
                title: '状态',
                dataIndex: 'status',
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.handleChange(record)}>{record.status === 0 ? '启用' : record.status === 1 ? "禁用" : ''}</a>
                        </div>
                    )
                }
            },
            {
                title: '添加时间',
                dataIndex: 'addTime',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
            },
            {
                title: '操作',
                // dataIndex: 'companyCommission',
                render: (_, record) => {

                    return (
                        <div>
                            {/* <a onClick={() => this.updateAdmin(record)}>编辑</a> */}
                            {
                                record.id === '1' ? <span style={{ color: 'gray' }}></span> : <a onClick={() => this.updateAdmin(record)}>编辑</a>

                            }
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.delAdmin(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                {
                                    record.id === '1' ? <span style={{ color: 'gray', marginLeft: 30 }}></span> : <a style={{ marginLeft: 30 }}>删除</a>

                                }
                            </Popconfirm>
                        </div >
                    )
                }
            },


        ];
    }
    componentDidMount() {
        this.init();
    }

    init = () => {
        const { adminListPage } = this.props
        adminListPage({ page: 1, size: 10 })
    }

    handleChangeAdminist = (current, pageSize) => {
        const { newValues } = this.props
        //
        newValues.page = current
        this.props.adminListPage({ ...newValues })
    }
    updateAdmin = (record) => {

        this.setState({
            updatevisible: true,
            record
        })
    }
    delAdmin = (record) => {
        const { delAdmin } = this.props
        delAdmin({ id: record.id }).then(res => {
            message.success('删除成功')
            this.props.adminListPage({ page: 1, size: 10 })
        })
    }
    handleCancel = () => {
        this.setState({
            updatevisible: false,
        })

    }
    handleChange = (record) => {
        if (record.status === 0) {
            const { updateStatus } = this.props
            updateStatus({ id: record.id, status: 1 }).then(res => {
                this.props.adminListPage({ page: 1, size: 10 })
            })
        } else if (record.status === 1) {
            const { updateStatus } = this.props
            updateStatus({ id: record.id, status: 0 }).then(res => {
                this.props.adminListPage({ page: 1, size: 10 })
            })
        }

    }
    render() {
        const { data, userTotal, userLoading } = this.props;
        const { pageNum, pageSize, updatevisible, record } = this.state;

        const pagination = {
            current: data.current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeAdminist,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns}
                    loading={userLoading} dataSource={data.records} rowKey={record => record.id} pagination={pagination}
                />
                <div>
                    <Modal destroyOnClose visible={updatevisible} title='编辑' footer={null} onCancel={this.handleCancel} >
                        <UpdateAdmin record={record} handleCancel={this.handleCancel}></UpdateAdmin>
                    </Modal>
                </div>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.admin
});

const mapDispatch = dispatch => ({
    ...dispatch.admin

})

export default connect(mapState, mapDispatch)(AdminList);