import request from '../utils/request';
import url from '../utils/url';

//信息列表
export function list(params) {
    return request(url.systemValueList, {
        method: 'POST',
        params
    });
}



//修改信息
export function upd(params) {
    return request(url.systemValueUpd, {
        method: 'POST',
        params
    });
}

