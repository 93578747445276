import React, {Component} from 'react'
import CommentList from './commentList'
import style from './comment.module.scss'
import {connect} from "react-redux";
import {removeEmptyField} from '../../compoent/emptyFild'
import moment from 'moment';
import {Button, DatePicker, Form, Input} from 'antd';

class comment extends Component {
    state = {
        newValues:{},
        current:1
    }
    onFinish = (values) => {
        const beginTime = values.beginTime
        values.beginTime = beginTime ? moment(beginTime[0]).format('YYYY-MM-DD') : ''
        values.endTime = beginTime ? moment(beginTime[1]).format('YYYY-MM-DD') : ''

        if (values) {
            values.page = 1
            values.size = 10
            let newValues = removeEmptyField(values)
            this.setState({ newValues: newValues })
            this.props.commentList(newValues)
        } else {
            this.props.commentList()
        }

    };
    onFinishFailed = (errorInfo) => {

    };

    render() {
        const {RangePicker} = DatePicker;

        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{marginTop: 20, marginLeft: 20}}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{width: 320}}
                            label="发单编号："
                            name="orderId"
                        >
                            <Input placeholder='输入订单编号'/>
                        </Form.Item>

                        <Form.Item
                            style={{width: 320}}
                            label="评论用户："
                            name="memberName"
                        >
                            <Input placeholder='输入评论用户昵称'/>
                        </Form.Item>

                        <Form.Item
                            style={{width: 320}}
                            label="评论内容："
                            name="content"
                        >
                            <Input placeholder='输入评论内容查询'/>
                        </Form.Item>


                        <Form.Item
                            style={{marginTop: 0}}
                            label="开始时间"
                            name="beginTime"
                        >
                            <RangePicker
                                ranges={{
                                    // Today: [moment(), moment()],
                                    '本周': [moment().startOf('week'), moment().endOf('week')],
                                    '本月': [moment().startOf('month'), moment().endOf('month')],
                                }}/>


                        </Form.Item>
                        <Form.Item style={{marginLeft: 0, marginTop: 0}}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{marginLeft: 30}}>
                                重置
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <CommentList newValues = {this.state.newValues}/>
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.orderComment

});

const mapDispatch = dispatch => ({
    ...dispatch.orderComment
})

export default connect(mapState, mapDispatch)(comment);