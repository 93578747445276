/* eslint-disable import/no-anonymous-default-export */
import {
  login, getAdminMenuList, updatePassword
} from '../services/loginApi';

export default {
  state: {
    userInfo: {},
    logoutState: {},
    getAdminMenuListData: {},
    updatePasswordData: {}
  },
  effects: {
    //登录
    async login(params) {
      const response = await login(params);
      return response;
    },
    //权限列表
    async getAdminMenuList(params) {

      const response = await getAdminMenuList(params);
      return response
    },
    // //退出
    // async logout() {
    //   const response = await logout();
    //   return response;
    // },
    //修改密码
    async updatePassword(params) {
      const response = await updatePassword(params);
      return response
    }
  },
  reducers: {
    saveUserinfo(state, payload) {
      return {
        ...state,
        ...payload

      }
    },
    saveGetAdminMenuList(state, payload) {
      return {
        ...state,
        ...payload
      }
    },
    // saveUpdatePassword(state, payload) {
    //   return {
    //     ...state,
    //     ...payload
    //   }
    // },
    //   logoutResult(state, payload) {
    //     return {
    //       ...state,
    //       logoutState: payload
    //     }
    //   },
    //   changeHeadImg(state, payload) {
    //     return {
    //       ...state,
    //       headImg: payload
    //     }
    //   }
  }

}