import React, { Component } from 'react'
import { Table,Badge } from 'antd';

import { connect } from "react-redux";

class ShowUserAcc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            newValues: {},
   
        }


        this.columns = [
            {
                title: '订单编号',
                dataIndex: 'tradeNo',
            },
          
            {
                title: '名称',
                dataIndex: 'name',
            },

            {
                title: '充值类型',
                dataIndex: 'type',
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_=== 1 ? 'success':_=== 0? 'error': 'default'}
                            text={_=== 1 ? 'vip':_=== 2? '金币': '暂无设置'}/>
                    </a>

                 )
            },
            {
                title: '金额',
                dataIndex: 'price',
                
            },
            {
                title: '支付渠道',
                dataIndex: 'remarks',
                render: (_, record) => (
                    <a>
                        {'('+record.payMch+')'+record.mchName}
                    </a>

                 )
                
            },

            {
                title: '收款账户',
                dataIndex: 'mchCode',
                render: (_, record) => (
                    <a>
                        {_}
                    </a>

                 )
                
            },
            {
                title: '支付IP',
                dataIndex: 'payIp',
            },
            {
                title: '支付域名',
                dataIndex: 'payDomain',
            },
            {
                title: '支付时间',
                dataIndex: 'payTime',
            },
         
            

        ];
    }
  


    onFinishFailed = (errorInfo) => {

    };
    componentDidMount() {
        this.init()
    }
    init = () => {
        const { accBalanceLog } = this.props
        accBalanceLog({  id: this.props.record.id })
    }

  


    handleCancel = () => {
        this.setState({
            visible: false,
        })

    }
    render() {
        const { balanceData } = this.props;
   
     
        return (
            <div>
                
                <Table columns={this.columns} dataSource={balanceData.records} rowKey={record => record.id} 
                />


            </div>
        );
    }
}
const mapState = state => ({
    ...state.users

});

const mapDispatch = dispatch => ({
    ...dispatch.users
})

export default connect(mapState, mapDispatch)(ShowUserAcc);