/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Button, Upload, message } from 'antd';
import { connect } from "react-redux";
import InputNumber from 'rc-input-number';

class ChangeLevel extends Component {
    state = {
        // imageUrl: {},
        fileList: [],
        loading: false,
        picData: '',
        img: ''

    };
    componentDidMount() {
        // const { roleList } = this.props
        // roleList()
    }
    onFinish = (values) => {
        const { type } = this.props
        if (type === '0') {

            if (values) {

                values.icon = this.props.imgData
                // const newValues = removeEmptyField(values)
                this.props.addMemberLevel(values).then(res => {
                    message.success('新增成功')
                    this.props.memberLevelList({ page: 1, size: 10, })
                })
            }
            this.props.handleCancel()
            this.props.clear()
        }
        else if (type === '1') {
            //编辑接口

            if (values) {
                values.icon = this.props.imgData
                values.id = this.props.record.id
                //
                // const newValues = removeEmptyField(values)
                this.props.updateMemberLevel(values).then(res => {
                    this.props.memberLevelList({ page: 1, size: 10, type: 'image' })
                })
            }
            this.props.clear()
            this.props.handleCancel()
        }
    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { record} = this.props
        const {  fileList,loading } = this.state;

        const propsFile = {
            listType: "picture",
           // accept: ".png, .jpg, .jpeg",
            onRemove: file => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                this.setState({ fileList: newFileList })
            },
            beforeUpload: file => {
                const r = new FileReader();
                r.readAsDataURL(file);
                r.onload = e => {
                    file.thumbUrl = e.target.result;
                    this.setState({ fileList: [...fileList, file],loading:true })
                    this.props.imgs({ file: file, type: 'image' }).then(r=>{
                        if (r.code ===200) {
                            this.setState({loading:false })
                        }
                    })
                }

                return false;
            },
            fileList
        };

        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        name: record ? record.name : '',
                        mixCondition: record ? record.mixCondition ? record.mixCondition : record.mixCondition === '0' ? '0' : record.mixCondition : '',
                        maxConfition: record ? record.maxConfition : '',
                       // icon: record ? record.icon : '',
                        mLevel:record?record.mlevel:''
                    }}
                >


                    <Form.Item
                        label="等级"
                        name="mLevel"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="昵称"
                        name="name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="最小接单"
                        name="mixCondition"
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="最大接单"
                        name="maxConfition"
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="icon" label="图标">
                        <Upload {...propsFile} maxCount={1}>
                            <Button icon={<UploadOutlined />}>上传图标</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>

                        <Button type="success" htmlType="reset" >
                            重置
                        </Button>

                        <Button  loading={loading} style={{ marginLeft: 30 }} type="primary" htmlType="submit" >
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.level

});

const mapDispatch = dispatch => ({
    ...dispatch.level
})

export default connect(mapState, mapDispatch)(ChangeLevel);