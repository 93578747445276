import request from '../utils/request';
import url from '../utils/url';

//评论列表
export function commentList(params) {
    return request(url.commentListPage, {
        method: 'POST',
        params
    });
}
//删除评论
export function del(params) {
    return request(url.delComment, {
        method: 'POST',
        params
    });
}
