import React, {Component} from 'react'

import {connect} from "react-redux";
import {Badge, Button, Modal,message } from 'antd';
import ProTable from '@ant-design/pro-table';
import Add from './Add'

class Comment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            row: null,
            selectedRowKeys:[],
            loading:false,
            type:'sp',
        
        };

        this.columns = [
            {
                title: '视频资源',
                dataIndex: 'name',
                align: 'center',
                hideInSearch: true,
                copyable: true,
                width:300
            },
            {
                title: '视频大小',
                dataIndex: 'g',
                align: 'center',
                hideInSearch: true
              
                
            },
            {
                title: '上传时间',
                dataIndex: 'time',
                align: 'center',
                hideInSearch: true
              
                
            },
            {
                title: '状态',
                align: 'center',

                tip: '请勿提交队列中或同步中的视频进行转码',
                dataIndex: 'value',
                hideInSearch: true,//processing' : 'default'
                render: (_, record) => (
                    <a>
                        <Badge
                            status={_=== "3" ? 'processing':_=== "0"? 'default':_=== "1"? 'success': 'error'}
                            text={_=== "3" ? '队列中':_=== "0"? '待同步':_=== "1"? '同步中': '失败'}/>
                    </a>

                 )
            },

            {
                title: '操作',
                align: 'center',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                    <div>
                        <Button  disabled={this.state.selectedRowKeys&&this.state.selectedRowKeys.length>0||record.value=="1" ||record.value=="4"} type="primary" onClick={() => this.action(record)} >转码</Button>
                    <Button style={{marginLeft:10}} disabled={record.value == "1"} type="primary" danger onClick={() => this.del(record)} >删除</Button> 
                    </div>
                    )
                }
            }

        ];


    }

    componentDidMount() {
       // this.props.sourceList()
       

    }

  

    handleCancel = () => {
        this.setState({
            visible: false,
            row:null,
            selectedRowKeys:[]
        })
    };


    sync = (row) => {
        this.setState({loading:true})
        this.props.syncSort().then(res => {
            if (res.code===0) {
                message.info('同步成功');
            }

            this.setState({loading:false});
            this.props.sourceList();

        })
    }
    cleaner = () => {
        this.setState({ loading: true })
        this.props.sourceClean({type:'dsp'}).then(res => {
            if (res.code === 0) {
                message.info('缓存已清空 请重新同步');
            }

            this.setState({ loading: false });
            this.props.sourceList();

        })
    }

    action = (record) => {
        this.setState({visible: true, row: record})
    }
    parse = (record) => {
        this.setState({
            visible: true,
            type: '0',
            row: record
        })
    }
    del = (record) => {
        if(record.value=="1")
            return 
       this.props.sourceDel({path:record.name})
       .then(res=>{
         if(res.code==0){
            this.props.sourceList();

         }
       }
       )
    }

    start = () => {
        this.setState({loading:true,visible: true, type: '0',})
        setTimeout(() => {
            this.setState({loading:false});
            
        }, 1000);
    };

    onSelectChange = (newSelectedRowKeys,row) => {
        console.log('row',row)
        let arr=[]
         row.map(item=>{
            if(item.value!=="1")
              arr.push(item.name)
        })
      
        this.setState({selectedRowKeys: arr})
    };


    render() {
        const {row, type,selectedRowKeys,loading} = this.state;
        const { sources,tags} = this.props;
        const rowSelection = {selectedRowKeys, onChange: this.onSelectChange,}
        const hasSelected = selectedRowKeys.length > 0;
     
        return (
            <div>
                <div
                    style={{
                        marginBottom: 1,
                    }}
                >
                  

                </div>
                <ProTable
                columns={this.columns}
                request={(params, s, f) => {
                    this.props.sourceList(params)
                }}
                rowSelection={rowSelection}
                dataSource={sources}
                columnsState={{
                    persistenceKey: 'pro-table-singe-demos',
                    persistenceType: 'localStorage',
                }}
                rowKey="name"

                pagination={{
                    pageSize: 50,
                }}
                search={false}
                revalidateOnFocus={true}
                dateFormatter="string"
                headerTitle="视频文件路径"
                toolBarRender={() => [
                    <Button style={{marginRight:10}} key="creat" type="primary" danger onClick={() => this.cleaner()} loading={loading}>
                    清空
                 </Button>,
                    
                    <Button type="primary" onClick={() => this.start()} disabled={!hasSelected} loading={loading}>
                    视频转码
                </Button>,
                    <Button key="creat" type="primary" danger onClick={() => this.sync()} loading={loading}>
                       同步
                    </Button>

                ]}
            />
                <Modal destroyOnClose open={this.state.visible} title={type === '0' ? '视频转码' : '视频转码'}
                       footer={null}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} tags={tags} row={row} paths={selectedRowKeys}></Add>
                </Modal>
            </div>
        );

    }
}

const mapState = state => ({
    ...state.feedback

});

const mapDispatch = dispatch => ({
    ...dispatch.feedback
})

export default connect(mapState, mapDispatch)(Comment);