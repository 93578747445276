/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Col, Row, Space } from 'antd';

import { ProFormText, ProForm, ProFormSelect } from '@ant-design/pro-form';

class Add extends Component {
  constructor(props) {
    super(props);

  }

  onFinish = (e) => {
    const { type, row } = this.props
    if (type === '1' && row)
      e.id = row.id
    this.props.channelUpd(e).then(res => {
      this.props.domainListAll()
      this.props.channelList()
    })
    this.props.handleCancel()

  };


  render() {
    const { row, domainAll, domainAcc } = this.props

    return (

      <ProForm grid={true}

        initialValues={row ? {
          items: row ? row.itemIds : null,
          name: row ? row.name : '',

          link: row ? row.link : ''
        } : {}}
        submitter={{
          // resetButtonProps: {
          //   style: {
          //     // 隐藏重置按钮
          //     display: 'none',
          //   },
          // },
          render: (props, doms) => {
            return (
              <Row >
                <Col  >
                  <Space>{doms}</Space>
                </Col>
              </Row>
            );
          },

        }}
        onFinish={(v) => this.onFinish(v)}
      >
        <ProFormSelect disabled={row} rules={[{ required: true, message: '至少选择一条路线!' }]}
          name="items" label="线路" placeholder="至少选择一条路线" mode={row ? 'multiple' : 'single'} options={domainAll}
          fieldProps={{
            optionItemRender(item) {
                return item.label + ' —— ' + item.desc;
            },
        }}
        />
        <ProFormText rules={[{ required: true, message: '请输入渠道名称!' }]} name="name" label="渠道名称" placeholder="请输入渠道名称 " />

        {/* <ProFormText rules={[{ required: true, message: '请输入渠道标识' }]} name="remark" label="渠道标识" placeholder="请输入渠道标识" /> */}

        <ProFormSelect
          name="link" label="访问域名" placeholder="可以为空 也可单独选择 也可批量设置" options={domainAcc} />


      </ProForm>

    )


  }


}

const mapState = state => ({
  ...state.pay

});

const mapDispatch = dispatch => ({
  ...dispatch.pay
})

export default connect(mapState, mapDispatch)(Add);