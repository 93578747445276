/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { Form, Input, Button, Select } from 'antd';
import { connect } from "react-redux";
import { removeEmptyField } from '../../compoent/emptyFild';

const { Option } = Select;

class AddInfo extends Component {
    state = {
        loading: false,
        picData: '',
        type: [
            {
                id: '1',
                name: '系统消息'
            },
            {
                id: '2',
                name: '其他'
            },
        ]
    };

    onFinish = (values) => {

        const { type } = this.props
        if (type === '0') {
            if (values) {
                values.page = 1
                values.size = 10
                const newValues = removeEmptyField(values)
                this.props.createInfo(newValues).then(res => {
                    this.props.msgList({ page: 1, size: 10 })
                })
            }
            this.props.handleCancel()
        } else if (type === '1') {
            //编辑接口
        }

    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { record } = this.props
        const { loading, imageUrl, type } = this.state;

        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        type: record ? (record.type === 1 ? '系统消息' : '其他') : '',
                        body: record ? record.content : ''
                    }}

                >
                    <Form.Item
                        style={{ width: 250, marginLeft: 55 }}
                        label="类型:"
                        name="type"
                    >
                        <Select
                            showSearch
                            placeholder='请选择订单类型'

                        >
                            {type.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="内容:"
                        name="body"
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                        <Button type="primary" htmlType="reset" style={{ marginLeft: 30 }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.systemInfo

});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo
})

export default connect(mapState, mapDispatch)(AddInfo);