import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Image, message, Popconfirm, Table} from 'antd';

class RecordList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            newValues:{}
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',

            },

            {
                title: '发送人',
                dataIndex: 'sender',

            },
            {
                title: '接收人',
                dataIndex: 'receiver',

            },
            {
                title: '内容',
                dataIndex: 'msgContent',
                render: (_, record) => (<div>
                    {record.msgType === 'TIMImageElem' ?
                        <Image style={{width: 60, height: 60}} key={_} src={_}/> : _}
                </div>)

            },
            {
                title: '消息时间',
                dataIndex: 'time',

            },
            {
                title: '会话Id',
                dataIndex: 'sessionKey',

            },
            {
                title: '消息id',
                dataIndex: 'msgKey',

            },


            {
                title: '操作',
                dataIndex: 'status',
                render: (_, record) => {
                    return (
                        <div>
                            {_ === -1 ? <Popconfirm
                                    title="不通过后 该订单会被关闭 "

                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <a>禁言</a>
                                </Popconfirm>
                                : '-'}
                        </div>
                    )
                }
            },


        ];
    }

    componentDidMount() {

        this.props.chatRecordList({pageNo: 1, pageSize: 20, status: this.props.status})
    }


    onSelectChange = (newSelectedRowKeys) => {
        this.setState({selectedRowKeys: newSelectedRowKeys})
    };




    reject = (e) => {
        const ids = this.state.selectedRowKeys;
        if (ids.length>0){
            const params = this.state.newValues;
            params.status=this.props.status;
            this.props.changeChatRecordStatus({ids:ids,status:-1})
                .then(r=>{
                    this.setState({selectedRowKeys:[]})
                    this.props.chatRecordList(params)
                })
        }


    }

    pass = (e) => {
        const ids = this.state.selectedRowKeys;
        if (ids.length>0){
            const params = this.state.newValues;
            params.status=this.props.status;
            this.props.changeChatRecordStatus({ids:ids,status:1})
                .then(r=>{
                    this.setState({selectedRowKeys:[]})
                    this.props.chatRecordList(params)
                })
        }
    }

    handleChangeLabelList = (current, pageSize) => {

       let params =  this.props.newValues;
       params.pageNo=current;
       params.pageSize=pageSize;
       params.status= this.props.status;
       this.setState({newValues:params})
        this.props.chatRecordList(params)
    }


    confirm(record) {
        // const {delMessage} = this.props
        // delMessage({id: record.id}).then(res => {
        //         message.success('删除成功');
        //         this.props.msgList({pageNo: 1, pageSize: 10})
        //
        //     }
        // )
    }


    render() {
        const {chatRecordData,status} = this.props;
        const {selectedRowKeys} = this.state;
        const rowSelection = {selectedRowKeys, onChange: this.onSelectChange};
        const hasSelected = selectedRowKeys.length > 0;
        const pagination = {
            current: chatRecordData.current,//当前页
            total: chatRecordData.total,//总条数
            pageSize: chatRecordData.size,//页大小
            onChange: this.handleChangeLabelList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: true//大于50时显示pageSize切换器
        };
        return (
            <div>
                {status===0?
                    <div>
                        <Button  type="primary" danger onClick={this.reject} disabled={!hasSelected}>
                            违规
                        </Button>

                        <Button style={{marginLeft: 20}} type="primary"  onClick={this.pass}
                                disabled={!hasSelected}>
                            通过
                        </Button>

                        <span
                            style={{
                                marginLeft: 8,
                            }}
                        >
          {hasSelected ? `已选 ${selectedRowKeys.length} 行` : ''}
        </span>

                    </div>
                    :''}
                <Table columns={this.columns} dataSource={chatRecordData.records} rowKey={record => record.id}
                       pagination={pagination} rowSelection={rowSelection}
                />

            </div>

        );
    }
}


const mapState = state => ({
    ...state.systemInfo
});

const mapDispatch = dispatch => ({
    ...dispatch.systemInfo

})

export default connect(mapState, mapDispatch)(RecordList);