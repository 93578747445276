/* eslint-disable import/no-anonymous-default-export */
import {
    domainList,
    changeChatRecordStatus,
    chatRecordList,
    createInfo,
    delMessage,
    filterData,
    msgList,
    updDomain,
    updateFilterData,
    spreadUpdate,
    spreadList,
    syncDomain,
    serviceList,
    serviceAddOrUpd, serviceIpAdd, serviceIpUpd,
    domainParse,
    dnsUpd,
    dnsDel,
    serviceImport
} from "../services/systemInfoApi";

export default {
    state: {
        data: [],
        createData: [],
        chatRecordData: [],
        domain: {},
        filterData: '',
        spreadData: [],
        serviceLists: []

    },
    effects: {
        async msgList(params) {
            const response = await msgList(params)

            if (response.code === 200) {
                this.saveMsgList({
                    data: response.data
                })
            }
        },
        async domainList(params) {
            const response = await domainList(params)

            if (response.code === 0) {
                this.saveDomainList({
                    domain: response.data
                })
            }
        },
        async spreadList() {
            const response = await spreadList()

            if (response.code === 0) {
                this.saveScale({
                    spreadData: response.data
                })
            }
        },
        async serviceList() {
            const response = await serviceList()

            if (response.code === 0) {
                this.saveServiceList({
                    serviceLists: response.data
                })
            }
        },

        async filterDataList() {
            const response = await filterData()

            if (response.code === 200) {
                this.saveFilter({
                    filterData: response.data
                })
            }
        },

        async updateFilter(params) {
            return await updateFilterData(params)
        },
        async serviceImport(params) {
            return await serviceImport(params)
        },
        async syncDomain(params) {
            return await syncDomain(params)

        },
        async spredadUpdate(params) {
            return await spreadUpdate(params)

        },
        async domainUpd(params) {
            return await updDomain(params)

        },
        async serviceAddOrUpd(params) {
            return await serviceAddOrUpd(params)

        },
        async serviceIpAdd(params) {
            return await serviceIpAdd(params)

        },

        async domainParse(params) {
            return await domainParse(params)

        },
        async dnsUpd(params) {
            return await dnsUpd(params)

        },
        async dnsDel(params) {
            return await dnsDel(params)

        },
        async serviceIpUpd(params) {
            return await serviceIpUpd(params)

        },

        async createInfo(params) {
            const response = await createInfo(params)

            if (response.code === 200) {
                this.saveCreate({
                    createData: response.data
                })
            }
        },
        async delMessage(params) {
            const response = await delMessage(params)

            if (response.code === 200) {
                this.saveDelMessage({
                    delMessageData: response.data
                })
            }
        },
        async changeChatRecordStatus(params) {
            const response = await changeChatRecordStatus(params)
        },
        //聊天记录
        async chatRecordList(params) {
            const response = await chatRecordList(params)

            if (response.code === 200) {
                this.saveRecordList({
                    chatRecordData: response.data
                })
            }
        },
    },
    reducers: {
        saveMsgList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveFilter(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveScale(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveDomainList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveServiceList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveRecordList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveCreate(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveDelMessage(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
    }
}