/* eslint-disable import/no-anonymous-default-export */
import { list, parse,child,syncSort,batchUpdate,status,path } from '../services/feedback'
import {img } from '../services/cateApi'
import {
    fileDel,sourceDel,sourceClean
} from '../services/MonitorApi';
export default {
    state: {
        data: [],
        childs: [],
        sources:[]
    },
    effects: {
        async sortList(params) {
            const response = await list(params)

            if (response.code === 0) {
                this.saveSortList({
                    data: response.data
                })
            }
        },
        async sourceList(params) {
            const response = await path(params)

            if (response.code === 0) {
                this.saveSourceList({
                    sources: response.data
                })
            }
        },

        async fileDel(params) {
            return   await fileDel(params)
          },
          async sourceDel(params) {
            return   await sourceDel(params)
          },
          async sourceClean(params) {
            return   await sourceClean(params)
          },
        async child(params) {

            const response = await child(params)

            if (response.code === 0) {
                this.saveChild({
                    childs: response.data,

                })
            }
        },
        async img(params) {
           return await img(params)  
        },
        async update(params) {
            return await batchUpdate(params)

         },

        async changeStatus(params) {
          return await status(params)
         
        },
        async parse(params) {
            return   await parse(params)
          },
          async syncSort(params) {
            return   await syncSort(params)
          },
    },
    reducers: {
        saveSortList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveSourceList(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveChild(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
    }
}