/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Col, message, Row, Space } from 'antd';
import {ProFormSelect, ProFormText, ProForm, ProFormTextArea,ProFormUploadButton} from '@ant-design/pro-form';
import loading from '../../../utils/loading/loading';


class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            titleImg:null,
            loadings: false,
            tagList:[],
            status:[
                {label:'正常',value:1},
                {label:'下架',value:0}  
            ],
            feesType:[
                {label:'vip',value:1},
                {label:'金币',value:2},
                {label:'免费',value:0},
            ],
            ids:[],
            fileList:[]
        };
    }

    componentDidMount() { 
        const {row} = this.props
        let arr=[]
        this.props. childs.map(item=>{
            let obj={}
            obj.label=item.name
            obj.value=item.id
            arr.push(obj)
        })
        const fileArr = []
        if(row!=null&&row.titleImg){
            fileArr.push(
                {
                    uid: row.id,
                    name: row.titleImg.substring(row.titleImg.lastIndexOf("/")+1),
                    status: 'done',
                    url: row.titleImg,
                  }
              )
        }
        this.setState({tagList:arr,fileList:fileArr})
        
    }

    onFinish = (e) => {
        console.log('e',e)
        if(!e) return 
        const {tagList,titleImg} = this.state
        const {row,ids,type} = this.props

        if(e.tagId){
            let obj = tagList.filter((item)=>{ return e.tagId.some(curVal => (curVal === item.value)) })
            e.tag =obj.map(i=>{return i.label})
        }
        if(titleImg&&e.titleImg&&e.titleImg.length>0)  e.titleImg = titleImg
        e.ids=ids
        if(type=='0') e.ids = row.id
       
        this.props.update(e)
          .then(res=>{
             if (res.code===0) {
                this.setState({titleImg:null})
                 message.info('提交成功');
            }
            else  message.error('提交失败');
              
            this.props.handleCancel() 
           // this.props.list()
          })   

    };
    handleChange = ({ fileList: newFileList }) =>{this.setState({fileList:newFileList})}

    render() {
        const {tagList,status,feesType,fileList,loadings} = this.state;
        const {img,type,row} = this.props
      
        const required = type ==='2'
        return (
          <ProForm layout='inline' grid={true} rowProps={{ gutter: [12, 16 ] }} preserve={false}
          initialValues={row?{
            collect:row?row.collect:0,
            title:row?row.title:'',
            params:row?row.template:'',
            viewer:row?row.viewer:0,
            hots:row?row.hots:0,
            feesType:row?row.feesType:'',
            status:row?row.status:'',
            auth:row?row.auth:'',
            feesPrice:row?row.feesPrice:'',
            tagId:row?row.tagId:null,
            finish:row?row.finish:0,
            descs:row?row.descs:'',

          }:{}}
          submitter={{
                    render: (props, doms) => {
                      return (
                        <Row style={{paddingTop:10}}>
                          <Col  span={12} offset={24}>
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                    submitButtonProps: {
                       
                        loading:loadings
                    }
                
                  }}
                  onFinish={ (v) => this.onFinish(v)}
                >
                    <ProFormText type='number' colProps={{span:12}} name="collect" label="收藏" placeholder="收藏"/>
                    <ProFormText  type='number' colProps={{span:12}} name="viewer" label="观看" placeholder="观看"/>
                    <ProFormText   type='number' colProps={{span:12}} name="hots" label="热度" placeholder="热度"/>
                    <ProFormText colProps={{span:12}} name="params" label="模板" placeholder="参数"/>
                    <ProFormText colProps={{span:12}} name="auth" label="作者" placeholder="漫画作者"/>
                    <ProFormText   type='number' colProps={{span:12}} name="feesPrice" label="价格" placeholder="金币价格"/>
                    <ProFormText   type='number' colProps={{span:12}} name="finish" label="完结" placeholder="0连载 1完结"/>
                    <ProFormSelect colProps={{span:12}} name="status" label="状态" placeholder="状态"  options={status}/>          
                    <ProFormSelect colProps={{span:12}} name="feesType" label="类型" placeholder="收费类型" options={feesType}/>          
                    <ProFormSelect name="tagId" label="标签" placeholder="请选择标签" mode="multiple" options={tagList} />
                    <ProFormText rules={[{ required: required }]}  name="title" label="标题" placeholder="视频标题 "/>
                    <ProFormUploadButton fileList={fileList} onChange={this.handleChange}
                     disabled={type==='1'} name="titleImg" max={1} tooltip="封面图片" label="封面" placeholder="漫画封面"
                        fieldProps={{
                            name: 'file',
                            beforeUpload: async (file) => {
                                this.setState({loadings: true})
                                setTimeout(() => {
                                    this.setState({loadings:false})
                                }, 3000);
                                img({file: file,type: row?'manhua':''}).then(r => {
                                    this.setState({loadings: false})
                                    if (r.code === 0)
                                        this.setState({titleImg:r.data})
                                    return true
                                })


                                return false
                            }
                        }}
                    />
                    <ProFormTextArea  name="descs" label="描述" placeholder="简介"/>
                   

                    
                </ProForm>
          
        )


    }


}

const mapState = state => ({
    ...state.circleComment

});

const mapDispatch = dispatch => ({
    ...dispatch.circleComment
})

export default connect(mapState, mapDispatch)(Add);