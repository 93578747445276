import request from '../utils/request';
import url from '../utils/url';

//动态管理列表分页
export function preserve(params) {
    return request(url.anData, {
        method: 'POST',
        params
    })

}
// export function exportPreserve(params) {
//     return request(url.exportPreserve, {
//         method: 'GET',
//         params
//     })

// }
