import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const AuthRouter = ({ component: Component, ...rest }) => {
    const isLogged = localStorage.getItem('isLogin') === '1' ? true : false;
    // const token = JSON.parse(localStorage.getItem('userInfo'))
    return (
        <Route
            {...rest}
            render={props => (
                isLogged ? <Component {...props} /> : <Redirect to={'/login'} />
            )}
        />
    )
};

export default AuthRouter;