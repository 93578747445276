import React, { Component } from 'react'
import { Form, Input, Button, Select } from 'antd';
import { removeEmptyField } from '../../../compoent/emptyFild'
import { connect } from "react-redux";

const { Option } = Select;

class AddAdmin extends Component {
    state = {
        res: []
    }
    componentDidMount() {
        const { roleListPage } = this.props
        roleListPage().then(res => {


            if (res.code === 0) {
                this.setState({ res: res.data.records })
            }
        })
    }
    onFinish = (values) => {
        if (values) {
            values.page = 1
            values.size = 10
            const newValues = removeEmptyField(values)
            this.props.addAdmin(newValues).then(res => {
                this.props.adminListPage({ page: 1, size: 10 })
            }
            )
        } this.props.handleCancel()
    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { res } = this.state
        const { roleListData } = this.props

        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    initialValues={{ remember: true }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"

                >
                    <Form.Item
                        label="用户名："
                        name="name"
                        rules={[{ required: true, message: '请输入用户名' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="昵称："
                        name="loginName"
                        rules={[{ required: true, message: '请输入昵称!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="密码："
                        name="password"
                        rules={[{ required: true, message: '请输入密码!' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="角色："
                        name="roleId"
                    >
                        <Select
                            showSearch
                            placeholder='请选择角色'
                        // style={{ marginLeft:10}}
                        // placeholder=""

                        >
                            {res.map(item => (
                                <Option key={item.id} value={item.id}>
                                    {item.roleName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="备注"
                        name="remarks"
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            确认
                        </Button>
                        <Button type="primary" htmlType="reset" style={{ marginLeft: 30 }}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}
const mapState = state => ({
    ...state.admin

});

const mapDispatch = dispatch => ({
    ...dispatch.admin
})

export default connect(mapState, mapDispatch)(AddAdmin);