/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import MembersList from './MembersList'
import {connect} from "react-redux";
import {ProFormRadio, ProFormText, ProForm} from '@ant-design/pro-form';
import {Col, Row, Space} from 'antd';



class Members extends Component {

    state = {
        data: [],
        min: '',
        max: '',
        visible: false,
        recommend:false,
        msgAdd:false,
        date: [],
        id: '',
        newValues: {},
        status:[
            {label:'vip',value:1},
            {label:'免费',value:0}  
        ],
        sources: [
            {
                source: 1,
                desc: 'android'
            },
            {
                source: 2,
                desc: 'ios'
            },

        ],

    };

    onFinish = (values) => {
  
    

        //
        this.setState({data: values})
        if (values) {
            values.page = 1
            values.size = 20
            const newValues = values
            this.setState({newValues: newValues})
            this.props.userList(newValues)
        } else {
            //
            this.props.userList({page: 1, size: 20})
        }
        //
    };
    onFinishFailed = (errorInfo) => {

    };
    onChange = (value) => {
        this.setState({min: value})

    };
    onChange1 = (value) => {
        this.setState({max: value})

    };
    createUser = () => {
        this.setState({
            visible: true
        })
    };
    recommendList = () => {
        this.setState({
            recommend: true
        })
    };

    pushAllMsg = () => {
        this.setState({
            msgAdd: true
        })
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            recommend:false,
            msgAdd:false
        })
      /*  setTimeout(() => {
            this.props.homeData()

        }, 200);*/
    };

    reset = () => {
        this.setState({newValues:{page:1,size:20}})

    };

    render() {
        const {searchData, visible, status, newValues,recommend,sources,msgAdd} = this.state
       // const {homeInfo} = this.props

        return (
            <div >
               
                <ProForm 
                  initialValues={ {status:true}}
                submitter={{
                    searchConfig: {
                        submitText: '查询',
                    },
                    render: (props, doms) => {
                      return (
                        <Row>
                          <Col  offset={2}>
                            <Space>{doms}</Space>
                          </Col>
                        </Row>
                      ) ;
                    },
                  }}
                  layout='inline'
                  onFinish={ (v) => this.onFinish(v)}
                  onReset={()=>this.reset()}
                >   
                <ProFormText  width="xs" name="id" label="id" placeholder="id"/> 
                    <ProFormText  name="nickname" label="名称" placeholder="请输入名称搜索"/> 
                    <ProFormText  name="em" label="账号" placeholder="账号"/>
                    <ProFormRadio.Group
                            name="vip"
                           
                            radioType="button"
                            options={status}
                        />
                    {/* <ProFormSelect  name="vip" label="vip" placeholder="请选择"  options={status} /> */}
                </ProForm>
                  
             
              
                    <MembersList searchData={searchData} newValues={newValues}/>
              
                {/* <Modal destroyOnClose open={visible} title='新增' footer={null} onCancel={this.handleCancel}
                       handleCancel={this.handleCancel}>
                    <CreateUser handleCancel={this.handleCancel}></CreateUser>
                </Modal> */}

            


            </div>
        )
    }
}

const mapState = state => ({
    ...state.users

});

const mapDispatch = dispatch => ({
    ...dispatch.users
})

export default connect(mapState, mapDispatch)(Members);