/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Button, Form} from 'antd';
import {ProFormRadio, ProFormText, ProFormUploadButton} from '@ant-design/pro-form';
import {removeEmptyField} from '../../compoent/emptyFild'

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            type: '',
            loading: false,
            h5: true

        };
    }

    componentDidMount() {
        const {record} = this.props
        if (record)
            this.setState({h5: record.category !== '2'})
    }

    onFinish = (values) => {
        const {type, img} = this.props
        values.upload = '';
        values.imageUrl = img;
        let params = removeEmptyField(values);
        if (type === '0') {
            if (params) {
                this.props.add(params).then(res => {
                    this.props.list()
                })
            }
        }
        else if (type === '1') {
            //编辑接口
            if (params) {

                params.id = this.props.record.id
                this.props.upd(params).then(res => {
                    this.props.list()
                })
            }

        }
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };
    onChangeType = (e) => {
        let h5 = e.target.value !== '2'
        this.setState({h5: h5})
    };

    render() {
        // this.setState({h5:true })
        const {record, upload} = this.props
        const {loading, h5} = this.state;

        return (
            <div>
                <Form name="basic"
                      labelCol={{span: 6}}
                      wrapperCol={{span: 14}}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      autoComplete="off"
                      initialValues={{
                          name: record ? record.name : '',
                          params: record ? record.params : '',
                          action: record ? record.action : '',
                          orderNum: record ? record.orderNum : null,
                          type:record?record.type:1,
                          category: record && record.category === '2' ? '2' : '1'
                      }}>

                    <ProFormText rules={[
                        {
                            required: true,
                            message: '请输入标题!',
                        },
                    ]} width="md" name="name" label="标题" placeholder="标题 "/>

                        <ProFormText rules={[
                        {
                            required: true,
                            message: '请输入排序!',
                        },
                    ]} width="md" name="orderNum" label="排序" placeholder="正序排序 "/>
                     <ProFormRadio.Group
                        name="type"
                        label="类型"
                        options={[{label: '视频', value: 1,}, {label: '漫画', value: 2,}]}
                        onChange={this.onChangeType}
                    />

                    <ProFormRadio.Group
                        name="category"
                        label="类型"
                        options={[{label: '外链', value: '1',}, {label: '内部', value: '2',}]}
                        onChange={this.onChangeType}
                    />
                    {h5 ? <ProFormText tooltip="H5地址" width="md" name="action" label="h5地址"
                                       placeholder="跳转地址"
                                       rules={[
                                           {
                                               required: h5,
                                               message: '请输入h5地址!',
                                           },
                                       ]}
                    /> : <>
                        <ProFormText tooltip="内部跳转路径" width="md" name="params" label="内部路径"
                                     placeholder="android app跳转路径"
                                     rules={[
                                         {
                                             required: !h5,
                                             message: '内部跳转路径!',
                                         },
                                     ]}
                        />

                    </>}


                    <ProFormUploadButton
                        max={1} tooltip="限制1张图片 app轮播图片 注意尺寸" width="md" name="upload" label="图片"
                        placeholder="图片"
                        fieldProps={{
                            name: 'file',
                            beforeUpload: async (file) => {
                                this.setState({loading: true})
                                upload({file: file, type: 'img'}).then(r => {
                                    if (r.code === 0)
                                        this.setState({loading: false})
                                })


                                return false
                            }
                        }}
                    />


                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="success" htmlType="reset">
                            重置
                        </Button>
                        <Button loading={loading} type="primary" htmlType="submit" style={{marginLeft: 30}}>
                            提交
                        </Button>

                    </Form.Item>
                </Form>
            </div>
        )


    }


}

const mapState = state => ({
    ...state.banner

});

const mapDispatch = dispatch => ({
    ...dispatch.banner
})

export default connect(mapState, mapDispatch)(Add);