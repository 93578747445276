import React, { Component } from 'react'
import { ProCard, ProTable, ProList } from '@ant-design/pro-components';
import RcResizeObserver from 'rc-resize-observer';
import { connect } from 'react-redux';

import {

    Badge,
    Tag,
    Space

} from 'antd';

class Financial extends Component {
    state = {
        responsive: false,
        data: [],
        clickData: {},
        activeKey: 'vc',
        spmhData: {},
        dataSource: [
            {
                id: '1',
                name: '语雀的天空',
                image:
                    'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
                desc: '我是一条测试的描述',
            },
            {
                id: '2',
                name: 'Ant Design',
                image:
                    'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
                desc: '我是一条测试的描述',
            },
            {
                id: '3',
                name: '蚂蚁金服体验科技',
                image:
                    'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
                desc: '我是一条测试的描述',
            },
            {
                id: '4',
                name: 'TechUI',
                image:
                    'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
                desc: '我是一条测试的描述',
            },
        ],


    }
    componentDidMount() {
        this.init();
    }

    init = () => {
        const { finance, spmh } = this.props;
        finance().then(res => {
            if (res.code == 0) {
                this.setState({ clickData: res.data })
            }
        })
        spmh()
            .then(res => {
                if (res.code == 0) {
                    this.setState({ spmhData: res.data, dataSource: res.data.vc })
                }
            })
    }

    renderBadge = (count, active = false) => {
        return (
            <Badge
                count={count}
                overflowCount={99999}
                style={{
                    marginBlockStart: -2,
                    marginInlineStart: 8,
                    color: active ? 'white' : '#999',
                    backgroundColor: active ? 'red' : '#E6F7FF',
                }}
            />
        );
    };


    render() {
        const { responsive, data, dataSource, activeKey, clickData, spmhData } = this.state;
        const vs = clickData.vs || [];
        const ms = clickData.ms || [];
        const vc = clickData.vc || [];
        const mc = clickData.mc || [];
        const spc = spmhData.vc || [];
        const mhc = spmhData.mc || [];
        return (
            <div>
                <RcResizeObserver
                    key="resize-observer"
                    onResize={(offset) => {

                        this.setState({ responsive: offset.width < 596 })
                    }}
                >
                    <ProCard
                        bordered
                        split={responsive ? 'horizontal' : 'vertical'}
                    >
                        <ProCard split="vertical">
                            <ProCard split="vertical" title="视频" headerBordered
                            >
                                <ProCard title='关键字搜索量'
                                >

                                    {vs.map(item => (
                                        <div key={item.value} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                                            <div style={{ fontWeight: 600 }}>{item.value}</div>
                                            <div style={{ fontWeight: 600, color: 'blue' }}>{item.id}</div>
                                        </div>

                                    ))}
                                </ProCard>
                                <ProCard title='分类点击量'
                                >
                                    {vc.map(item => (
                                        <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                                            <div style={{ fontWeight: 600 }}>{item.url}</div>
                                            <div style={{ fontWeight: 600, color: 'blue' }}>{item.id}</div>
                                        </div>

                                    ))}
                                </ProCard>


                            </ProCard>

                            <ProCard split="vertical" title="漫画" headerBordered
                            >
                                <ProCard title='关键字搜索量'
                                >    {ms.map(item => (
                                    <div key={item.value} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                                        <div style={{ fontWeight: 600 }}>{item.value}</div>
                                        <div style={{ fontWeight: 600, color: 'blue' }}>{item.id}</div>
                                    </div>

                                ))}
                                </ProCard>
                                <ProCard title='分类点击量'
                                >
                                    {mc.map(item => (
                                        <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                                            <div style={{ fontWeight: 600 }}>{item.url}</div>
                                            <div style={{ fontWeight: 600, color: 'blue' }}>{item.id}</div>
                                        </div>

                                    ))}
                                </ProCard>


                            </ProCard>






                        </ProCard>
                        <ProCard >
                            <ProList
                                rowKey="id"

                                dataSource={dataSource}

                                metas={{
                                    title: {
                                        dataIndex: 'id',
                                        render: (val, row, idx) => (
                                            <Space>
                                                {'No.' + (val)}
                                                <Tag color="cyan">{row.count + (activeKey == 'mc' || activeKey == 'vc' ? ' 点击' : ' 购买')}</Tag>

                                            </Space>
                                        )

                                    },
                                    description: {
                                        key: 'title',
                                        dataIndex: 'title'

                                    },



                                    subTitle: {
                                        key: 'tag',

                                        dataIndex: 'tag',
                                        render: (val, row) => (
                                            <Space size={8}>


                                                {/* <Tag color='purple'>{row.tag}</Tag> */}
                                                < Space style={{ color: 'blue' }}>  {row.tag ? row.tag : ''}
                                                </ Space>
                                                {activeKey == 'vc' || activeKey == 'mc' ? <Tag color="red">{row.feeType == 1 ? 'vip' : row.feeType == 2 ? '金币' : row.feeType}</Tag>
                                                    : activeKey=='mb'?<Tag color="purple">{'购买人数：'+row.mc}</Tag>:''}



                                            </Space>
                                        )
                                    },
                                    actions: () => false

                                }}

                                toolbar={{
                                    menu: {
                                        activeKey,
                                        items: [
                                            {
                                                key: 'vc',
                                                label: (
                                                    <span>视频点击{this.renderBadge(spmhData.vcTotal, activeKey === 'vc')}</span>
                                                ),
                                            },
                                            {
                                                key: 'mc',
                                                label: (
                                                    <span>
                                                        漫画点击{this.renderBadge(spmhData.mcTotal, activeKey === 'mc')}
                                                    </span>
                                                ),
                                            },
                                            {
                                                key: 'vb',
                                                label: (
                                                    <span>
                                                        视频购买{this.renderBadge(50, activeKey === 'vb')}
                                                    </span>
                                                ),
                                            },
                                            {
                                                key: 'mb',
                                                label: (
                                                    <span>
                                                        漫画购买{this.renderBadge(50, activeKey === 'mb')}
                                                    </span>
                                                ),
                                            },
                                        ],
                                        onChange: (key) => {
                                            if (key === 'vc')
                                                this.setState({ activeKey: key, dataSource: spmhData.vc })
                                            else if (key === 'mc')
                                                this.setState({ activeKey: key, dataSource: spmhData.mc })
                                            else if (key === 'vb')
                                                this.setState({ activeKey: key, dataSource: spmhData.vb })
                                            else if (key === 'mb')
                                                this.setState({ activeKey: key, dataSource: spmhData.mb })
                                        }
                                    },


                                }}
                            />
                        </ProCard>

                    </ProCard>
                </RcResizeObserver>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.finance
})
const mapDispatch = dispatch => ({
    ...dispatch.finance

})

export default connect(mapState, mapDispatch)(Financial);
