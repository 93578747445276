import React, { Component } from 'react';
import { connect } from "react-redux";
import { Table ,Modal} from 'antd';
import CancelModal from './CancelModal'
class OrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            userName: '',
            email: '',
            gender: '',
            phone: '',
            startTime: '',
            endTime: '',
            current: 1,
            cancels:false,
            record:{}
        }

        this.columns = [
            {
                title: '订单编号',
                dataIndex: 'orderId',
                align: 'center',

            },
            {
                title: '发单用户',
                dataIndex: 'publishMember',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {_}({record.pid})
                        </div>
                    )
                }

            },
            {
                title: '下单用户',
                dataIndex: 'consumeMember',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {_}({record.cid})
                        </div>
                    )
                }

            },
            {
                title: '订单金额',
                dataIndex: 'orderPrice',
                align: 'center',
            },
            {
                title: '平台抽成',
                dataIndex: 'companyCommission',
                align: 'center',
            },
            {
                title: '推荐人抽成比例',
                dataIndex: 'popularScale',
                align: 'center',
            },
            {
                title: '推荐人抽成金额',
                dataIndex: 'popularMoney',
                align: 'center',
            },
            {
                title: '接单员到账',
                dataIndex: 'realMoney',
                align: 'center',
            },
            {
                title: '类型',
                dataIndex: 'category',
                align: 'center',
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                align: 'center',
            },
            {
                title: '开始时间',
                dataIndex: 'beginTime',
                align: 'center',
            },
            {
                title: '订单时长',
                dataIndex: 'orderTime',
                align: 'center',
            },
            {
                title: '状态',
                dataIndex: 'orderStatus',
                align: 'center',
                render: (_, record) => {
                    return (
                        <div>
                            {record.orderStatus === '已接单' ? '进行中' : record.orderStatus}
                        </div>
                    )
                }
            },
            {
                title: '添加时间',
                align: 'center',
                dataIndex: 'addTime',
            },
            {
                title: '操作',
                align: 'center',
                fixed: 'right',
                width: 320,
                render: (_, record) => {
                    return (
                         record.cancels?"":
                    <div>
                        {record.status === 0 || record.status === 1 ?
                            <a onClick={() => this.cancelOrder(record)}>取消</a> : ''}
                    </div>


                    )
                }
            }
        ];
    }
    componentDidMount() {
        this.init();
    }


    init = () => {
        const { OrderList } = this.props
        OrderList({ page: 1, size: 10 })
    }

    handleChangeOrderList = (current, pageSize) => {
        const { newValues } = this.props

        newValues.page = current
        this.setState({ current })
        this.props.OrderList({ ...newValues })
    }
     cancelOrder = (record) => {
        this.setState({cancels:true,record:record})
    }
     handleCancel = () => {
        this.setState({cancels:false})
    };

    render() {
        const { data,  userLoading ,newValues} = this.props;
        const {  current,record,cancels } = this.state;
        const pagination = {
            current: current,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.handleChangeOrderList,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records} rowKey={record => record.orderId} pagination={pagination} />
                <Modal destroyOnClose visible={cancels} footer={null} title={'取消订单'}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <CancelModal handleCancel={this.handleCancel} record={record} newValues={newValues}/>
                </Modal>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.order
});

const mapDispatch = dispatch => ({
    ...dispatch.order

})

export default connect(mapState, mapDispatch)(OrderList);