import request from '../utils/request';
import url from '../utils/url';


export function list(params) {
    return request(url.mhPage, {
        method: 'POST',
        params
    });
}
export function sync() {
    return request(url.mhSync, {
        method: 'POST',
    });
}
export function paths() {
    return request(url.mhPaths, {
        method: 'POST',
    });
}

export function create(params) {
    return request(url.mhCreate, {
        method: 'POST',
        params
    });
}


export function update(params) {
    return request(url.mhUpd, {
        method: 'POST',
        params
    });
}
export function status(params) {
    return request(url.mhStatus, {
        method: 'POST',
        params
    });
}
//章节列表
export function zjs(params) {
    return request(url.mhzjs, {
        method: 'POST',
        params
    });
}
//章节重拍
export function zjSort(params) {
    return request(url.mhzjSort, {
        method: 'POST',
        params
      
    });
}
//zjFeesTypeStatus syncZj
export function zjFeesTypeStatus(params) {
    return request(url.mhzjFeesType, {
        method: 'POST',
        params
      
    });
}

export function syncZj(params) {
    return request(url.mhSyncZj, {
        method: 'POST',
        params
      
    });
}
//mhAddZj
export function mhAddZj(params) {
    return request(url.mhAddZj, {
        method: 'POST',
        params
      
    });
}



