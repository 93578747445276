/* eslint-disable react/jsx-no-duplicate-props */
import React, {Component} from 'react'
import {Button, Form, Input} from 'antd';
import {connect} from "react-redux";


class Add extends Component {

    onFinish = (values) => {
        const {type} = this.props
        if (type === '0') {
            if (values) {
                values.page = 1
                values.size = 10
                this.props.add(values).then(res => {
                    this.props.list({page: 1, size: 10})
                })
            }
        }
        else if (type === '1') {
            //编辑接口
            if (values) {
                values.id = this.props.record.id
                this.props.upd(values).then(res => {
                    this.props.list({page: this.props.current, size: 10})
                })
            }

        }
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };

    render() {
        const {record} = this.props
        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 14}}
                    initialValues={{remember: true}}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        name: record ? record.name : '',
                        content: record ? record.content : '',
                    }}
                >
                    <Form.Item
                        label="协议名称："
                        name="name"
                        rules={[{required: true, message: '请输入协议名称!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="协议内容："
                        name="content"
                         rules={[{ required: true, message: '请输入协议内容!' }]}
                    >
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            保存
                       </Button>
                        <Button type="primary" htmlType="reset" style={{marginLeft: 30}}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.agreement

});

const mapDispatch = dispatch => ({
    ...dispatch.agreement
})

export default connect(mapState, mapDispatch)(Add);