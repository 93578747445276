import React, {Component} from "react";
import {connect} from 'react-redux';
import ProTable from '@ant-design/pro-table';
import Add from './Add'
import {Badge, Button, Modal,Popconfirm} from 'antd';

class PayControl extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            visible: false,
            type: '',
            row: {},
            parent: [],
            params: {}

        };
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                align: 'center',
                hideInSearch: true,
            },
            {
                title: '支付类型',
                align: 'center',
                dataIndex: 'type',
                key: 'name',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                       
                           <a> <Badge
                                status={_ === '1' ? 'success' : 'processing'}
                                text={_ === '1' ? '微信('+record.name+')' : '支付宝('+record.name+')'}/></a>
                       

                      );
                }

            },
            {
                title: '商户Id',
                align: 'center',
                ellipsis: true,
                copyable: true,
         
                hideInSearch: true,
                dataIndex: 'mchId'
              
               
            },

            {
                title: '排序',
                align: 'center',
                ellipsis: true,
                copyable: true,
         
                hideInSearch: true,
                dataIndex: 'signType'
              
               
            },

            {
                title: '支付网关',
                align: 'center',
                ellipsis: true,
                copyable: true,
            
                hideInSearch: true,
                dataIndex: 'publicKey',
  

            },
            
        

            {
                title: '支付渠道',
                align: 'center',
                ellipsis: true,
                hideInSearch: true,
                dataIndex: 'icon',
                key: 'icon',
               
            },

            {
                title: '回调',
                align: 'center',
                ellipsis: true,
                hideInSearch: true,
                dataIndex: 'cert',
                key: 'cert',
               
            },
            
            {
                title: '支付名称',
                align: 'center',
                ellipsis: true,
               
                tip: '用户支付时显示的商品描述',
                hideInSearch: true,
                dataIndex: 'params',
                key: 'params',
               
            },

            {
                title: '状态',
                align: 'center',
                ellipsis: true,
                tip: '关闭后 用户无法使用该支付方式',
                hideInSearch: true,
               
                render: (_, record) => {
                    return (
                        <Popconfirm
                            title="确定操作？"
                            onConfirm={() => this.status(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                           <a> <Badge
                                status={record.status === 1 ? 'success' : 'error'}
                                text={record.status === 1 ? '开启' : '关闭'}/></a>
                        </Popconfirm>


                      );
                }
            },

            {
                title: '修改时间',
                dataIndex: 'updTime',
                align: 'center',
                key: 'updTime',
                hideInSearch: true,
                
            },
            {
                title: '创建时间',
                dataIndex: 'addTime',
                align: 'center',
                key: 'addTime',
                hideInSearch: true,
                
            },

            {
                title: '操作',
               
                align: 'center',
                hideInSearch: true,
                render: (_, record) => {
                    return (
                        <div>
                            <a onClick={() => this.upd(record)} style={{marginLeft: 2}}>编辑</a>
                            <a onClick={() => this.del(record)} style={{marginLeft:12,color:'red'}}>删除</a>
                        </div>
                    )
                }
            }

        ];


    }


    handleCancel = () => {
        this.setState({
            visible: false
        })
    };
    add = (row) => {
        this.setState({
            visible: true,
            type: '0',
            row: row
        })
    }
    upd = (record) => {
        this.setState({visible: true, row: record, type: '1'})
    }

    del = (record) => {
        this.props.del({id: record.id}).then(res => {
            this.props.list();

        })
    }
    status = (record) => {
        this.props.status({id: record.id, status: record.status === 1 ? 0 : 1}).then(res => {
            this.props.list();

        })
    };

    render() {
        const {row, type} = this.state;
     
        const {data} = this.props;

        return (
            <div>
                <ProTable
                columns={this.columns}
                request={(params, s, f) => {
                    this.props.list(params)
                }}
                dataSource={data}
                columnsState={{
                    persistenceKey: 'pro-table-singe-demos',
                    persistenceType: 'localStorage',
                }}
                rowKey="id"
                search={false}
                pagination={{
                    pageSize: 50,
                }}

                dateFormatter="string"
                headerTitle="支付信息列表"
                toolBarRender={() => [
                    <Button key="create" type="primary" onClick={() => this.add()}>
                        添加
                    </Button>,

                ]}
            />
                <Modal destroyOnClose open={this.state.visible} title={type === '0' ? '新增' : '编辑'}
                       footer={null}
                       onCancel={this.handleCancel} handleCancel={this.handleCancel}>
                    <Add handleCancel={this.handleCancel} row={row} type={type}></Add>
                </Modal>
            </div>
        );

    }


}


const mapState = state => ({
    ...state.payControl

});

const mapDispatch = dispatch => ({
    ...dispatch.payControl
})

export default connect(mapState, mapDispatch)(PayControl);