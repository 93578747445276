/* eslint-disable import/no-anonymous-default-export */
import {add, del, list, parent, status, upd} from '../services/menusApi';

export default {
    state: {
        data: [],
        parentData: [],
        del: {},
        upd: {}
    },
    effects: {
        async list(params) {
            const response = await list(params)
            if (response.code === 0) {
                this.saveData({
                    data: response.data,

                })
            }
            return response.data
        }, async parent() {
            const response = await parent()
            if (response.code === 0) {
                this.saveParent({
                    parentData: response.data,

                })
            }
        },
        async add(params) {
            await add(params)
        },
        async del(params) {
            const response = await del(params)
            this.dels({
                del: response.data,

            })

        },
        async status(params) {
            await status(params)
        },
        async upd(params) {
            return await upd(params)
            /*    this.dels({
                    upd: response.data,

                })*/
        },
    },
    reducers: {
        saveData(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveParent(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }, dels(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        }, upds(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },

    }
}