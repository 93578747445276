/* eslint-disable import/no-anonymous-default-export */
import { roleListPage, addRole, delRole, getRoleMenu, configPower } from '../services/role'

export default {
    state: {
        data: [],
        addRoleData: [],
        delRoleData: [],
        getRoleMenuData: [],
        configPowerData: []

    },
    effects: {
        async roleListPage(params) {
            const response = await roleListPage(params)

            if (response.code === 0) {
                this.saveRoleListPage({
                    data: response.data
                })
            }
        },
        async addRole(params) {
            const response = await addRole(params)

            if (response.code === 0) {
                this.saveAddRole({
                    addRoleData: response.data
                })
            }
        },
        async delRole(params) {

            const response = await delRole(params)

            if (response.code === 0) {
                this.saveDelRole({
                    delRoleData: response.data
                })
            }
        },
        async getRoleMenu(params) {
            const response = await getRoleMenu(params)
            return response
        },
        async configPower(params) {
            const response = await configPower(params)

            if (response.code === 0) {
                this.saveConfigPower({
                    configPowerData: response.data
                })
            }
        },
    },
    reducers: {
        saveRoleListPage(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveAddRole(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveDelRole(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveGetRoleMenu(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        saveConfigPower(state, paylode) {
            return {
                ...state,
                ...paylode
            }
        },
        clear(state, payload) {
            return {
                ...state,
                getRoleMenuData: [],
            }
        },
    }
}