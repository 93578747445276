import React, {Component} from 'react';
import {connect} from "react-redux";
import {Popconfirm, Table} from 'antd';
import {sc} from "../../../constant/consts";

class commentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageNum: 1,
            pageSize: 10,
            content: '',
            orderId: '',
            beginTime: '',
            endTime: '',
            current: 1
        }

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',

            },
            {
                title: '发单编号',
                dataIndex: 'orderId',

            },
            {
                title: '评论用户',
                dataIndex: 'memberName',

            },
            {
                title: '发单用户',
                dataIndex: 'commentMemberName',

            },
            {
                title: '评论内容',
                dataIndex: 'content',
            },
            {
                title: '评论时间',
                dataIndex: 'addTime',
            },
            {
                title: '操作',
                render: (_, record) => {
                    return (
                        <div>
                            <Popconfirm
                                title="确定删除吗？"
                                onConfirm={() => this.delComment(record)}
                                okText="删除"
                                cancelText="取消"
                            >
                                <a style={{marginLeft: 30}}>删除</a>
                            </Popconfirm>
                        </div>
                    )
                }
            }
        ];
    }

    componentDidMount() {
        this.init();
    }


    init = () => {
        const {commentList} = this.props
        commentList({page: 1, size: 10})
    }

    query = (current, pageSize) => {
        const {newValues} = this.props

        newValues.page = current
        this.setState({current})
        this.props.commentList({...newValues})
    }
    delComment = (record) => {
        let {newValues,data} = this.props
        newValues.page=data.current;
        this.props.del({id: record.id}).then(res => {
            this.props.commentList(newValues)
        })
    }

    render() {
        const {data, userLoading,newValues} = this.props;
        const pagination = {
            current: newValues.page,//当前页
            total: data.total,//总条数
            pageSize: data.size,//页大小
            onChange: this.query,
            showQuickJumper: true,//快速跳转
            showSizeChanger: false//大于50时显示pageSize切换器
        };
        return (
            <div>
                <Table columns={this.columns} loading={userLoading} dataSource={data.records}
                       rowKey={record => record.id} pagination={pagination}
                       scroll={sc}/>
            </div>
        );
    }
}


const mapState = state => ({
    ...state.orderComment
});

const mapDispatch = dispatch => ({
    ...dispatch.orderComment

})

export default connect(mapState, mapDispatch)(commentList);