import request from '../utils/request';
import url from '../utils/url';


//用户登录
export function login(params) {
  return request(url.login, {
    method: 'POST',
    params
  });
}
//返回权限菜单
export function getAdminMenuList(params) {
  return request(url.getAdminMenuList, {
    method: 'POST',
    params
  });
}

// //用户退出
// export function logout() {
//   return request(url.logout, {
//     method: 'POST',
//   });
// }
//修改密码
export function updatePassword(params) {
  return request(url.updatePassword, {
    method: 'POST',
    params
  });
}
// export function homeData() {
//   return request(url.homeData, {
//     method: 'GET'
  // });
// }