import React, {Component} from 'react'
import {connect} from "react-redux";
import { EditableProTable} from '@ant-design/pro-components';
  import { Button, Space } from 'antd';


class Vcs extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            editableKeys:[]


        };
        this.columns = [
            {
              title: '描述',
              dataIndex: 'tips',
              align: 'center',
              readonly:true,
              width: '30%',
            },
            {
                title: '参数',
                dataIndex: 'values',
                align: 'center',
                formItemProps: {
                  rules: [
                    {
                      required: true,
                      message: '此项为必填项',
                    },
                  ],
                },
                width: '30%',
              },

              {
                title: '备注',
                dataIndex: 'remark',
                align: 'center',
                readonly:true,
                width: '30%',
              },
      
      
            {
              title: '操作',
              valueType: 'option',
      
              render: (text, record, _, action) => [
                <a
                  key="editable"
                  onClick={() => {
                    action?.startEditable?.(record.id);
                  }}
                >
                  编辑
                </a>
              ],
            },
          ];


    }


    save = (key,row) => {
       
        this.props.upd(row).then(res=>{
            console.log('ee',res)
            if(res.code==0)
             this.props.list();
        })
    }
    render() {
     const {data} = this.props
 
        return (
            <>

            <Space>
        
              <Button
                key="rest"
                onClick={() => {
                  this.form.resetFields();
                }}
              >
                清空缓存数据
              </Button>
            </Space>
      
            <EditableProTable
              rowKey="id"
              scroll={{
                x: 960,
              }}

              // 关闭默认的新建按钮
              recordCreatorProps={false}
              columns={this.columns}
              request={() => {
                this.props.list()
            }}
              value={data}
             
              editable={{
                actionRender: (row, config, dom) => [dom.save, dom.cancel],
                onSave:  (key,row) => {
                  this.save(key,row)
                },
              
              }}
            />
           
          </>
        )
    }
}

const mapState = state => ({
    ...state.vcs

});

const mapDispatch = dispatch => ({
    ...dispatch.vcs
})

export default connect(mapState, mapDispatch)(Vcs);