/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'
import { Form, Input, Button, Select } from 'antd';
import { connect } from "react-redux";
import {ProFormRadio, ProFormUploadButton} from '@ant-design/pro-form';
import {removeEmptyField} from '../../compoent/emptyFild'

const { Option } = Select;

class AddLabel extends Component {
    state = {
       
        loading: false,
        parentCate:[],
        picData: '',
        cateType: [
            {
                value: "tag",
                name: '普通标签'
            },
            {
                value: "nav",
                name: '导航标签'
            },
            {
                value: "recommend",
                name: '栏目标签'
            },
            {
                value: "cate",
                name: '推荐标题'
            },
            {
                value: "yy",
                name: '演员'
            },
            {
                value: "cj",
                name: '厂家'
            }

        ],
  

    };



    componentDidMount() {


    }

    onFinish = (values) => {
        const { type } = this.props

        if (type === '0') {
            if (values) {

                values.icon = this.props.imgUrl
                 const newValues = removeEmptyField(values)
                this.props.addCateTag(newValues).then(res => {
                    this.props.list({...this.props.newValues})
                })
            }
          
        }
        else if (type === '1') {
            //编辑接口

            if (values) {
                values.icon = this.props.imgUrl
                values.id = this.props.record.id
                 const newValues = removeEmptyField(values)
                this.props.updCateTag(newValues).then(res => {
                    this.props.list({...this.props.newValues})
                })
            }

        }
        this.props.clear()
        this.props.handleCancel()

    };

    onFinishFailed = (errorInfo) => {

    };
    render() {
        const { record ,parent,icon} = this.props
        const { loading } = this.state
       
       let close = record
        const { cateType } = this.state;

        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    initialValues={{
                        orderNum: record ? record.orderNum : '',
                        name: record ? record.name : '',
                        icon: record ? record.icon : '',
                        remarks: record ? record.remarks : '',
                        params: record ? record.params : '',
                        owner: record ? record.owner : '',
                        cateType: record ? record.cateType :'',
                        showType:record ? record.showType :'',
                        bgImg: record ? record.bgImg :'',
                        showIndex:record?record.showIndex:0
                    }}
                >
                    <Form.Item
                        label="板块"
                        name="owner"
                        rules={[{ required: !close, message: '请选择板块!' }]}

                    >
                        <Select
                            disabled={close}
                            showSearch
                            placeholder='请选择板块'
                            optionLabelProp="label"
                        >
                            {parent.map(item => (
                                <Option key={item.id} value={item.id} label={item.name} >
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="名称"
                        name="name"
                        rules={[{ required: true, message: '请输入名称!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="显示位置"
                        name="cateType"
                        rules={[{ required: true, message: '请选择位置标签!' }]}

                    >
                        <Select
                            showSearch
                            placeholder='请选择位置标签'

                        >
                            {cateType.map(item => (
                                <Option key={item.value} value={item.value} label="name">
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="排序"
                        name="orderNum"
                        rules={[{ required: true, message: '请输入排序序号!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <ProFormRadio.Group
                        name="showIndex"
                        rules={[{ required: true, message: '选择' }]}
                        label="推荐展示"
                        tooltip="一般指的是板块推荐类目下 是否展示该标签在推荐"
                        defaultValue={0}

                        options={[{label: '否', value: 0,}, {label: '是', value: 1,}]}
                        onChange={this.onChangeType}
                    />
                    <Form.Item
                        label="推荐标识"
                        name="bgImg"
                        tooltip="无特殊要求 请忽略"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="展示样式"
                        name="showType"
                        tooltip="无特殊要求 请忽略"
                    >
                        <Input />
                    </Form.Item>

                    <ProFormUploadButton
                        max={1} tooltip="图标" width="md"  label="图标"
                        placeholder="显示图标"
                        fieldProps={{
                            name: 'file',
                            beforeUpload: async (file) => {
                                this.setState({loading: true})
                                icon({file: file, type: 'image'}).then(r => {
                                    if (r.code === 0)
                                        this.setState({loading: false})
                                })


                                return false
                            }
                        }}
                    />

                    <Form.Item
                        label="标签参数"
                        name="params"
                        tooltip="无特殊要求 请忽略"
                    >
                        <Input.TextArea />
                    </Form.Item>


                    <Form.Item
                        label="备注"
                        name="remarks"
                    // rules={[{ required: true, message: '请输入标签名称!' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>

                        <Button type="success" htmlType="reset" >
                            重置
                        </Button>
                        <Button loading={loading} style={{ marginLeft: 30 }} type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.label

});

const mapDispatch = dispatch => ({
    ...dispatch.label
})

export default connect(mapState, mapDispatch)(AddLabel);