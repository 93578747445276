import React, {Component} from 'react'
import FaceList from './FaceList'
import style from './Face.module.scss'
import {connect} from "react-redux";
import {removeEmptyField} from '../../compoent/emptyFild'

import {Button, DatePicker, Form, Input, Select,} from 'antd';

const {Option} = Select;

class Face extends Component {
    state = {
        newValues:{faceStatus:1},
    }

    onFinish = (values) => {
        this.setState({current:1})
        if (values) {
            values.page = 1
            values.size = 10
            if (!values.faceStatus)
                values.faceStatus=1

            if (values.faceStatus===-9)
                values.faceStatus=null;

            let newValues = removeEmptyField(values);
            this.setState({ newValues: newValues })
            this.props.faceList(newValues)
        } else {
            this.props.faceList()
        }

    };
    onFinishFailed = (errorInfo) => {

    };

    render() {
        return (
            <div>
                <div className={style.top}>
                    <Form
                        style={{marginTop: 20, marginLeft: 20}}
                        name="basic"
                        layout='inline'
                        // wrapperCol={{ span: 6 }}
                        onFinish={this.onFinish}
                        autoComplete="off"

                    >
                        <Form.Item
                            style={{width: 320}}
                            label="用户名："
                            name="name"
                        >
                            <Input placeholder='输入用户名'/>
                        </Form.Item>

                        <Form.Item
                            style={{ width: 250, marginLeft: 20 }}
                            label="审核"
                            name="faceStatus"
                        >
                            <Select>
                                <Option key={0} value={-9}>全部</Option>
                                <Option key={-1} value={-1}>已拒绝</Option>
                                <Option key={2} value={2}>通过</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{marginLeft: 0, marginTop: 0}}>
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                            <Button type="primary" htmlType='reset' style={{marginLeft: 30}}>
                                重置
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className={style.middle}>
                    <FaceList newValues = {this.state.newValues}/>
                </div>
            </div>
        )
    }
}

const mapState = state => ({
    ...state.face

});

const mapDispatch = dispatch => ({
    ...dispatch.face
})

export default connect(mapState, mapDispatch)(Face);